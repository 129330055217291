import { array, boolean, literal, number, object, string } from 'zod';

import { dateString } from './helpers';

export const ProviderDTO = object({
  first_name: string().min(1),
  last_name: string().min(1),
  middle_name: string().nullish(),
  birthdate: dateString.nullish(),

  type_code: string().min(1),
  email: string().email(),
  phone: string().nullish(),
  phone_code: string().nullish(),
  ssn: string().length(9).nullish(),
  specialty: string().nullish(),

  work_phone: string().nullish(),
  work_phone_code: string().nullish(),
  work_email: string().email().nullish().optional()
    .or(literal('')),
  driver_license: string().nullish(),
  gender: string().nullish(),
  suffix: string().nullish(),
});

export const InviteProviderDTO = object({
  first_name: string().min(1),
  last_name: string().min(1),
  email: string().email(),
  type_code: string().min(1),
  assignees: array(object({
    id: number(),
  })).default([]),
  specialty: string().nullish(),
  birthdate: dateString.nullish(),
  phone: string().nullish(),
  npi: string().length(10).nullish(),
  npi_verified: boolean().default(false),
  not_invited: boolean().default(false),
});
