import { number, object, string } from 'zod';

export const DemographicInfoDTO = object({
  id: number().optional(),
  ethnicity: string().nullish(),
  otherEthnicity: string().nullish(), // @TODO: conditional
  hairColor: string().nullish(),
  eyeColor: string().nullish(),
  heightFt: number().nullish(),
  heightIn: number().nullish(),
  weightLbs: number().nullish(),
  provider_id: number(),
});
