import { number, object, string } from 'zod';

export const CredentialDTO = object({
  id: number().optional(),
  provider_id: number(),
  website: string().min(1),
  title: string().min(1),
  login: string().min(1),
  password: string().min(1),
  notes: string().nullish(),
});
