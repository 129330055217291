import { number, object, string } from 'zod';

import { dateString, jsonAddress } from './helpers';

export const HealthcarePayorDTO = object({
  id: number().optional(),
  provider_id: number(),
  payor_name: string().min(1),
  payor_contact: jsonAddress.default({}),
  category: string().nullish(),
  payor_state_code: string().nullish(),
  number: string().nullish(),
  status: string().nullish(),
  effective_date: dateString.nullish(),
  expiration_date: dateString.nullish(),
  par_status: string().nullish(),
  notes: string().nullish(),
});
