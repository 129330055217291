import { number, object, string } from 'zod';

import { dateString, jsonAddress } from './helpers';

export const EducationDTO = object({
  id: number().optional(),
  provider_id: number(),
  type: string().min(1),
  institution: string().min(1),
  institutionAddress: jsonAddress.default({}),
  start_at: dateString,
  end_at: dateString.nullish(),
  degree: string().nullish(),
  specialty: string().nullish(),
  notes: string().nullish(),
});
