import { array, number, object, string } from 'zod';

const applyLicensesByTopicDTO = object({
  id: string(),
  topic: string(),
  cme_cycle: object({
    value: number(), period: string(),
  }).nullable(),
  state_code: string(),
  license_number: string(),
  provider_type_code: string(),
});

export const CmeDTO = object({
  id: number().optional(),
  credits: number().min(1).or(string().min(1)),
  name: string().min(1),
  topic: string().min(1),
  type: string().min(1),
  level: string().nullish(),
  completed: string().min(1),
  certificate: string().min(1),
  document_id: number().min(1),
  apply_licenses_by_topic: array(applyLicensesByTopicDTO).min(1).default([]),
  notes: string().nullish(),
  provider_id: number().optional(),
  cycle_params: array(object({
    value: number(), period: string(), license_id: number().nullish(),
  })).optional(),
});
