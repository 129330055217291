import { literal, number, object, string } from 'zod';

export const PeerReferenceDTO = object({
  id: number().optional(),
  provider_id: number(),
  prefix: string().nullish(),
  full_name: string().min(1),
  degree: string().min(1),
  speciality: string().nullish(),
  email: string().email().optional().or(literal('')),
  phone: string().nullish(),
  fax: string().nullish(),
  notes: string().nullish(),
});
