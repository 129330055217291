import { boolean, number, object, string } from 'zod';

import { dateString } from './helpers';

export const ExamDTO = object({
  id: number().optional(),
  provider_id: number(),
  type: string().min(1),
  additional_type: string().nullish(),
  // additional_type: string().when('location', {
  //   is: () => form.values.type === 'Other' || globals.getItems('exam_type', form.values.type).length > 0,
  //   then: schema => schema.required(),
  //   otherwise: schema => schema.nullable(),
  // }),
  first_try_at: dateString.nullish(),
  date: dateString.nullish(),
  is_passed: boolean().default(false),
  number_of_attempts: number().nullish().default(null),
  score: number().nullish().default(null),
  location: string().nullish(),
  notes: string().nullish(),
});
