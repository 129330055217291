import { number, object, string } from 'zod';

import { dateString, jsonAddress } from './helpers';

export const FacilityAffiliationDTO = object({
  id: number().optional(),
  provider_id: number(),
  name: string().min(1),
  address: jsonAddress.default({}),
  staff_category: string().nullish(),
  start_at: dateString,
  end_at: dateString.nullish(),
  notes: string().nullish(),
});
