import { number, object, string } from 'zod';

import { dateString } from './helpers';

export const MoreNamesDTO = object({
  id: number().optional(),
  provider_id: number(),
  moreNamesType: string().nullish(),
  first_name: string(),
  last_name: string(),
  middle_name: string().nullish(),
  start_date: dateString.nullish(),
  end_date: dateString.nullish(),
});
