import { object, string } from 'zod';

import { CountryCode } from '../codegen';

export const dateString = string().regex(/\d{4}-\d{2}-\d{2}/);

export const jsonAddress = object({
  address: string().nullish(),
  city: string().nullish(),
  state_code: string().nullish(),
  zip: string().nullish(),
  name: string().nullish(),
  country: string().nullish(),
  email: string().email().nullish(),
  phone: string().nullish(),
}).superRefine((data, ctx) => {
  if (data && data.country === CountryCode.Us && !data.state_code) {
    ctx.addIssue({
      code: 'custom',
      message: 'Required',
      path: ['state_code'],
    });
  }
});
