import { find, isArray, orderBy } from 'lodash-es';
import { defineStore } from 'pinia';

import { Address, Provider } from '@/graphql';

type ProviderArraysKey = keyof Provider
& ('addresses'
| 'assignees'
| 'board_certifications'
| 'credentials'
| 'documents'
| 'educations'
| 'exams'
| 'facility_affiliations'
| 'legal_answers'
| 'licenses'
| 'peer_references'
| 'practice_employers'
| 'birth_info'
| 'demographic_info'
| 'more_names'
| 'work_histories'
| 'malpractice_insurances');

export interface ProviderState {
  provider?: Provider;
}
export const useProviderStore = defineStore({
  id: 'provider',

  // eslint-disable-next-line
  state: () => ({
    provider: undefined,
  } as ProviderState),

  actions: {
    update(data: Provider) {
      this.provider = {
        ...this.provider,
        ...data,
      };
    },

    push(
      field: ProviderArraysKey,
      data: Provider[ProviderArraysKey],
      sort?: { field: string; direction: string },
    ) {
      if (this.provider?.[field]) {
        if (!this.provider[field]) this.provider[field] = [];
        (this.provider[field] as []).push(data);
      }

      if (this.provider?.[field] === null) this.provider[field] = data as never;

      if (sort && this.provider?.[field]) {
        this.provider[field] = orderBy(
          this.provider[field],
          sort.field,
          sort.direction.toLowerCase() as 'asc' | 'desc',
        ) as never;
      }
    },

    updateEntry(
      field: ProviderArraysKey,
      data: Address,
      sort?: { field: string; direction: string },
    ) {
      if (this.provider?.[field]) {
        if (Array.isArray(this.provider[field])) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
          this.provider[field] = this.provider[field]!
            .map((item: Address) => (item.id === data.id ? data : item));
        } else {
          this.provider[field] = data;
        }
        if (sort) {
          this.provider[field] = orderBy(
            this.provider[field],
            sort.field,
            sort.direction.toLowerCase() as 'asc' | 'desc',
          ) as never;
        }
      }
    },

    remove(field: ProviderArraysKey, id: string, primaryKey?: string) {
      if (this.provider?.[field] && isArray(this.provider[field]) && !primaryKey) {
        this.provider[field] = (this.provider[field] as never[]).filter(
          (item: { id: string }) => item.id !== id,
        ) as never;
      }
      if (this.provider?.[field] && isArray(this.provider[field]) && primaryKey) {
        this.provider[field] = (this.provider[field] as never[]).filter(
          (item: Record<string, string>) => item[primaryKey] !== id,
        ) as never;
      }
      if (this.provider?.[field] && !isArray(this.provider[field])) {
        this.provider[field] = null as never;
      }
    },

    isExist(field: keyof Provider, id: number) {
      if (!this.provider) {
        return false;
      }
      return find(this.provider[field], {
        id,
      }) as boolean;
    },
  },
});
