import { ref, watch } from 'vue';
import { LocationQuery, LocationQueryRaw, onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';

const params = ref<LocationQuery>();

const arrConKeys = new Set(['after', 'before']);
const parseArrCon = (str: string) => atob(str).split(':')[1];
const toArrCon = (str: string) => btoa(`arrayconnection:${str}`);

export function useRouterQuery<T extends LocationQuery>({
  queryKey, minificationSchema, onReset,
}: {
  queryKey: string,
  minificationSchema?: Record<string, string>,
  onReset?: () => void
}) {
  let routeQuerySetting = false;

  const route = useRoute();
  watch(() => route.name, () => {
    params.value = undefined;
  }, {
    flush: 'pre',
  });
  const router = useRouter();

  if (!params.value) params.value = route.query;

  function getFullObject<T>(object: Record<string, string>): T {
    if (!minificationSchema) return object as T;

    const obj: Record<string, string> = {};
    Object.entries(object).forEach(([key, value]) => {
      const normalKey = Object.keys(minificationSchema).find(minKey => minificationSchema[minKey] === key);
      if (!normalKey) return;

      obj[normalKey] = arrConKeys.has(normalKey) ? toArrCon(value) : value;
    });

    return obj as T;
  }

  function getMinifiedObject(obj: LocationQueryRaw): Record<string, string> {
    if (!minificationSchema) return obj as Record<string, string>;

    const minified: Record<string, string> = {};
    Object.entries(obj).forEach(([key, value]) => {
      const minifiedKey = minificationSchema[key];
      const v = value?.toString() ?? '';

      if (!minifiedKey) return;
      minified[minifiedKey] = arrConKeys.has(key as string) ? parseArrCon(v) : v;
    });

    return minified;
  }

  function getQueryParams(): T {
    if (queryKey in route.query && route.query[queryKey]) {
      const params = new URLSearchParams(atob(route.query[queryKey] as string));
      const obj = Object.fromEntries(params.entries());

      return getFullObject(obj);
    }
    // eslint-disable-next-line
    return {} as T;
  }

  async function setQueryParams(obj: LocationQueryRaw) {
    routeQuerySetting = true;
    try {
      const newQuery = {
        ...params.value,
      };
      if (Object.keys(obj).length > 0) {
        newQuery[queryKey] = btoa(new URLSearchParams(getMinifiedObject(obj)).toString());
      } else {
        delete newQuery[queryKey];
      }
      params.value = newQuery;

      await router.replace({
        query: newQuery,
      });
    } finally {
      routeQuerySetting = false;
    }
  }

  onBeforeRouteUpdate((to, from) => {
    if (onReset && !routeQuerySetting && !to.query?.[queryKey] && from.query?.[queryKey]) {
      onReset();
    }
  });

  return {
    getQueryParams,
    setQueryParams,
  };
}
