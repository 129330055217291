import { number, object, string } from 'zod';

import { dateString, jsonAddress } from './helpers';

export const MalpracticeInsuranceDTO = object({
  id: number().optional(),
  provider_id: number(),
  insurance_name: string().min(1),
  insurance_address: jsonAddress.default({}),
  policy_number: string().nullish(),
  insurer_state_code: string().nullish(),
  effective_date: dateString.nullish(),
  expiration_date: dateString.nullish(),
  retroactive_date: dateString.nullish(),
  per_incident_amount: number().max(2e9).nullish(),
  annual_aggregate_amount: number().max(2e9).nullish(),
  notes: string().nullish(),
});
