export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ConnectionCursor: any;
  Date: any;
  DateTime: any;
  DocumentsExtra: any;
  JSON: any;
  LocalDate: any;
};

export type AddApply_LicensesToCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddAssigneesToProviderGroupInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddAssigneesToProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddCmesToProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddExamsToProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddHealthcare_PayorsToProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddImport_Logs_FailedsToLicenseInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddImport_Logs_SuccessesToLicenseInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddMalpractice_InsurancesToProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddOrganizationsToProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddPeer_ReferencesToProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddProvidersToProviderGroupInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddRolesToOrganizationInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddRolesToUserInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddShareDocsToProviderShareInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddStatusesToLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type AddTeamMemberInput = {
  details: Scalars['Boolean'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  organization: Scalars['Float'];
  password: Scalars['String'];
  role: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: CountryCode;
  createdAt: Scalars['Date'];
  dea_check: Scalars['Boolean'];
  dea_license_id?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  first_line: Scalars['String'];
  id: Scalars['Float'];
  npi?: Maybe<Npi>;
  npi_number?: Maybe<Scalars['String']>;
  other_state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  purpose: AddressPurpose;
  state?: Maybe<State>;
  state_code?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressAggregateFilter = {
  and?: InputMaybe<Array<AddressAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  npi_number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type AddressDeleteResponse = {
  __typename?: 'AddressDeleteResponse';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['Date']>;
  dea_check?: Maybe<Scalars['Boolean']>;
  dea_license_id?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  first_line?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  npi_number?: Maybe<Scalars['String']>;
  other_state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  purpose?: Maybe<AddressPurpose>;
  state_code?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Address */
  node: Address;
};

export type AddressFilter = {
  and?: InputMaybe<Array<AddressFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  npi_number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressFilter>>;
  provider?: InputMaybe<AddressFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type AddressFilterProviderFilter = {
  and?: InputMaybe<Array<AddressFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export enum AddressPurpose {
  Dea = 'Dea',
  Home = 'Home',
  International = 'International',
  Location = 'Location',
  Mailing = 'Mailing',
  Military = 'Military',
  Office = 'Office',
  Other = 'Other',
  PoBox = 'PoBox',
  Practice = 'Practice',
  W_9 = 'W_9'
}

export type AddressSort = {
  direction: SortDirection;
  field: AddressSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AddressSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  NpiNumber = 'npi_number',
  ProviderId = 'provider_id'
}

export type AddressSubscriptionFilter = {
  and?: InputMaybe<Array<AddressSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  npi_number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type BadgeUpdateResult = {
  __typename?: 'BadgeUpdateResult';
  user_id: Scalars['Float'];
};

export type BoardCertification = {
  __typename?: 'BoardCertification';
  certification?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  expires_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  is_passed: Scalars['Boolean'];
  issued_at: Scalars['String'];
  name: Scalars['String'];
  provider: Provider;
  provider_id: Scalars['Float'];
  specialty: Scalars['String'];
  status: BoardCertificationStatus;
};

export type BoardCertificationAggregateFilter = {
  and?: InputMaybe<Array<BoardCertificationAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<BoardCertificationAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type BoardCertificationDeleteResponse = {
  __typename?: 'BoardCertificationDeleteResponse';
  certification?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  expires_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  is_passed?: Maybe<Scalars['Boolean']>;
  issued_at?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  specialty?: Maybe<Scalars['String']>;
  status?: Maybe<BoardCertificationStatus>;
};

export type BoardCertificationEdge = {
  __typename?: 'BoardCertificationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the BoardCertification */
  node: BoardCertification;
};

export type BoardCertificationFilter = {
  and?: InputMaybe<Array<BoardCertificationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<BoardCertificationFilter>>;
  provider?: InputMaybe<BoardCertificationFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type BoardCertificationFilterProviderFilter = {
  and?: InputMaybe<Array<BoardCertificationFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BoardCertificationFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type BoardCertificationSort = {
  direction: SortDirection;
  field: BoardCertificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BoardCertificationSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'provider_id'
}

export enum BoardCertificationStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Expired = 'Expired',
  Issue = 'Issue',
  Other = 'Other'
}

export type BoardCertificationSubscriptionFilter = {
  and?: InputMaybe<Array<BoardCertificationSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<BoardCertificationSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
};

export type BrowserDto = {
  __typename?: 'BrowserDto';
  healthy: Scalars['Boolean'];
  host: Scalars['String'];
  tabs: Scalars['Float'];
};

export type ChangeEmail = {
  email: Scalars['String'];
};

export type ChangeEmailConfirm = {
  token: Scalars['String'];
};

export type ChangePasswordInput = {
  confirm_password: Scalars['String'];
  new_password: Scalars['String'];
  user_id: Scalars['Float'];
};

export type Changelog = {
  __typename?: 'Changelog';
  action: Scalars['String'];
  createdAt: Scalars['Date'];
  data: Scalars['JSON'];
  data_old: Scalars['JSON'];
  id: Scalars['Float'];
  provider: Provider;
  provider_id: Scalars['Float'];
  search: Scalars['JSON'];
  type?: Maybe<Scalars['String']>;
  user: User;
  user_id: Scalars['Float'];
};

export type ChangelogConnection = {
  __typename?: 'ChangelogConnection';
  /** Array of edges. */
  edges: Array<ChangelogEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ChangelogDeleteResponse = {
  __typename?: 'ChangelogDeleteResponse';
  action?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['JSON']>;
  data_old?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  search?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ChangelogEdge = {
  __typename?: 'ChangelogEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Changelog */
  node: Changelog;
};

export type ChangelogFilter = {
  action?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ChangelogFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  data?: InputMaybe<JsonFieldComparison>;
  data_old?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ChangelogFilter>>;
  provider?: InputMaybe<ChangelogFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  search?: InputMaybe<JsonFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  user?: InputMaybe<ChangelogFilterUserFilter>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type ChangelogFilterProviderFilter = {
  and?: InputMaybe<Array<ChangelogFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ChangelogFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type ChangelogFilterUserFilter = {
  and?: InputMaybe<Array<ChangelogFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ChangelogFilterUserFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type ChangelogSort = {
  direction: SortDirection;
  field: ChangelogSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ChangelogSortFields {
  Action = 'action',
  CreatedAt = 'createdAt',
  Data = 'data',
  DataOld = 'data_old',
  Id = 'id',
  ProviderId = 'provider_id',
  Search = 'search',
  Type = 'type',
  UserId = 'user_id'
}

export type ChangelogSubscriptionFilter = {
  action?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ChangelogSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  data?: InputMaybe<JsonFieldComparison>;
  data_old?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ChangelogSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  search?: InputMaybe<JsonFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type CheckInviteLinkInput = {
  token: Scalars['String'];
  user_id: Scalars['String'];
};

export type ChooseStepRequestLicense = {
  request_id: Scalars['Float'];
  status: LicenseRequestStatuses;
};

export type CloseRequestLicense = {
  comment?: InputMaybe<Scalars['String']>;
  license_id?: InputMaybe<Scalars['Float']>;
  request_id?: InputMaybe<Scalars['Float']>;
};

export type Cme = {
  __typename?: 'Cme';
  apply_licenses: Array<License>;
  apply_licensesAggregate: Array<CmeApply_LicensesAggregateResponse>;
  apply_licenses_by_topic?: Maybe<Scalars['JSON']>;
  apply_licenses_ids?: Maybe<Scalars['JSON']>;
  completed: Scalars['String'];
  createdAt: Scalars['Date'];
  credits: Scalars['String'];
  document?: Maybe<Document>;
  document_id?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  topic: Scalars['String'];
  type: Scalars['String'];
};


export type CmeApply_LicensesArgs = {
  filter?: LicenseFilter;
  sorting?: Array<LicenseSort>;
};


export type CmeApply_LicensesAggregateArgs = {
  filter?: InputMaybe<LicenseAggregateFilter>;
};

export type CmeAggregateFilter = {
  and?: InputMaybe<Array<CmeAggregateFilter>>;
  apply_licenses_by_topic?: InputMaybe<JsonFieldComparison>;
  apply_licenses_ids?: InputMaybe<JsonFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  credits?: InputMaybe<StringFieldComparison>;
  document_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  topic?: InputMaybe<StringFieldComparison>;
};

export type CmeApply_LicensesAggregateGroupBy = {
  __typename?: 'CmeApply_licensesAggregateGroupBy';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  monitor?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
  verify?: Maybe<Scalars['Boolean']>;
};

export type CmeApply_LicensesAggregateResponse = {
  __typename?: 'CmeApply_licensesAggregateResponse';
  avg?: Maybe<CmeApply_LicensesAvgAggregate>;
  count?: Maybe<CmeApply_LicensesCountAggregate>;
  groupBy?: Maybe<CmeApply_LicensesAggregateGroupBy>;
  max?: Maybe<CmeApply_LicensesMaxAggregate>;
  min?: Maybe<CmeApply_LicensesMinAggregate>;
  sum?: Maybe<CmeApply_LicensesSumAggregate>;
};

export type CmeApply_LicensesAvgAggregate = {
  __typename?: 'CmeApply_licensesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

export type CmeApply_LicensesCountAggregate = {
  __typename?: 'CmeApply_licensesCountAggregate';
  expires_at?: Maybe<Scalars['Int']>;
  extra?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  monitor?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_type_code?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
  request_type?: Maybe<Scalars['Int']>;
  state_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Int']>;
  verify?: Maybe<Scalars['Int']>;
};

export type CmeApply_LicensesMaxAggregate = {
  __typename?: 'CmeApply_licensesMaxAggregate';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
};

export type CmeApply_LicensesMinAggregate = {
  __typename?: 'CmeApply_licensesMinAggregate';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
};

export type CmeApply_LicensesSumAggregate = {
  __typename?: 'CmeApply_licensesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

export type CmeConnection = {
  __typename?: 'CmeConnection';
  /** Array of edges. */
  edges: Array<CmeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type CmeCycle = {
  __typename?: 'CmeCycle';
  id: Scalars['Float'];
  license_id: Scalars['Float'];
  period: Scalars['String'];
  value: Scalars['Float'];
};

export type CmeCycleConnection = {
  __typename?: 'CmeCycleConnection';
  /** Array of edges. */
  edges: Array<CmeCycleEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CmeCycleDeleteFilter = {
  and?: InputMaybe<Array<CmeCycleDeleteFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeCycleDeleteFilter>>;
  period?: InputMaybe<StringFieldComparison>;
  value?: InputMaybe<NumberFieldComparison>;
};

export type CmeCycleDeleteResponse = {
  __typename?: 'CmeCycleDeleteResponse';
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
  period?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CmeCycleEdge = {
  __typename?: 'CmeCycleEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the CmeCycle */
  node: CmeCycle;
};

export type CmeCycleFilter = {
  and?: InputMaybe<Array<CmeCycleFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeCycleFilter>>;
  period?: InputMaybe<StringFieldComparison>;
  value?: InputMaybe<NumberFieldComparison>;
};

export type CmeCycleSort = {
  direction: SortDirection;
  field: CmeCycleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CmeCycleSortFields {
  Id = 'id',
  LicenseId = 'license_id',
  Period = 'period',
  Value = 'value'
}

export type CmeCycleUpdateFilter = {
  and?: InputMaybe<Array<CmeCycleUpdateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeCycleUpdateFilter>>;
  period?: InputMaybe<StringFieldComparison>;
  value?: InputMaybe<NumberFieldComparison>;
};

export type CmeDeleteFilter = {
  and?: InputMaybe<Array<CmeDeleteFilter>>;
  apply_licenses_by_topic?: InputMaybe<JsonFieldComparison>;
  apply_licenses_ids?: InputMaybe<JsonFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  credits?: InputMaybe<StringFieldComparison>;
  document_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeDeleteFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  topic?: InputMaybe<StringFieldComparison>;
};

export type CmeDeleteResponse = {
  __typename?: 'CmeDeleteResponse';
  apply_licenses_by_topic?: Maybe<Scalars['JSON']>;
  apply_licenses_ids?: Maybe<Scalars['JSON']>;
  completed?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  credits?: Maybe<Scalars['String']>;
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CmeEdge = {
  __typename?: 'CmeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Cme */
  node: Cme;
};

export type CmeFilter = {
  and?: InputMaybe<Array<CmeFilter>>;
  apply_licenses?: InputMaybe<CmeFilterLicenseFilter>;
  apply_licenses_by_topic?: InputMaybe<JsonFieldComparison>;
  apply_licenses_ids?: InputMaybe<JsonFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  credits?: InputMaybe<StringFieldComparison>;
  document?: InputMaybe<CmeFilterDocumentFilter>;
  document_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeFilter>>;
  provider?: InputMaybe<CmeFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  topic?: InputMaybe<StringFieldComparison>;
};

export type CmeFilterDocumentFilter = {
  and?: InputMaybe<Array<CmeFilterDocumentFilter>>;
  extra?: InputMaybe<DocumentsExtraScalarFilterComparison>;
  hash?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  mime?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeFilterDocumentFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  size?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  uploadedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<BooleanFieldComparison>;
  visibility?: InputMaybe<NumberFieldComparison>;
};

export type CmeFilterLicenseFilter = {
  and?: InputMaybe<Array<CmeFilterLicenseFilter>>;
  expires_at?: InputMaybe<LocalDateFilterComparison>;
  extra?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  monitor?: InputMaybe<BooleanFieldComparison>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeFilterLicenseFilter>>;
  primary?: InputMaybe<BooleanFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<NumberFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseStatusFilterComparison>;
  type?: InputMaybe<LicenseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<LicenseVerificationStatusFilterComparison>;
  verify?: InputMaybe<BooleanFieldComparison>;
};

export type CmeFilterProviderFilter = {
  and?: InputMaybe<Array<CmeFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type CmeGeneralSettings = {
  __typename?: 'CmeGeneralSettings';
  enabled: Scalars['Boolean'];
  id: Scalars['Float'];
  initial: Scalars['Boolean'];
  providerType: ProviderType;
  providerTypeCode: Scalars['String'];
  renew: Scalars['Boolean'];
};

export type CmeGeneralSettingsConnection = {
  __typename?: 'CmeGeneralSettingsConnection';
  /** Array of edges. */
  edges: Array<CmeGeneralSettingsEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CmeGeneralSettingsDeleteFilter = {
  and?: InputMaybe<Array<CmeGeneralSettingsDeleteFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeGeneralSettingsDeleteFilter>>;
  providerTypeCode?: InputMaybe<StringFieldComparison>;
};

export type CmeGeneralSettingsDeleteResponse = {
  __typename?: 'CmeGeneralSettingsDeleteResponse';
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  initial?: Maybe<Scalars['Boolean']>;
  providerTypeCode?: Maybe<Scalars['String']>;
  renew?: Maybe<Scalars['Boolean']>;
};

export type CmeGeneralSettingsEdge = {
  __typename?: 'CmeGeneralSettingsEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the CmeGeneralSettings */
  node: CmeGeneralSettings;
};

export type CmeGeneralSettingsFilter = {
  and?: InputMaybe<Array<CmeGeneralSettingsFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeGeneralSettingsFilter>>;
  providerType?: InputMaybe<CmeGeneralSettingsFilterProviderTypeFilter>;
  providerTypeCode?: InputMaybe<StringFieldComparison>;
};

export type CmeGeneralSettingsFilterProviderTypeFilter = {
  and?: InputMaybe<Array<CmeGeneralSettingsFilterProviderTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeGeneralSettingsFilterProviderTypeFilter>>;
};

export type CmeGeneralSettingsSort = {
  direction: SortDirection;
  field: CmeGeneralSettingsSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CmeGeneralSettingsSortFields {
  Id = 'id',
  ProviderTypeCode = 'providerTypeCode'
}

export type CmeGeneralSettingsUpdateFilter = {
  and?: InputMaybe<Array<CmeGeneralSettingsUpdateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeGeneralSettingsUpdateFilter>>;
  providerTypeCode?: InputMaybe<StringFieldComparison>;
};

export type CmeSettings = {
  __typename?: 'CmeSettings';
  additionalInformation?: Maybe<Scalars['String']>;
  additionalTopics?: Maybe<Scalars['JSON']>;
  gracePeriod: CmeSettingsGracePeriod;
  gracePeriodValue?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  moreInformation?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
  providerType: ProviderType;
  providerTypeCode: Scalars['String'];
  renewal: CmeSettingsRenewal;
  renewalValue?: Maybe<Scalars['String']>;
  required: CmeSettingsReuqired;
  requirements: CmeSettingsRequirements;
  requirementsValue?: Maybe<Scalars['Float']>;
  show: Scalars['Boolean'];
  state: State;
  stateCode: Scalars['String'];
  statuteRuleRegulation?: Maybe<Scalars['String']>;
  type: CmeSettingsType;
  typeValues?: Maybe<Scalars['JSON']>;
};

export type CmeSettingsConnection = {
  __typename?: 'CmeSettingsConnection';
  /** Array of edges. */
  edges: Array<CmeSettingsEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CmeSettingsDeleteFilter = {
  and?: InputMaybe<Array<CmeSettingsDeleteFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeSettingsDeleteFilter>>;
  providerTypeCode?: InputMaybe<StringFieldComparison>;
  stateCode?: InputMaybe<StringFieldComparison>;
};

export type CmeSettingsDeleteResponse = {
  __typename?: 'CmeSettingsDeleteResponse';
  additionalInformation?: Maybe<Scalars['String']>;
  additionalTopics?: Maybe<Scalars['JSON']>;
  gracePeriod?: Maybe<CmeSettingsGracePeriod>;
  gracePeriodValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  moreInformation?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
  providerTypeCode?: Maybe<Scalars['String']>;
  renewal?: Maybe<CmeSettingsRenewal>;
  renewalValue?: Maybe<Scalars['String']>;
  required?: Maybe<CmeSettingsReuqired>;
  requirements?: Maybe<CmeSettingsRequirements>;
  requirementsValue?: Maybe<Scalars['Float']>;
  show?: Maybe<Scalars['Boolean']>;
  stateCode?: Maybe<Scalars['String']>;
  statuteRuleRegulation?: Maybe<Scalars['String']>;
  type?: Maybe<CmeSettingsType>;
  typeValues?: Maybe<Scalars['JSON']>;
};

export type CmeSettingsEdge = {
  __typename?: 'CmeSettingsEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the CmeSettings */
  node: CmeSettings;
};

export type CmeSettingsFilter = {
  and?: InputMaybe<Array<CmeSettingsFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeSettingsFilter>>;
  providerType?: InputMaybe<CmeSettingsFilterProviderTypeFilter>;
  providerTypeCode?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<CmeSettingsFilterStateFilter>;
  stateCode?: InputMaybe<StringFieldComparison>;
};

export type CmeSettingsFilterProviderTypeFilter = {
  and?: InputMaybe<Array<CmeSettingsFilterProviderTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeSettingsFilterProviderTypeFilter>>;
};

export type CmeSettingsFilterStateFilter = {
  and?: InputMaybe<Array<CmeSettingsFilterStateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeSettingsFilterStateFilter>>;
};

export enum CmeSettingsGracePeriod {
  No = 'No',
  Yes = 'Yes'
}

export type CmeSettingsInput = {
  direction: Scalars['String'];
  settings: Scalars['JSON'];
};

export type CmeSettingsQueryResponse = {
  __typename?: 'CmeSettingsQueryResponse';
  general: Scalars['JSON'];
  initial: Scalars['JSON'];
  renew: Scalars['JSON'];
};

export enum CmeSettingsRenewal {
  BirthDate = 'BirthDate',
  BirthMonth = 'BirthMonth',
  BirthMonthLastDay = 'BirthMonthLastDay',
  Custom = 'Custom',
  Date = 'Date',
  DateEvenYear = 'DateEvenYear',
  DateOddYear = 'DateOddYear',
  IssueDate = 'IssueDate',
  IssueMonthFirstDay = 'IssueMonthFirstDay',
  Varies = 'Varies'
}

export enum CmeSettingsRequirements {
  Common = 'Common',
  Custom = 'Custom'
}

export type CmeSettingsResponse = {
  __typename?: 'CmeSettingsResponse';
  direction: Scalars['String'];
  settings: Scalars['JSON'];
};

export enum CmeSettingsReuqired {
  No = 'No',
  Yes = 'Yes'
}

export type CmeSettingsSort = {
  direction: SortDirection;
  field: CmeSettingsSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CmeSettingsSortFields {
  Id = 'id',
  ProviderTypeCode = 'providerTypeCode',
  StateCode = 'stateCode'
}

export type CmeSettingsSubscriptionFilter = {
  and?: InputMaybe<Array<CmeSettingsSubscriptionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeSettingsSubscriptionFilter>>;
  providerTypeCode?: InputMaybe<StringFieldComparison>;
  stateCode?: InputMaybe<StringFieldComparison>;
};

export enum CmeSettingsType {
  Common = 'Common',
  Custom = 'Custom'
}

export type CmeSettingsUpdateFilter = {
  and?: InputMaybe<Array<CmeSettingsUpdateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CmeSettingsUpdateFilter>>;
  providerTypeCode?: InputMaybe<StringFieldComparison>;
  stateCode?: InputMaybe<StringFieldComparison>;
};

export type CmeSort = {
  direction: SortDirection;
  field: CmeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CmeSortFields {
  ApplyLicensesByTopic = 'apply_licenses_by_topic',
  ApplyLicensesIds = 'apply_licenses_ids',
  CreatedAt = 'createdAt',
  Credits = 'credits',
  DocumentId = 'document_id',
  Id = 'id',
  Name = 'name',
  ProviderId = 'provider_id',
  Topic = 'topic'
}

export type CmeSubscriptionFilter = {
  and?: InputMaybe<Array<CmeSubscriptionFilter>>;
  apply_licenses_by_topic?: InputMaybe<JsonFieldComparison>;
  apply_licenses_ids?: InputMaybe<JsonFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  credits?: InputMaybe<StringFieldComparison>;
  document_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  topic?: InputMaybe<StringFieldComparison>;
};

export type CmeUpdateFilter = {
  and?: InputMaybe<Array<CmeUpdateFilter>>;
  apply_licenses_by_topic?: InputMaybe<JsonFieldComparison>;
  apply_licenses_ids?: InputMaybe<JsonFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  credits?: InputMaybe<StringFieldComparison>;
  document_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CmeUpdateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  topic?: InputMaybe<StringFieldComparison>;
};

export enum CountryCode {
  Ab = 'AB',
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Os = 'OS',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type CountryCodeFilterComparison = {
  eq?: InputMaybe<CountryCode>;
  gt?: InputMaybe<CountryCode>;
  gte?: InputMaybe<CountryCode>;
  iLike?: InputMaybe<CountryCode>;
  in?: InputMaybe<Array<CountryCode>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<CountryCode>;
  lt?: InputMaybe<CountryCode>;
  lte?: InputMaybe<CountryCode>;
  neq?: InputMaybe<CountryCode>;
  notILike?: InputMaybe<CountryCode>;
  notIn?: InputMaybe<Array<CountryCode>>;
  notLike?: InputMaybe<CountryCode>;
};

export type CreateAddress = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  createdAt?: InputMaybe<Scalars['Date']>;
  dea_check?: InputMaybe<Scalars['Boolean']>;
  dea_license_id?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  first_line?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  npi_number?: InputMaybe<Scalars['String']>;
  other_state?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  purpose?: InputMaybe<AddressPurpose>;
  state_code?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreateAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: AddressSubscriptionFilter;
};

export type CreateBoardCertification = {
  certification?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  expires_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  is_passed?: InputMaybe<Scalars['Boolean']>;
  issued_at?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  specialty?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BoardCertificationStatus>;
};

export type CreateBoardCertificationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: BoardCertificationSubscriptionFilter;
};

export type CreateChangelogSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ChangelogSubscriptionFilter;
};

export type CreateCme = {
  apply_licenses_by_topic?: InputMaybe<Scalars['JSON']>;
  apply_licenses_ids?: InputMaybe<Scalars['JSON']>;
  completed: Scalars['String'];
  credits: Scalars['String'];
  cycle_params?: InputMaybe<Scalars['JSON']>;
  document_id: Scalars['Float'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  provider_id: Scalars['Float'];
  topic: Scalars['String'];
  type: Scalars['String'];
};

export type CreateCmeCycle = {
  id?: InputMaybe<Scalars['Float']>;
  license_id?: InputMaybe<Scalars['Float']>;
  period?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateCmeGeneralSettings = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  initial?: InputMaybe<Scalars['Boolean']>;
  providerTypeCode?: InputMaybe<Scalars['String']>;
  renew?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCmeSettings = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  additionalTopics?: InputMaybe<Scalars['JSON']>;
  gracePeriod?: InputMaybe<CmeSettingsGracePeriod>;
  gracePeriodValue?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  moreInformation?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Float']>;
  providerTypeCode?: InputMaybe<Scalars['String']>;
  renewal?: InputMaybe<CmeSettingsRenewal>;
  renewalValue?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<CmeSettingsReuqired>;
  requirements?: InputMaybe<CmeSettingsRequirements>;
  requirementsValue?: InputMaybe<Scalars['Float']>;
  show?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['String']>;
  statuteRuleRegulation?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CmeSettingsType>;
  typeValues?: InputMaybe<Scalars['JSON']>;
};

export type CreateCmeSettingsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CmeSettingsSubscriptionFilter;
};

export type CreateCmeSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CmeSubscriptionFilter;
};

export type CreateCredentials = {
  id?: InputMaybe<Scalars['Float']>;
  login?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateCredentialsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CredentialsSubscriptionFilter;
};

export type CreateDocumentSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: DocumentSubscriptionFilter;
};

export type CreateEducation = {
  createdAt?: InputMaybe<Scalars['Date']>;
  degree?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  institution?: InputMaybe<Scalars['String']>;
  institutionAddress?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  specialty?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateEducationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: EducationSubscriptionFilter;
};

export type CreateExam = {
  additional_type?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  date?: InputMaybe<Scalars['String']>;
  first_try_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  is_passed?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  number_of_attempts?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  score?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateExamSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ExamSubscriptionFilter;
};

export type CreateFacilityAffiliation = {
  address?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  staff_category?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityAffiliationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: FacilityAffiliationSubscriptionFilter;
};

export type CreateHealthcarePayors = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  effective_date?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  par_status?: InputMaybe<ParStatus>;
  payor_contact?: InputMaybe<Scalars['JSON']>;
  payor_name?: InputMaybe<Scalars['String']>;
  payor_state_code?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<HealthcarePayorsStatus>;
};

export type CreateHealthcarePayorsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: HealthcarePayorsSubscriptionFilter;
};

export type CreateImportLogSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ImportLogSubscriptionFilter;
};

export type CreateLegalAnswer = {
  answer?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  question_id?: InputMaybe<Scalars['Float']>;
};

export type CreateLegalAnswerSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LegalAnswerSubscriptionFilter;
};

export type CreateLegalQuestionSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LegalQuestionSubscriptionFilter;
};

export type CreateLicense = {
  cme?: InputMaybe<Scalars['JSON']>;
  expires_at?: InputMaybe<Scalars['LocalDate']>;
  extra?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['Float']>;
  issued_at?: InputMaybe<Scalars['LocalDate']>;
  last_parse?: InputMaybe<Scalars['Date']>;
  monitor?: InputMaybe<Scalars['Boolean']>;
  monitor_option?: InputMaybe<ParseInterval>;
  multi_state?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  primary_source_update?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  provider_type_code?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['Float']>;
  request_status?: InputMaybe<LicenseRequestStatuses>;
  request_type?: InputMaybe<LicenseRequestType>;
  state_code?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LicenseStatus>;
  type?: InputMaybe<LicenseType>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  verified?: InputMaybe<LicenseVerificationStatus>;
  verify?: InputMaybe<Scalars['Boolean']>;
};

export type CreateLicensePrimarySourceFile = {
  createdAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Float']>;
  license_id?: InputMaybe<Scalars['Float']>;
  pdf?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  signedUrl?: InputMaybe<Scalars['String']>;
};

export type CreateLicensePrimarySourceFileSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicensePrimarySourceFileSubscriptionFilter;
};

export type CreateLicenseRequest = {
  createdAt?: InputMaybe<Scalars['Date']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  id_type?: InputMaybe<LicenseRequestIdType>;
  license_id?: InputMaybe<Scalars['Float']>;
  org_id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  provider_type_code?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['String']>;
  request_state?: InputMaybe<Scalars['String']>;
  request_type?: InputMaybe<LicenseRequestType>;
  start_at?: InputMaybe<Scalars['String']>;
  state_code?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LicenseRequestStatuses>;
  user_id?: InputMaybe<Scalars['Float']>;
};

export type CreateLicenseRequestStatus = {
  close_comment?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  request_id?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Float']>;
  start_at?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<LicenseRequestStatuses>;
};

export type CreateLicenseRequestStatusSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseRequestStatusSubscriptionFilter;
};

export type CreateLicenseRequestSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseRequestSubscriptionFilter;
};

export type CreateLicenseSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseSubscriptionFilter;
};

export type CreateLicenseToCme = {
  cme_cycle_id?: InputMaybe<Scalars['Float']>;
  cme_id?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  license_id?: InputMaybe<Scalars['Float']>;
};

export type CreateLicenseToCmeSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseToCmeSubscriptionFilter;
};

export type CreateMalpracticeInsurance = {
  annual_aggregate_amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  effective_date?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  insurance_address?: InputMaybe<Scalars['JSON']>;
  insurance_name?: InputMaybe<Scalars['String']>;
  insurer_state_code?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  per_incident_amount?: InputMaybe<Scalars['Float']>;
  policy_number?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  retroactive_date?: InputMaybe<Scalars['String']>;
};

export type CreateMalpracticeInsuranceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MalpracticeInsuranceSubscriptionFilter;
};

export type CreateManyCmeCyclesInput = {
  /** Array of records to create */
  cmeCycles: Array<CreateCmeCycle>;
};

export type CreateManyCmeGeneralSettingsInput = {
  /** Array of records to create */
  cmeGeneralSettings: Array<CreateCmeGeneralSettings>;
};

export type CreateManyCmeSettingsInput = {
  /** Array of records to create */
  cmeSettings: Array<CreateCmeSettings>;
};

export type CreateManyCmesInput = {
  /** Array of records to create */
  cmes: Array<CreateCme>;
};

export type CreateManyExamsInput = {
  /** Array of records to create */
  exams: Array<CreateExam>;
};

export type CreateManyHealthcarePayorsInput = {
  /** Array of records to create */
  healthcarePayors: Array<CreateHealthcarePayors>;
};

export type CreateManyLicensePrimarySourceFilesInput = {
  /** Array of records to create */
  licensePrimarySourceFiles: Array<CreateLicensePrimarySourceFile>;
};

export type CreateManyLicenseRequestStatusesInput = {
  /** Array of records to create */
  licenseRequestStatuses: Array<CreateLicenseRequestStatus>;
};

export type CreateManyLicenseRequestsInput = {
  /** Array of records to create */
  licenseRequests: Array<CreateLicenseRequest>;
};

export type CreateManyLicenseToCmesInput = {
  /** Array of records to create */
  licenseToCmes: Array<CreateLicenseToCme>;
};

export type CreateManyMalpracticeInsurancesInput = {
  /** Array of records to create */
  malpracticeInsurances: Array<CreateMalpracticeInsurance>;
};

export type CreateManyOrganizationsInput = {
  /** Array of records to create */
  organizations: Array<CreateOrganization>;
};

export type CreateManyPeerReferencesInput = {
  /** Array of records to create */
  peerReferences: Array<CreatePeerReferences>;
};

export type CreateManyProviderGroupsInput = {
  /** Array of records to create */
  providerGroups: Array<CreateProviderGroup>;
};

export type CreateManyProviderShareDocsInput = {
  /** Array of records to create */
  providerShareDocs: Array<CreateProviderShareDoc>;
};

export type CreateManyProviderSharesInput = {
  /** Array of records to create */
  providerShares: Array<CreateProviderShare>;
};

export type CreateManyProviderToGroupsInput = {
  /** Array of records to create */
  providerToGroups: Array<CreateProviderToGroup>;
};

export type CreateManyProviderTypesInput = {
  /** Array of records to create */
  providerTypes: Array<CreateProviderType>;
};

export type CreateManyStatesInput = {
  /** Array of records to create */
  states: Array<CreateState>;
};

export type CreateNpiSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: NpiSubscriptionFilter;
};

export type CreateOneAddressInput = {
  /** The record to create */
  address: CreateAddress;
};

export type CreateOneBoardCertificationInput = {
  /** The record to create */
  boardCertification: CreateBoardCertification;
};

export type CreateOneCmeCycleInput = {
  /** The record to create */
  cmeCycle: CreateCmeCycle;
};

export type CreateOneCmeGeneralSettingsInput = {
  /** The record to create */
  cmeGeneralSettings: CreateCmeGeneralSettings;
};

export type CreateOneCmeInput = {
  /** The record to create */
  cme: CreateCme;
};

export type CreateOneCmeSettingsInput = {
  /** The record to create */
  cmeSettings: CreateCmeSettings;
};

export type CreateOneCredentialsInput = {
  /** The record to create */
  credentials: CreateCredentials;
};

export type CreateOneEducationInput = {
  /** The record to create */
  education: CreateEducation;
};

export type CreateOneExamInput = {
  /** The record to create */
  exam: CreateExam;
};

export type CreateOneFacilityAffiliationInput = {
  /** The record to create */
  facilityAffiliation: CreateFacilityAffiliation;
};

export type CreateOneHealthcarePayorsInput = {
  /** The record to create */
  healthcarePayors: CreateHealthcarePayors;
};

export type CreateOneLegalAnswerInput = {
  /** The record to create */
  legalAnswer: CreateLegalAnswer;
};

export type CreateOneLicenseInput = {
  /** The record to create */
  license: CreateLicense;
};

export type CreateOneLicensePrimarySourceFileInput = {
  /** The record to create */
  licensePrimarySourceFile: CreateLicensePrimarySourceFile;
};

export type CreateOneLicenseRequestInput = {
  /** The record to create */
  licenseRequest: CreateLicenseRequest;
};

export type CreateOneLicenseRequestStatusInput = {
  /** The record to create */
  licenseRequestStatus: CreateLicenseRequestStatus;
};

export type CreateOneLicenseToCmeInput = {
  /** The record to create */
  licenseToCme: CreateLicenseToCme;
};

export type CreateOneMalpracticeInsuranceInput = {
  /** The record to create */
  malpracticeInsurance: CreateMalpracticeInsurance;
};

export type CreateOneOrganizationInput = {
  /** The record to create */
  organization: CreateOrganization;
};

export type CreateOnePeerReferencesInput = {
  /** The record to create */
  peerReferences: CreatePeerReferences;
};

export type CreateOnePracticeEmployerInput = {
  /** The record to create */
  practiceEmployer: CreatePracticeEmployer;
};

export type CreateOneProviderBirthInfoInput = {
  /** The record to create */
  providerBirthInfo: CreateProviderBirthInfo;
};

export type CreateOneProviderDemographicInfoInput = {
  /** The record to create */
  providerDemographicInfo: CreateProviderDemographicInfo;
};

export type CreateOneProviderGroupInput = {
  /** The record to create */
  providerGroup: CreateProviderGroup;
};

export type CreateOneProviderMoreNamesInput = {
  /** The record to create */
  providerMoreNames: CreateProviderMoreNames;
};

export type CreateOneProviderShareDocInput = {
  /** The record to create */
  providerShareDoc: CreateProviderShareDoc;
};

export type CreateOneProviderShareInput = {
  /** The record to create */
  providerShare: CreateProviderShare;
};

export type CreateOneProviderToGroupInput = {
  /** The record to create */
  providerToGroup: CreateProviderToGroup;
};

export type CreateOneProviderTypeInput = {
  /** The record to create */
  providerType: CreateProviderType;
};

export type CreateOneRoleInput = {
  /** The record to create */
  role: CreateRole;
};

export type CreateOneStateInput = {
  /** The record to create */
  state: CreateState;
};

export type CreateOneWorkHistoryInput = {
  /** The record to create */
  workHistory: CreateWorkHistory;
};

export type CreateOrganization = {
  address?: InputMaybe<Scalars['JSON']>;
  admins: Array<Scalars['String']>;
  email: Scalars['String'];
  fax?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  npi?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  tax_id?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: OrganizationSubscriptionFilter;
};

export type CreatePeerReferences = {
  createdAt?: InputMaybe<Scalars['Date']>;
  degree?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<PeerReferencesPrefix>;
  provider_id?: InputMaybe<Scalars['Float']>;
  speciality?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};

export type CreatePeerReferencesSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PeerReferencesSubscriptionFilter;
};

export type CreatePracticeEmployer = {
  createdAt?: InputMaybe<Scalars['Date']>;
  employer_address?: InputMaybe<Scalars['JSON']>;
  employer_name?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  office_type?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  start_at?: InputMaybe<Scalars['String']>;
};

export type CreatePracticeEmployerSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PracticeEmployerSubscriptionFilter;
};

export type CreateProviderBirthInfo = {
  birthCity?: InputMaybe<Scalars['String']>;
  birthCountry?: InputMaybe<CountryCode>;
  birthCounty?: InputMaybe<Scalars['String']>;
  birthProvince?: InputMaybe<Scalars['String']>;
  countryOfCitizenship?: InputMaybe<CountryCode>;
  createdAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
};

export type CreateProviderBirthInfoSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderBirthInfoSubscriptionFilter;
};

export type CreateProviderDemographicInfo = {
  createdAt?: InputMaybe<Scalars['Date']>;
  ethnicity?: InputMaybe<Ethnicity>;
  eyeColor?: InputMaybe<EyeColor>;
  hairColor?: InputMaybe<HairColor>;
  heightFt?: InputMaybe<Scalars['Float']>;
  heightIn?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  otherEthnicity?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  weightLbs?: InputMaybe<Scalars['Float']>;
};

export type CreateProviderDemographicInfoSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderDemographicInfoSubscriptionFilter;
};

export type CreateProviderGroup = {
  createdAt?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateProviderGroupSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderGroupSubscriptionFilter;
};

export type CreateProviderMoreNames = {
  createdAt?: InputMaybe<Scalars['Date']>;
  end_date?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  moreNamesType?: InputMaybe<MoreNamesType>;
  provider_id?: InputMaybe<Scalars['Float']>;
  start_date?: InputMaybe<Scalars['String']>;
};

export type CreateProviderMoreNamesSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderMoreNamesSubscriptionFilter;
};

export type CreateProviderShare = {
  address?: InputMaybe<Scalars['Boolean']>;
  birth_info?: InputMaybe<Scalars['Boolean']>;
  board_certification?: InputMaybe<Scalars['Boolean']>;
  cancelAt?: InputMaybe<Scalars['Date']>;
  cme?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  csr_licenses?: InputMaybe<Scalars['Boolean']>;
  dea_licenses?: InputMaybe<Scalars['Boolean']>;
  demographic_info?: InputMaybe<Scalars['Boolean']>;
  education?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  exams?: InputMaybe<Scalars['Boolean']>;
  facility_affiliations?: InputMaybe<Scalars['Boolean']>;
  healthcare_payors?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  legal_questions?: InputMaybe<Scalars['Boolean']>;
  malpractice_insurance?: InputMaybe<Scalars['Boolean']>;
  more_names?: InputMaybe<Scalars['Boolean']>;
  npdb?: InputMaybe<Scalars['Boolean']>;
  npi?: InputMaybe<Scalars['Boolean']>;
  peer_references?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
  photo?: InputMaybe<Scalars['Boolean']>;
  practice_employer?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  share_to?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<Scalars['String']>;
  state_licenses?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  work_email?: InputMaybe<Scalars['Boolean']>;
  work_history?: InputMaybe<Scalars['Boolean']>;
  work_phone?: InputMaybe<Scalars['Boolean']>;
};

export type CreateProviderShareDoc = {
  createdAt?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  path?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  share_id?: InputMaybe<Scalars['Float']>;
};

export type CreateProviderShareDocSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderShareDocSubscriptionFilter;
};

export type CreateProviderShareSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderShareSubscriptionFilter;
};

export type CreateProviderSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderSubscriptionFilter;
};

export type CreateProviderToGroup = {
  group_id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateProviderToGroupSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderToGroupSubscriptionFilter;
};

export type CreateProviderType = {
  code?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateRole = {
  copy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Scalars['JSON'];
};

export type CreateRoleSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RoleSubscriptionFilter;
};

export type CreateState = {
  code?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateUserSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: UserSubscriptionFilter;
};

export type CreateWorkHistory = {
  createdAt?: InputMaybe<Scalars['Date']>;
  employer_address?: InputMaybe<Scalars['JSON']>;
  employer_name?: InputMaybe<Scalars['String']>;
  employer_type?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  start_at?: InputMaybe<Scalars['String']>;
};

export type CreateWorkHistorySubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: WorkHistorySubscriptionFilter;
};

export type Credentials = {
  __typename?: 'Credentials';
  id: Scalars['Float'];
  login: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  website: Scalars['String'];
};

export type CredentialsAggregateFilter = {
  and?: InputMaybe<Array<CredentialsAggregateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  login?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CredentialsAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  website?: InputMaybe<StringFieldComparison>;
};

export type CredentialsAggregateGroupBy = {
  __typename?: 'CredentialsAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
};

export type CredentialsAggregateResponse = {
  __typename?: 'CredentialsAggregateResponse';
  avg?: Maybe<CredentialsAvgAggregate>;
  count?: Maybe<CredentialsCountAggregate>;
  groupBy?: Maybe<CredentialsAggregateGroupBy>;
  max?: Maybe<CredentialsMaxAggregate>;
  min?: Maybe<CredentialsMinAggregate>;
  sum?: Maybe<CredentialsSumAggregate>;
};

export type CredentialsAvgAggregate = {
  __typename?: 'CredentialsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type CredentialsConnection = {
  __typename?: 'CredentialsConnection';
  /** Array of edges. */
  edges: Array<CredentialsEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CredentialsCountAggregate = {
  __typename?: 'CredentialsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  login?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['Int']>;
};

export type CredentialsDeleteResponse = {
  __typename?: 'CredentialsDeleteResponse';
  id?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
};

export type CredentialsEdge = {
  __typename?: 'CredentialsEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Credentials */
  node: Credentials;
};

export type CredentialsFilter = {
  and?: InputMaybe<Array<CredentialsFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  login?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CredentialsFilter>>;
  provider?: InputMaybe<CredentialsFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  website?: InputMaybe<StringFieldComparison>;
};

export type CredentialsFilterProviderFilter = {
  and?: InputMaybe<Array<CredentialsFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CredentialsFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type CredentialsMaxAggregate = {
  __typename?: 'CredentialsMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
};

export type CredentialsMinAggregate = {
  __typename?: 'CredentialsMinAggregate';
  id?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
};

export type CredentialsSort = {
  direction: SortDirection;
  field: CredentialsSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CredentialsSortFields {
  Id = 'id',
  Login = 'login',
  ProviderId = 'provider_id',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Website = 'website'
}

export type CredentialsSubscriptionFilter = {
  and?: InputMaybe<Array<CredentialsSubscriptionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  login?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CredentialsSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  website?: InputMaybe<StringFieldComparison>;
};

export type CredentialsSumAggregate = {
  __typename?: 'CredentialsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  stats: Scalars['JSON'];
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type DeleteManyCmeCyclesInput = {
  /** Filter to find records to delete */
  filter: CmeCycleDeleteFilter;
};

export type DeleteManyCmeGeneralSettingsInput = {
  /** Filter to find records to delete */
  filter: CmeGeneralSettingsDeleteFilter;
};

export type DeleteManyCmeSettingsInput = {
  /** Filter to find records to delete */
  filter: CmeSettingsDeleteFilter;
};

export type DeleteManyCmesInput = {
  /** Filter to find records to delete */
  filter: CmeDeleteFilter;
};

export type DeleteManyExamsInput = {
  /** Filter to find records to delete */
  filter: ExamDeleteFilter;
};

export type DeleteManyHealthcarePayorsInput = {
  /** Filter to find records to delete */
  filter: HealthcarePayorsDeleteFilter;
};

export type DeleteManyLicensePrimarySourceFilesInput = {
  /** Filter to find records to delete */
  filter: LicensePrimarySourceFileDeleteFilter;
};

export type DeleteManyLicenseRequestStatusesInput = {
  /** Filter to find records to delete */
  filter: LicenseRequestStatusDeleteFilter;
};

export type DeleteManyLicenseRequestsInput = {
  /** Filter to find records to delete */
  filter: LicenseRequestDeleteFilter;
};

export type DeleteManyLicenseToCmesInput = {
  /** Filter to find records to delete */
  filter: LicenseToCmeDeleteFilter;
};

export type DeleteManyMalpracticeInsurancesInput = {
  /** Filter to find records to delete */
  filter: MalpracticeInsuranceDeleteFilter;
};

export type DeleteManyOrganizationsInput = {
  /** Filter to find records to delete */
  filter: OrganizationDeleteFilter;
};

export type DeleteManyPeerReferencesInput = {
  /** Filter to find records to delete */
  filter: PeerReferencesDeleteFilter;
};

export type DeleteManyProviderGroupsInput = {
  /** Filter to find records to delete */
  filter: ProviderGroupDeleteFilter;
};

export type DeleteManyProviderShareDocsInput = {
  /** Filter to find records to delete */
  filter: ProviderShareDocDeleteFilter;
};

export type DeleteManyProviderSharesInput = {
  /** Filter to find records to delete */
  filter: ProviderShareDeleteFilter;
};

export type DeleteManyProviderToGroupsInput = {
  /** Filter to find records to delete */
  filter: ProviderToGroupDeleteFilter;
};

export type DeleteManyProviderTypesInput = {
  /** Filter to find records to delete */
  filter: ProviderTypeDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManyStatesInput = {
  /** Filter to find records to delete */
  filter: StateDeleteFilter;
};

export type DeleteOneAddressInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: AddressSubscriptionFilter;
};

export type DeleteOneBoardCertificationInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneBoardCertificationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: BoardCertificationSubscriptionFilter;
};

export type DeleteOneChangelogSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ChangelogSubscriptionFilter;
};

export type DeleteOneCmeCycleInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneCmeGeneralSettingsInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneCmeInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneCmeSettingsInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneCmeSettingsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CmeSettingsSubscriptionFilter;
};

export type DeleteOneCmeSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CmeSubscriptionFilter;
};

export type DeleteOneCredentialsInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneCredentialsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CredentialsSubscriptionFilter;
};

export type DeleteOneDocumentInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneDocumentSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: DocumentSubscriptionFilter;
};

export type DeleteOneEducationInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneEducationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: EducationSubscriptionFilter;
};

export type DeleteOneExamInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneExamSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ExamSubscriptionFilter;
};

export type DeleteOneFacilityAffiliationInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneFacilityAffiliationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: FacilityAffiliationSubscriptionFilter;
};

export type DeleteOneHealthcarePayorsInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneHealthcarePayorsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: HealthcarePayorsSubscriptionFilter;
};

export type DeleteOneImportLogSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ImportLogSubscriptionFilter;
};

export type DeleteOneLegalAnswerInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneLegalAnswerSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LegalAnswerSubscriptionFilter;
};

export type DeleteOneLegalQuestionSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LegalQuestionSubscriptionFilter;
};

export type DeleteOneLicenseInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneLicensePrimarySourceFileInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneLicensePrimarySourceFileSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicensePrimarySourceFileSubscriptionFilter;
};

export type DeleteOneLicenseRequestInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneLicenseRequestStatusInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneLicenseRequestStatusSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseRequestStatusSubscriptionFilter;
};

export type DeleteOneLicenseRequestSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseRequestSubscriptionFilter;
};

export type DeleteOneLicenseSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseSubscriptionFilter;
};

export type DeleteOneLicenseToCmeInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneLicenseToCmeSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseToCmeSubscriptionFilter;
};

export type DeleteOneMalpracticeInsuranceInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneMalpracticeInsuranceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MalpracticeInsuranceSubscriptionFilter;
};

export type DeleteOneNpiInput = {
  /** The id of the record to delete. */
  id: Scalars['String'];
};

export type DeleteOneNpiSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: NpiSubscriptionFilter;
};

export type DeleteOneOrganizationInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneOrganizationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: OrganizationSubscriptionFilter;
};

export type DeleteOnePeerReferencesInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOnePeerReferencesSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PeerReferencesSubscriptionFilter;
};

export type DeleteOnePracticeEmployerInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOnePracticeEmployerSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PracticeEmployerSubscriptionFilter;
};

export type DeleteOneProviderBirthInfoInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneProviderBirthInfoSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderBirthInfoSubscriptionFilter;
};

export type DeleteOneProviderDemographicInfoInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneProviderDemographicInfoSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderDemographicInfoSubscriptionFilter;
};

export type DeleteOneProviderGroupInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneProviderGroupSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderGroupSubscriptionFilter;
};

export type DeleteOneProviderInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneProviderMoreNamesInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneProviderMoreNamesSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderMoreNamesSubscriptionFilter;
};

export type DeleteOneProviderShareDocInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneProviderShareDocSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderShareDocSubscriptionFilter;
};

export type DeleteOneProviderShareInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneProviderShareSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderShareSubscriptionFilter;
};

export type DeleteOneProviderSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderSubscriptionFilter;
};

export type DeleteOneProviderToGroupInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneProviderToGroupSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderToGroupSubscriptionFilter;
};

export type DeleteOneProviderTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['String'];
};

export type DeleteOneRoleInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneRoleSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RoleSubscriptionFilter;
};

export type DeleteOneStateInput = {
  /** The id of the record to delete. */
  id: Scalars['String'];
};

export type DeleteOneUserSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: UserSubscriptionFilter;
};

export type DeleteOneWorkHistoryInput = {
  /** The id of the record to delete. */
  id: Scalars['Float'];
};

export type DeleteOneWorkHistorySubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: WorkHistorySubscriptionFilter;
};

export type Document = {
  __typename?: 'Document';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  previews: Array<DocumentPreview>;
  previewsAggregate: Array<DocumentPreviewsAggregateResponse>;
  provider: Provider;
  provider_id: Scalars['Float'];
  signedUrl?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  verified?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Float']>;
};


export type DocumentPreviewsArgs = {
  filter?: DocumentPreviewFilter;
  sorting?: Array<DocumentPreviewSort>;
};


export type DocumentPreviewsAggregateArgs = {
  filter?: InputMaybe<DocumentPreviewAggregateFilter>;
};

export type DocumentAggregateFilter = {
  and?: InputMaybe<Array<DocumentAggregateFilter>>;
  extra?: InputMaybe<DocumentsExtraScalarFilterComparison>;
  hash?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  mime?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DocumentAggregateFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  size?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  uploadedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<BooleanFieldComparison>;
  visibility?: InputMaybe<NumberFieldComparison>;
};

export type DocumentAggregateGroupBy = {
  __typename?: 'DocumentAggregateGroupBy';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type DocumentAggregateResponse = {
  __typename?: 'DocumentAggregateResponse';
  avg?: Maybe<DocumentAvgAggregate>;
  count?: Maybe<DocumentCountAggregate>;
  groupBy?: Maybe<DocumentAggregateGroupBy>;
  max?: Maybe<DocumentMaxAggregate>;
  min?: Maybe<DocumentMinAggregate>;
  sum?: Maybe<DocumentSumAggregate>;
};

export type DocumentAvgAggregate = {
  __typename?: 'DocumentAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** Array of edges. */
  edges: Array<DocumentEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type DocumentCountAggregate = {
  __typename?: 'DocumentCountAggregate';
  extra?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mime?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  uploadedAt?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Int']>;
  visibility?: Maybe<Scalars['Int']>;
};

export type DocumentDeleteResponse = {
  __typename?: 'DocumentDeleteResponse';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  signedUrl?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Document */
  node: Document;
};

export type DocumentFilter = {
  and?: InputMaybe<Array<DocumentFilter>>;
  extra?: InputMaybe<DocumentsExtraScalarFilterComparison>;
  hash?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  mime?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DocumentFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider?: InputMaybe<DocumentFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  size?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  uploadedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<BooleanFieldComparison>;
  visibility?: InputMaybe<NumberFieldComparison>;
};

export type DocumentFilterProviderFilter = {
  and?: InputMaybe<Array<DocumentFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DocumentFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type DocumentMaxAggregate = {
  __typename?: 'DocumentMaxAggregate';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type DocumentMinAggregate = {
  __typename?: 'DocumentMinAggregate';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type DocumentPreview = {
  __typename?: 'DocumentPreview';
  id?: Maybe<Scalars['Float']>;
  mime: Scalars['String'];
  path: Scalars['String'];
  purpose: Scalars['String'];
  signedUrl?: Maybe<Scalars['String']>;
};

export type DocumentPreviewAggregateFilter = {
  and?: InputMaybe<Array<DocumentPreviewAggregateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<DocumentPreviewAggregateFilter>>;
  purpose?: InputMaybe<StringFieldComparison>;
};

export type DocumentPreviewFilter = {
  and?: InputMaybe<Array<DocumentPreviewFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<DocumentPreviewFilter>>;
  purpose?: InputMaybe<StringFieldComparison>;
};

export type DocumentPreviewSort = {
  direction: SortDirection;
  field: DocumentPreviewSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DocumentPreviewSortFields {
  Id = 'id',
  Purpose = 'purpose'
}

export type DocumentPreviewsAggregateGroupBy = {
  __typename?: 'DocumentPreviewsAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
};

export type DocumentPreviewsAggregateResponse = {
  __typename?: 'DocumentPreviewsAggregateResponse';
  avg?: Maybe<DocumentPreviewsAvgAggregate>;
  count?: Maybe<DocumentPreviewsCountAggregate>;
  groupBy?: Maybe<DocumentPreviewsAggregateGroupBy>;
  max?: Maybe<DocumentPreviewsMaxAggregate>;
  min?: Maybe<DocumentPreviewsMinAggregate>;
  sum?: Maybe<DocumentPreviewsSumAggregate>;
};

export type DocumentPreviewsAvgAggregate = {
  __typename?: 'DocumentPreviewsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DocumentPreviewsCountAggregate = {
  __typename?: 'DocumentPreviewsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['Int']>;
};

export type DocumentPreviewsMaxAggregate = {
  __typename?: 'DocumentPreviewsMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
};

export type DocumentPreviewsMinAggregate = {
  __typename?: 'DocumentPreviewsMinAggregate';
  id?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
};

export type DocumentPreviewsSumAggregate = {
  __typename?: 'DocumentPreviewsSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type DocumentSort = {
  direction: SortDirection;
  field: DocumentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DocumentSortFields {
  Extra = 'extra',
  Hash = 'hash',
  Id = 'id',
  Mime = 'mime',
  Name = 'name',
  Path = 'path',
  ProviderId = 'provider_id',
  Size = 'size',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UploadedAt = 'uploadedAt',
  Verified = 'verified',
  Visibility = 'visibility'
}

export type DocumentSubscriptionFilter = {
  and?: InputMaybe<Array<DocumentSubscriptionFilter>>;
  extra?: InputMaybe<DocumentsExtraScalarFilterComparison>;
  hash?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  mime?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DocumentSubscriptionFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  size?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  uploadedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<BooleanFieldComparison>;
  visibility?: InputMaybe<NumberFieldComparison>;
};

export type DocumentSumAggregate = {
  __typename?: 'DocumentSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type DocumentsBadges = {
  __typename?: 'DocumentsBadges';
  documentsBadges?: Maybe<DocumentsBadgesDto>;
};

export type DocumentsBadgesDto = {
  __typename?: 'DocumentsBadgesDTO';
  cme: Scalars['Float'];
  common: Scalars['Float'];
  privateCount: Scalars['Float'];
};

export type DocumentsBadgesUpdateResult = {
  __typename?: 'DocumentsBadgesUpdateResult';
  provider_id: Scalars['Float'];
};

export type DocumentsExtraScalarFilterComparison = {
  eq?: InputMaybe<Scalars['DocumentsExtra']>;
  gt?: InputMaybe<Scalars['DocumentsExtra']>;
  gte?: InputMaybe<Scalars['DocumentsExtra']>;
  iLike?: InputMaybe<Scalars['DocumentsExtra']>;
  in?: InputMaybe<Array<Scalars['DocumentsExtra']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['DocumentsExtra']>;
  lt?: InputMaybe<Scalars['DocumentsExtra']>;
  lte?: InputMaybe<Scalars['DocumentsExtra']>;
  neq?: InputMaybe<Scalars['DocumentsExtra']>;
  notILike?: InputMaybe<Scalars['DocumentsExtra']>;
  notIn?: InputMaybe<Array<Scalars['DocumentsExtra']>>;
  notLike?: InputMaybe<Scalars['DocumentsExtra']>;
};

export type Education = {
  __typename?: 'Education';
  createdAt: Scalars['Date'];
  degree?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  institution: Scalars['String'];
  institutionAddress: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  specialty?: Maybe<Scalars['String']>;
  start_at: Scalars['String'];
  type: Scalars['String'];
};

export type EducationAggregateFilter = {
  and?: InputMaybe<Array<EducationAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  institutionAddress?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<EducationAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type EducationDeleteResponse = {
  __typename?: 'EducationDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  degree?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  institution?: Maybe<Scalars['String']>;
  institutionAddress?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  specialty?: Maybe<Scalars['String']>;
  start_at?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EducationEdge = {
  __typename?: 'EducationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Education */
  node: Education;
};

export type EducationFilter = {
  and?: InputMaybe<Array<EducationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  institutionAddress?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<EducationFilter>>;
  provider?: InputMaybe<EducationFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type EducationFilterProviderFilter = {
  and?: InputMaybe<Array<EducationFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EducationFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type EducationSort = {
  direction: SortDirection;
  field: EducationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EducationSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  InstitutionAddress = 'institutionAddress',
  ProviderId = 'provider_id',
  Type = 'type'
}

export type EducationSubscriptionFilter = {
  and?: InputMaybe<Array<EducationSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  institutionAddress?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<EducationSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export enum Ethnicity {
  AfricanAmerican = 'AFRICAN_AMERICAN',
  AmericanIndian = 'AMERICAN_INDIAN',
  Asian = 'ASIAN',
  Latino = 'LATINO',
  NoAnswer = 'NO_ANSWER',
  Other = 'OTHER',
  PacificIslander = 'PACIFIC_ISLANDER',
  Unknown = 'UNKNOWN',
  White = 'WHITE'
}

export type EthnicityFilterComparison = {
  eq?: InputMaybe<Ethnicity>;
  gt?: InputMaybe<Ethnicity>;
  gte?: InputMaybe<Ethnicity>;
  iLike?: InputMaybe<Ethnicity>;
  in?: InputMaybe<Array<Ethnicity>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Ethnicity>;
  lt?: InputMaybe<Ethnicity>;
  lte?: InputMaybe<Ethnicity>;
  neq?: InputMaybe<Ethnicity>;
  notILike?: InputMaybe<Ethnicity>;
  notIn?: InputMaybe<Array<Ethnicity>>;
  notLike?: InputMaybe<Ethnicity>;
};

export type Exam = {
  __typename?: 'Exam';
  additional_type?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  date?: Maybe<Scalars['String']>;
  first_try_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  is_passed: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_attempts?: Maybe<Scalars['Float']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  score?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
};

export type ExamAggregateFilter = {
  and?: InputMaybe<Array<ExamAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ExamAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ExamConnection = {
  __typename?: 'ExamConnection';
  /** Array of edges. */
  edges: Array<ExamEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ExamDeleteFilter = {
  and?: InputMaybe<Array<ExamDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ExamDeleteFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ExamDeleteResponse = {
  __typename?: 'ExamDeleteResponse';
  additional_type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['String']>;
  first_try_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  is_passed?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_attempts?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ExamEdge = {
  __typename?: 'ExamEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Exam */
  node: Exam;
};

export type ExamFilter = {
  and?: InputMaybe<Array<ExamFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ExamFilter>>;
  provider?: InputMaybe<ExamFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ExamFilterProviderFilter = {
  and?: InputMaybe<Array<ExamFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ExamFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type ExamSort = {
  direction: SortDirection;
  field: ExamSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ExamSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'provider_id',
  Type = 'type'
}

export type ExamSubscriptionFilter = {
  and?: InputMaybe<Array<ExamSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ExamSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ExamUpdateFilter = {
  and?: InputMaybe<Array<ExamUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ExamUpdateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export enum EyeColor {
  Amber = 'AMBER',
  Black = 'BLACK',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Hazel = 'HAZEL',
  Red = 'RED',
  Violet = 'VIOLET'
}

export type EyeColorFilterComparison = {
  eq?: InputMaybe<EyeColor>;
  gt?: InputMaybe<EyeColor>;
  gte?: InputMaybe<EyeColor>;
  iLike?: InputMaybe<EyeColor>;
  in?: InputMaybe<Array<EyeColor>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<EyeColor>;
  lt?: InputMaybe<EyeColor>;
  lte?: InputMaybe<EyeColor>;
  neq?: InputMaybe<EyeColor>;
  notILike?: InputMaybe<EyeColor>;
  notIn?: InputMaybe<Array<EyeColor>>;
  notLike?: InputMaybe<EyeColor>;
};

export type FacilityAffiliation = {
  __typename?: 'FacilityAffiliation';
  address: Scalars['JSON'];
  createdAt: Scalars['Date'];
  end_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  staff_category?: Maybe<Scalars['String']>;
  start_at: Scalars['String'];
};

export type FacilityAffiliationAggregateFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<FacilityAffiliationAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<FacilityAffiliationAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type FacilityAffiliationDeleteResponse = {
  __typename?: 'FacilityAffiliationDeleteResponse';
  address?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  end_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  staff_category?: Maybe<Scalars['String']>;
  start_at?: Maybe<Scalars['String']>;
};

export type FacilityAffiliationEdge = {
  __typename?: 'FacilityAffiliationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the FacilityAffiliation */
  node: FacilityAffiliation;
};

export type FacilityAffiliationFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<FacilityAffiliationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<FacilityAffiliationFilter>>;
  provider?: InputMaybe<FacilityAffiliationFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type FacilityAffiliationFilterProviderFilter = {
  and?: InputMaybe<Array<FacilityAffiliationFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FacilityAffiliationFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type FacilityAffiliationSort = {
  direction: SortDirection;
  field: FacilityAffiliationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FacilityAffiliationSortFields {
  Address = 'address',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'provider_id'
}

export type FacilityAffiliationSubscriptionFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<FacilityAffiliationSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<FacilityAffiliationSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export enum FetchDirection {
  CheckNcsbnId = 'CheckNcsbnId',
  CheckNewDeaAddress = 'CheckNewDeaAddress',
  RecheckDea = 'RecheckDea'
}

export type FetchSingleLicenseInput = {
  direction?: InputMaybe<FetchDirection>;
  id: Scalars['Float'];
  job_direction_type: JobDirectionType;
  ncsbn_id?: InputMaybe<Scalars['String']>;
  pdf: Scalars['Boolean'];
  primary_receipt_ncsbn_id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  provider_type_code: Scalars['String'];
  testLicense: Scalars['Boolean'];
  user_id?: InputMaybe<Scalars['Float']>;
};

export type FilterOption = {
  __typename?: 'FilterOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FlagResponse = {
  __typename?: 'FlagResponse';
  value: Scalars['Boolean'];
};

export type ForgetConfirmInput = {
  id: Scalars['Int'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  token: Scalars['String'];
};

export type ForgetInput = {
  email: Scalars['String'];
};

export enum HairColor {
  Bald = 'BALD',
  Black = 'BLACK',
  Blonde = 'BLONDE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Red = 'RED',
  White = 'WHITE'
}

export type HairColorFilterComparison = {
  eq?: InputMaybe<HairColor>;
  gt?: InputMaybe<HairColor>;
  gte?: InputMaybe<HairColor>;
  iLike?: InputMaybe<HairColor>;
  in?: InputMaybe<Array<HairColor>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<HairColor>;
  lt?: InputMaybe<HairColor>;
  lte?: InputMaybe<HairColor>;
  neq?: InputMaybe<HairColor>;
  notILike?: InputMaybe<HairColor>;
  notIn?: InputMaybe<Array<HairColor>>;
  notLike?: InputMaybe<HairColor>;
};

export type HealthcarePayors = {
  __typename?: 'HealthcarePayors';
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  effective_date?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  insurer_state: State;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  par_status?: Maybe<ParStatus>;
  payor_contact: Scalars['JSON'];
  payor_name: Scalars['String'];
  payor_state_code?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  status?: Maybe<HealthcarePayorsStatus>;
};

export type HealthcarePayorsAggregateFilter = {
  and?: InputMaybe<Array<HealthcarePayorsAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<HealthcarePayorsAggregateFilter>>;
  par_status?: InputMaybe<ParStatusFilterComparison>;
  payor_contact?: InputMaybe<JsonFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  status?: InputMaybe<HealthcarePayorsStatusFilterComparison>;
};

export type HealthcarePayorsConnection = {
  __typename?: 'HealthcarePayorsConnection';
  /** Array of edges. */
  edges: Array<HealthcarePayorsEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type HealthcarePayorsDeleteFilter = {
  and?: InputMaybe<Array<HealthcarePayorsDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<HealthcarePayorsDeleteFilter>>;
  par_status?: InputMaybe<ParStatusFilterComparison>;
  payor_contact?: InputMaybe<JsonFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  status?: InputMaybe<HealthcarePayorsStatusFilterComparison>;
};

export type HealthcarePayorsDeleteResponse = {
  __typename?: 'HealthcarePayorsDeleteResponse';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  effective_date?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  par_status?: Maybe<ParStatus>;
  payor_contact?: Maybe<Scalars['JSON']>;
  payor_name?: Maybe<Scalars['String']>;
  payor_state_code?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  status?: Maybe<HealthcarePayorsStatus>;
};

export type HealthcarePayorsEdge = {
  __typename?: 'HealthcarePayorsEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the HealthcarePayors */
  node: HealthcarePayors;
};

export type HealthcarePayorsFilter = {
  and?: InputMaybe<Array<HealthcarePayorsFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<HealthcarePayorsFilter>>;
  par_status?: InputMaybe<ParStatusFilterComparison>;
  payor_contact?: InputMaybe<JsonFieldComparison>;
  provider?: InputMaybe<HealthcarePayorsFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  status?: InputMaybe<HealthcarePayorsStatusFilterComparison>;
};

export type HealthcarePayorsFilterProviderFilter = {
  and?: InputMaybe<Array<HealthcarePayorsFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<HealthcarePayorsFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type HealthcarePayorsSort = {
  direction: SortDirection;
  field: HealthcarePayorsSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum HealthcarePayorsSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  ParStatus = 'par_status',
  PayorContact = 'payor_contact',
  ProviderId = 'provider_id',
  Status = 'status'
}

export enum HealthcarePayorsStatus {
  Active = 'Active',
  InProgress = 'InProgress',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Other = 'Other',
  Pending = 'Pending'
}

export type HealthcarePayorsStatusFilterComparison = {
  eq?: InputMaybe<HealthcarePayorsStatus>;
  gt?: InputMaybe<HealthcarePayorsStatus>;
  gte?: InputMaybe<HealthcarePayorsStatus>;
  iLike?: InputMaybe<HealthcarePayorsStatus>;
  in?: InputMaybe<Array<HealthcarePayorsStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<HealthcarePayorsStatus>;
  lt?: InputMaybe<HealthcarePayorsStatus>;
  lte?: InputMaybe<HealthcarePayorsStatus>;
  neq?: InputMaybe<HealthcarePayorsStatus>;
  notILike?: InputMaybe<HealthcarePayorsStatus>;
  notIn?: InputMaybe<Array<HealthcarePayorsStatus>>;
  notLike?: InputMaybe<HealthcarePayorsStatus>;
};

export type HealthcarePayorsSubscriptionFilter = {
  and?: InputMaybe<Array<HealthcarePayorsSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<HealthcarePayorsSubscriptionFilter>>;
  par_status?: InputMaybe<ParStatusFilterComparison>;
  payor_contact?: InputMaybe<JsonFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  status?: InputMaybe<HealthcarePayorsStatusFilterComparison>;
};

export type HealthcarePayorsUpdateFilter = {
  and?: InputMaybe<Array<HealthcarePayorsUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<HealthcarePayorsUpdateFilter>>;
  par_status?: InputMaybe<ParStatusFilterComparison>;
  payor_contact?: InputMaybe<JsonFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  status?: InputMaybe<HealthcarePayorsStatusFilterComparison>;
};

export type ImportLog = {
  __typename?: 'ImportLog';
  createdAt: Scalars['Date'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  imported_at: Scalars['Date'];
  new_values?: Maybe<Scalars['JSON']>;
  old_values?: Maybe<Scalars['JSON']>;
  pdf: Scalars['String'];
  provider: Provider;
  provider_id: Scalars['Float'];
  psv_link?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject: Scalars['String'];
  subject_id: Scalars['Float'];
};

export type ImportLogAggregateFilter = {
  and?: InputMaybe<Array<ImportLogAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ImportLogAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  raw?: InputMaybe<JsonFieldComparison>;
  status?: InputMaybe<ImportLogStatusFilterComparison>;
  subject_id?: InputMaybe<NumberFieldComparison>;
};

export type ImportLogConnection = {
  __typename?: 'ImportLogConnection';
  /** Array of edges. */
  edges: Array<ImportLogEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ImportLogDeleteResponse = {
  __typename?: 'ImportLogDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  imported_at?: Maybe<Scalars['Date']>;
  new_values?: Maybe<Scalars['JSON']>;
  old_values?: Maybe<Scalars['JSON']>;
  pdf?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  psv_link?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject?: Maybe<Scalars['String']>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type ImportLogEdge = {
  __typename?: 'ImportLogEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ImportLog */
  node: ImportLog;
};

export type ImportLogFilter = {
  and?: InputMaybe<Array<ImportLogFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ImportLogFilter>>;
  provider?: InputMaybe<ImportLogFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  raw?: InputMaybe<JsonFieldComparison>;
  status?: InputMaybe<ImportLogStatusFilterComparison>;
  subject_id?: InputMaybe<NumberFieldComparison>;
};

export type ImportLogFilterProviderFilter = {
  and?: InputMaybe<Array<ImportLogFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ImportLogFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type ImportLogSort = {
  direction: SortDirection;
  field: ImportLogSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ImportLogSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'provider_id',
  Raw = 'raw',
  Status = 'status',
  SubjectId = 'subject_id'
}

export enum ImportLogStatus {
  Failed = 'Failed',
  Successfully = 'Successfully'
}

export type ImportLogStatusFilterComparison = {
  eq?: InputMaybe<ImportLogStatus>;
  gt?: InputMaybe<ImportLogStatus>;
  gte?: InputMaybe<ImportLogStatus>;
  iLike?: InputMaybe<ImportLogStatus>;
  in?: InputMaybe<Array<ImportLogStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ImportLogStatus>;
  lt?: InputMaybe<ImportLogStatus>;
  lte?: InputMaybe<ImportLogStatus>;
  neq?: InputMaybe<ImportLogStatus>;
  notILike?: InputMaybe<ImportLogStatus>;
  notIn?: InputMaybe<Array<ImportLogStatus>>;
  notLike?: InputMaybe<ImportLogStatus>;
};

export type ImportLogSubscriptionFilter = {
  and?: InputMaybe<Array<ImportLogSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ImportLogSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  raw?: InputMaybe<JsonFieldComparison>;
  status?: InputMaybe<ImportLogStatusFilterComparison>;
  subject_id?: InputMaybe<NumberFieldComparison>;
};

export type ImportLogUpdateFilter = {
  and?: InputMaybe<Array<ImportLogUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ImportLogUpdateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  raw?: InputMaybe<JsonFieldComparison>;
  status?: InputMaybe<ImportLogStatusFilterComparison>;
  subject_id?: InputMaybe<NumberFieldComparison>;
};

export type InviteInput = {
  email: Scalars['String'];
  not_invited: Scalars['Boolean'];
  roles: Array<Scalars['String']>;
};

export type InviteProviderInput = {
  birthdate?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  not_invited: Scalars['Boolean'];
  npi?: InputMaybe<Scalars['String']>;
  npi_verified?: Scalars['Boolean'];
  phone?: InputMaybe<Scalars['String']>;
  phone_code?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  type_code: Scalars['String'];
};

export enum JobDirectionType {
  Auto = 'Auto',
  Manual = 'Manual'
}

export type JsonFieldComparison = {
  between?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  iLike?: InputMaybe<Scalars['JSON']>;
  is?: InputMaybe<Scalars['JSON']>;
  isNot?: InputMaybe<Scalars['JSON']>;
  like?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  neq?: InputMaybe<Scalars['JSON']>;
  notBetween?: InputMaybe<Scalars['JSON']>;
  notILike?: InputMaybe<Scalars['JSON']>;
  notLike?: InputMaybe<Scalars['JSON']>;
};

export type LegacyBadges = {
  __typename?: 'LegacyBadges';
  my_providers: Scalars['Float'];
};

export type LegalAnswer = {
  __typename?: 'LegalAnswer';
  answer: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  legalQuestion: LegalQuestion;
  provider: Provider;
  provider_id: Scalars['Float'];
  question_id: Scalars['Float'];
};

export type LegalAnswerAggregateFilter = {
  and?: InputMaybe<Array<LegalAnswerAggregateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LegalAnswerAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type LegalAnswerConnection = {
  __typename?: 'LegalAnswerConnection';
  /** Array of edges. */
  edges: Array<LegalAnswerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type LegalAnswerDeleteResponse = {
  __typename?: 'LegalAnswerDeleteResponse';
  answer?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type LegalAnswerEdge = {
  __typename?: 'LegalAnswerEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the LegalAnswer */
  node: LegalAnswer;
};

export type LegalAnswerFilter = {
  and?: InputMaybe<Array<LegalAnswerFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LegalAnswerFilter>>;
  provider?: InputMaybe<LegalAnswerFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type LegalAnswerFilterProviderFilter = {
  and?: InputMaybe<Array<LegalAnswerFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LegalAnswerFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type LegalAnswerSort = {
  direction: SortDirection;
  field: LegalAnswerSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LegalAnswerSortFields {
  Id = 'id',
  ProviderId = 'provider_id'
}

export type LegalAnswerSubscriptionFilter = {
  and?: InputMaybe<Array<LegalAnswerSubscriptionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LegalAnswerSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type LegalQuestion = {
  __typename?: 'LegalQuestion';
  id: Scalars['Float'];
  question: Scalars['String'];
};

export type LegalQuestionDeleteResponse = {
  __typename?: 'LegalQuestionDeleteResponse';
  id?: Maybe<Scalars['Float']>;
  question?: Maybe<Scalars['String']>;
};

export type LegalQuestionFilter = {
  and?: InputMaybe<Array<LegalQuestionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LegalQuestionFilter>>;
};

export type LegalQuestionSort = {
  direction: SortDirection;
  field: LegalQuestionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LegalQuestionSortFields {
  Id = 'id'
}

export type LegalQuestionSubscriptionFilter = {
  and?: InputMaybe<Array<LegalQuestionSubscriptionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LegalQuestionSubscriptionFilter>>;
};

export type License = {
  __typename?: 'License';
  cme?: Maybe<Scalars['JSON']>;
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id: Scalars['Float'];
  import_logs_faileds: LicenseImport_Logs_FailedsConnection;
  import_logs_failedsAggregate: Array<LicenseImport_Logs_FailedsAggregateResponse>;
  import_logs_successes: LicenseImport_Logs_SuccessesConnection;
  import_logs_successesAggregate: Array<LicenseImport_Logs_SuccessesAggregateResponse>;
  issued_at?: Maybe<Scalars['LocalDate']>;
  last_parse?: Maybe<Scalars['Date']>;
  monitor?: Maybe<Scalars['Boolean']>;
  monitor_option?: Maybe<ParseInterval>;
  multi_state?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization: Organization;
  primary?: Maybe<Scalars['Boolean']>;
  primary_source_update: Scalars['Boolean'];
  provider: Provider;
  provider_id: Scalars['Float'];
  provider_type: ProviderType;
  provider_type_code: Scalars['String'];
  request?: Maybe<LicenseRequest>;
  request_id?: Maybe<Scalars['Float']>;
  request_status?: Maybe<LicenseRequestStatuses>;
  request_type?: Maybe<LicenseRequestType>;
  state: State;
  state_code: Scalars['String'];
  status: LicenseStatus;
  type: LicenseType;
  updatedAt: Scalars['Date'];
  verified?: Maybe<LicenseVerificationStatus>;
  verify?: Maybe<Scalars['Boolean']>;
};


export type LicenseImport_Logs_FailedsArgs = {
  filter?: ImportLogFilter;
  paging?: OffsetPaging;
  sorting?: Array<ImportLogSort>;
};


export type LicenseImport_Logs_FailedsAggregateArgs = {
  filter?: InputMaybe<ImportLogAggregateFilter>;
};


export type LicenseImport_Logs_SuccessesArgs = {
  filter?: ImportLogFilter;
  paging?: OffsetPaging;
  sorting?: Array<ImportLogSort>;
};


export type LicenseImport_Logs_SuccessesAggregateArgs = {
  filter?: InputMaybe<ImportLogAggregateFilter>;
};

export type LicenseAggregateFilter = {
  and?: InputMaybe<Array<LicenseAggregateFilter>>;
  expires_at?: InputMaybe<LocalDateFilterComparison>;
  extra?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  monitor?: InputMaybe<BooleanFieldComparison>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseAggregateFilter>>;
  primary?: InputMaybe<BooleanFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<NumberFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseStatusFilterComparison>;
  type?: InputMaybe<LicenseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<LicenseVerificationStatusFilterComparison>;
  verify?: InputMaybe<BooleanFieldComparison>;
};

export type LicenseAggregateGroupBy = {
  __typename?: 'LicenseAggregateGroupBy';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  monitor?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
  verify?: Maybe<Scalars['Boolean']>;
};

export type LicenseAggregateResponse = {
  __typename?: 'LicenseAggregateResponse';
  avg?: Maybe<LicenseAvgAggregate>;
  count?: Maybe<LicenseCountAggregate>;
  groupBy?: Maybe<LicenseAggregateGroupBy>;
  max?: Maybe<LicenseMaxAggregate>;
  min?: Maybe<LicenseMinAggregate>;
  sum?: Maybe<LicenseSumAggregate>;
};

export type LicenseAvgAggregate = {
  __typename?: 'LicenseAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

export type LicenseConnection = {
  __typename?: 'LicenseConnection';
  /** Array of edges. */
  edges: Array<LicenseEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type LicenseCountAggregate = {
  __typename?: 'LicenseCountAggregate';
  expires_at?: Maybe<Scalars['Int']>;
  extra?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  monitor?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_type_code?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
  request_type?: Maybe<Scalars['Int']>;
  state_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Int']>;
  verify?: Maybe<Scalars['Int']>;
};

export type LicenseDeleteResponse = {
  __typename?: 'LicenseDeleteResponse';
  cme?: Maybe<Scalars['JSON']>;
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  issued_at?: Maybe<Scalars['LocalDate']>;
  last_parse?: Maybe<Scalars['Date']>;
  monitor?: Maybe<Scalars['Boolean']>;
  monitor_option?: Maybe<ParseInterval>;
  multi_state?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  primary_source_update?: Maybe<Scalars['Boolean']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_status?: Maybe<LicenseRequestStatuses>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
  verify?: Maybe<Scalars['Boolean']>;
};

export type LicenseEdge = {
  __typename?: 'LicenseEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the License */
  node: License;
};

export type LicenseFilter = {
  and?: InputMaybe<Array<LicenseFilter>>;
  expires_at?: InputMaybe<LocalDateFilterComparison>;
  extra?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  monitor?: InputMaybe<BooleanFieldComparison>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseFilter>>;
  organization?: InputMaybe<LicenseFilterOrganizationFilter>;
  primary?: InputMaybe<BooleanFieldComparison>;
  provider?: InputMaybe<LicenseFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type?: InputMaybe<LicenseFilterProviderTypeFilter>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request?: InputMaybe<LicenseFilterLicenseRequestFilter>;
  request_id?: InputMaybe<NumberFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state?: InputMaybe<LicenseFilterStateFilter>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseStatusFilterComparison>;
  type?: InputMaybe<LicenseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<LicenseVerificationStatusFilterComparison>;
  verify?: InputMaybe<BooleanFieldComparison>;
};

export type LicenseFilterLicenseRequestFilter = {
  and?: InputMaybe<Array<LicenseFilterLicenseRequestFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseFilterLicenseRequestFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<StringFieldComparison>;
  request_state?: InputMaybe<StringFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseRequestStatusesFilterComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type LicenseFilterOrganizationFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<LicenseFilterOrganizationFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  is_root?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseFilterOrganizationFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type LicenseFilterProviderFilter = {
  and?: InputMaybe<Array<LicenseFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type LicenseFilterProviderTypeFilter = {
  and?: InputMaybe<Array<LicenseFilterProviderTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseFilterProviderTypeFilter>>;
};

export type LicenseFilterStateFilter = {
  and?: InputMaybe<Array<LicenseFilterStateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseFilterStateFilter>>;
};

export type LicenseImport_Logs_FailedsAggregateGroupBy = {
  __typename?: 'LicenseImport_logs_failedsAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_FailedsAggregateResponse = {
  __typename?: 'LicenseImport_logs_failedsAggregateResponse';
  avg?: Maybe<LicenseImport_Logs_FailedsAvgAggregate>;
  count?: Maybe<LicenseImport_Logs_FailedsCountAggregate>;
  groupBy?: Maybe<LicenseImport_Logs_FailedsAggregateGroupBy>;
  max?: Maybe<LicenseImport_Logs_FailedsMaxAggregate>;
  min?: Maybe<LicenseImport_Logs_FailedsMinAggregate>;
  sum?: Maybe<LicenseImport_Logs_FailedsSumAggregate>;
};

export type LicenseImport_Logs_FailedsAvgAggregate = {
  __typename?: 'LicenseImport_logs_failedsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_FailedsConnection = {
  __typename?: 'LicenseImport_logs_failedsConnection';
  /** Array of nodes. */
  nodes: Array<ImportLog>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type LicenseImport_Logs_FailedsCountAggregate = {
  __typename?: 'LicenseImport_logs_failedsCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  raw?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  subject_id?: Maybe<Scalars['Int']>;
};

export type LicenseImport_Logs_FailedsMaxAggregate = {
  __typename?: 'LicenseImport_logs_failedsMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_FailedsMinAggregate = {
  __typename?: 'LicenseImport_logs_failedsMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_FailedsSumAggregate = {
  __typename?: 'LicenseImport_logs_failedsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_SuccessesAggregateGroupBy = {
  __typename?: 'LicenseImport_logs_successesAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_SuccessesAggregateResponse = {
  __typename?: 'LicenseImport_logs_successesAggregateResponse';
  avg?: Maybe<LicenseImport_Logs_SuccessesAvgAggregate>;
  count?: Maybe<LicenseImport_Logs_SuccessesCountAggregate>;
  groupBy?: Maybe<LicenseImport_Logs_SuccessesAggregateGroupBy>;
  max?: Maybe<LicenseImport_Logs_SuccessesMaxAggregate>;
  min?: Maybe<LicenseImport_Logs_SuccessesMinAggregate>;
  sum?: Maybe<LicenseImport_Logs_SuccessesSumAggregate>;
};

export type LicenseImport_Logs_SuccessesAvgAggregate = {
  __typename?: 'LicenseImport_logs_successesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_SuccessesConnection = {
  __typename?: 'LicenseImport_logs_successesConnection';
  /** Array of nodes. */
  nodes: Array<ImportLog>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type LicenseImport_Logs_SuccessesCountAggregate = {
  __typename?: 'LicenseImport_logs_successesCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  raw?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  subject_id?: Maybe<Scalars['Int']>;
};

export type LicenseImport_Logs_SuccessesMaxAggregate = {
  __typename?: 'LicenseImport_logs_successesMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_SuccessesMinAggregate = {
  __typename?: 'LicenseImport_logs_successesMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  status?: Maybe<ImportLogStatus>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseImport_Logs_SuccessesSumAggregate = {
  __typename?: 'LicenseImport_logs_successesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  subject_id?: Maybe<Scalars['Float']>;
};

export type LicenseMaxAggregate = {
  __typename?: 'LicenseMaxAggregate';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
};

export type LicenseMinAggregate = {
  __typename?: 'LicenseMinAggregate';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
};

export type LicensePrimarySourceFile = {
  __typename?: 'LicensePrimarySourceFile';
  createdAt: Scalars['Date'];
  id: Scalars['Float'];
  license: License;
  license_id: Scalars['Float'];
  pdf: Scalars['String'];
  provider: Provider;
  provider_id: Scalars['Float'];
  signedUrl?: Maybe<Scalars['String']>;
};

export type LicensePrimarySourceFileConnection = {
  __typename?: 'LicensePrimarySourceFileConnection';
  /** Array of edges. */
  edges: Array<LicensePrimarySourceFileEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type LicensePrimarySourceFileDeleteFilter = {
  and?: InputMaybe<Array<LicensePrimarySourceFileDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicensePrimarySourceFileDeleteFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type LicensePrimarySourceFileDeleteResponse = {
  __typename?: 'LicensePrimarySourceFileDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
  pdf?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type LicensePrimarySourceFileEdge = {
  __typename?: 'LicensePrimarySourceFileEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the LicensePrimarySourceFile */
  node: LicensePrimarySourceFile;
};

export type LicensePrimarySourceFileFilter = {
  and?: InputMaybe<Array<LicensePrimarySourceFileFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license?: InputMaybe<LicensePrimarySourceFileFilterLicenseFilter>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicensePrimarySourceFileFilter>>;
  provider?: InputMaybe<LicensePrimarySourceFileFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type LicensePrimarySourceFileFilterLicenseFilter = {
  and?: InputMaybe<Array<LicensePrimarySourceFileFilterLicenseFilter>>;
  expires_at?: InputMaybe<LocalDateFilterComparison>;
  extra?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  monitor?: InputMaybe<BooleanFieldComparison>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicensePrimarySourceFileFilterLicenseFilter>>;
  primary?: InputMaybe<BooleanFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<NumberFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseStatusFilterComparison>;
  type?: InputMaybe<LicenseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<LicenseVerificationStatusFilterComparison>;
  verify?: InputMaybe<BooleanFieldComparison>;
};

export type LicensePrimarySourceFileFilterProviderFilter = {
  and?: InputMaybe<Array<LicensePrimarySourceFileFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicensePrimarySourceFileFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type LicensePrimarySourceFileSort = {
  direction: SortDirection;
  field: LicensePrimarySourceFileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicensePrimarySourceFileSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  LicenseId = 'license_id',
  ProviderId = 'provider_id'
}

export type LicensePrimarySourceFileSubscriptionFilter = {
  and?: InputMaybe<Array<LicensePrimarySourceFileSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicensePrimarySourceFileSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type LicensePrimarySourceFileUpdateFilter = {
  and?: InputMaybe<Array<LicensePrimarySourceFileUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicensePrimarySourceFileUpdateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type LicenseRequest = {
  __typename?: 'LicenseRequest';
  createdAt: Scalars['Date'];
  end_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  id_type: LicenseRequestIdType;
  license?: Maybe<License>;
  license_id?: Maybe<Scalars['Float']>;
  org_id: Scalars['Float'];
  organization?: Maybe<Organization>;
  provider: Provider;
  provider_id: Scalars['Float'];
  provider_type: ProviderType;
  provider_type_code: Scalars['String'];
  request_id: Scalars['String'];
  request_state?: Maybe<Scalars['String']>;
  request_type?: Maybe<LicenseRequestType>;
  start_at?: Maybe<Scalars['String']>;
  state: State;
  state_code: Scalars['String'];
  status?: Maybe<LicenseRequestStatuses>;
  statuses: Array<LicenseRequestStatus>;
  statusesAggregate: Array<LicenseRequestStatusesAggregateResponse>;
  user?: Maybe<User>;
  user_id: Scalars['Float'];
};


export type LicenseRequestStatusesArgs = {
  filter?: LicenseRequestStatusFilter;
  sorting?: Array<LicenseRequestStatusSort>;
};


export type LicenseRequestStatusesAggregateArgs = {
  filter?: InputMaybe<LicenseRequestStatusAggregateFilter>;
};

export type LicenseRequestAggregateFilter = {
  and?: InputMaybe<Array<LicenseRequestAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestAggregateFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<StringFieldComparison>;
  request_state?: InputMaybe<StringFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseRequestStatusesFilterComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type LicenseRequestAggregateGroupBy = {
  __typename?: 'LicenseRequestAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  request_state?: Maybe<Scalars['String']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseRequestStatuses>;
  user_id?: Maybe<Scalars['Float']>;
};

export type LicenseRequestAggregateResponse = {
  __typename?: 'LicenseRequestAggregateResponse';
  avg?: Maybe<LicenseRequestAvgAggregate>;
  count?: Maybe<LicenseRequestCountAggregate>;
  groupBy?: Maybe<LicenseRequestAggregateGroupBy>;
  max?: Maybe<LicenseRequestMaxAggregate>;
  min?: Maybe<LicenseRequestMinAggregate>;
  sum?: Maybe<LicenseRequestSumAggregate>;
};

export type LicenseRequestAvgAggregate = {
  __typename?: 'LicenseRequestAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type LicenseRequestConnection = {
  __typename?: 'LicenseRequestConnection';
  /** Array of edges. */
  edges: Array<LicenseRequestEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type LicenseRequestCountAggregate = {
  __typename?: 'LicenseRequestCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  license_id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_type_code?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
  request_state?: Maybe<Scalars['Int']>;
  request_type?: Maybe<Scalars['Int']>;
  state_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type LicenseRequestDeleteFilter = {
  and?: InputMaybe<Array<LicenseRequestDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestDeleteFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<StringFieldComparison>;
  request_state?: InputMaybe<StringFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseRequestStatusesFilterComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type LicenseRequestDeleteResponse = {
  __typename?: 'LicenseRequestDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  end_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  id_type?: Maybe<LicenseRequestIdType>;
  license_id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  request_state?: Maybe<Scalars['String']>;
  request_type?: Maybe<LicenseRequestType>;
  start_at?: Maybe<Scalars['String']>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseRequestStatuses>;
  user_id?: Maybe<Scalars['Float']>;
};

export type LicenseRequestEdge = {
  __typename?: 'LicenseRequestEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the LicenseRequest */
  node: LicenseRequest;
};

export type LicenseRequestFilter = {
  and?: InputMaybe<Array<LicenseRequestFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license?: InputMaybe<LicenseRequestFilterLicenseFilter>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider?: InputMaybe<LicenseRequestFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type?: InputMaybe<LicenseRequestFilterProviderTypeFilter>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<StringFieldComparison>;
  request_state?: InputMaybe<StringFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state?: InputMaybe<LicenseRequestFilterStateFilter>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseRequestStatusesFilterComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type LicenseRequestFilterLicenseFilter = {
  and?: InputMaybe<Array<LicenseRequestFilterLicenseFilter>>;
  expires_at?: InputMaybe<LocalDateFilterComparison>;
  extra?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  monitor?: InputMaybe<BooleanFieldComparison>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestFilterLicenseFilter>>;
  primary?: InputMaybe<BooleanFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<NumberFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseStatusFilterComparison>;
  type?: InputMaybe<LicenseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<LicenseVerificationStatusFilterComparison>;
  verify?: InputMaybe<BooleanFieldComparison>;
};

export type LicenseRequestFilterProviderFilter = {
  and?: InputMaybe<Array<LicenseRequestFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type LicenseRequestFilterProviderTypeFilter = {
  and?: InputMaybe<Array<LicenseRequestFilterProviderTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestFilterProviderTypeFilter>>;
};

export type LicenseRequestFilterStateFilter = {
  and?: InputMaybe<Array<LicenseRequestFilterStateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestFilterStateFilter>>;
};

export enum LicenseRequestIdType {
  App = 'App',
  Credsy = 'Credsy'
}

export type LicenseRequestMaxAggregate = {
  __typename?: 'LicenseRequestMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  request_state?: Maybe<Scalars['String']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseRequestStatuses>;
  user_id?: Maybe<Scalars['Float']>;
};

export type LicenseRequestMinAggregate = {
  __typename?: 'LicenseRequestMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  request_state?: Maybe<Scalars['String']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseRequestStatuses>;
  user_id?: Maybe<Scalars['Float']>;
};

export type LicenseRequestSort = {
  direction: SortDirection;
  field: LicenseRequestSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicenseRequestSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  LicenseId = 'license_id',
  OrgId = 'org_id',
  ProviderId = 'provider_id',
  ProviderTypeCode = 'provider_type_code',
  RequestId = 'request_id',
  RequestState = 'request_state',
  RequestType = 'request_type',
  StateCode = 'state_code',
  Status = 'status',
  UserId = 'user_id'
}

export type LicenseRequestStatus = {
  __typename?: 'LicenseRequestStatus';
  close_comment?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  end_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  request_id: Scalars['Float'];
  sort: Scalars['Float'];
  start_at?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  value?: Maybe<LicenseRequestStatuses>;
};

export type LicenseRequestStatusAggregateFilter = {
  and?: InputMaybe<Array<LicenseRequestStatusAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestStatusAggregateFilter>>;
  request_id?: InputMaybe<NumberFieldComparison>;
  sort?: InputMaybe<NumberFieldComparison>;
  value?: InputMaybe<LicenseRequestStatusesFilterComparison>;
};

export type LicenseRequestStatusConnection = {
  __typename?: 'LicenseRequestStatusConnection';
  /** Array of edges. */
  edges: Array<LicenseRequestStatusEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type LicenseRequestStatusDeleteFilter = {
  and?: InputMaybe<Array<LicenseRequestStatusDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestStatusDeleteFilter>>;
  request_id?: InputMaybe<NumberFieldComparison>;
  sort?: InputMaybe<NumberFieldComparison>;
  value?: InputMaybe<LicenseRequestStatusesFilterComparison>;
};

export type LicenseRequestStatusDeleteResponse = {
  __typename?: 'LicenseRequestStatusDeleteResponse';
  close_comment?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  end_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  start_at?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  value?: Maybe<LicenseRequestStatuses>;
};

export type LicenseRequestStatusEdge = {
  __typename?: 'LicenseRequestStatusEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the LicenseRequestStatus */
  node: LicenseRequestStatus;
};

export type LicenseRequestStatusFilter = {
  and?: InputMaybe<Array<LicenseRequestStatusFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestStatusFilter>>;
  request_id?: InputMaybe<NumberFieldComparison>;
  sort?: InputMaybe<NumberFieldComparison>;
  value?: InputMaybe<LicenseRequestStatusesFilterComparison>;
};

export type LicenseRequestStatusSort = {
  direction: SortDirection;
  field: LicenseRequestStatusSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicenseRequestStatusSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  RequestId = 'request_id',
  Sort = 'sort',
  Value = 'value'
}

export type LicenseRequestStatusSubscriptionFilter = {
  and?: InputMaybe<Array<LicenseRequestStatusSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestStatusSubscriptionFilter>>;
  request_id?: InputMaybe<NumberFieldComparison>;
  sort?: InputMaybe<NumberFieldComparison>;
  value?: InputMaybe<LicenseRequestStatusesFilterComparison>;
};

export type LicenseRequestStatusUpdateFilter = {
  and?: InputMaybe<Array<LicenseRequestStatusUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestStatusUpdateFilter>>;
  request_id?: InputMaybe<NumberFieldComparison>;
  sort?: InputMaybe<NumberFieldComparison>;
  value?: InputMaybe<LicenseRequestStatusesFilterComparison>;
};

export enum LicenseRequestStatuses {
  BoardProcessing = 'BoardProcessing',
  Completed = 'Completed',
  Payment = 'Payment',
  Processing = 'Processing',
  Review = 'Review'
}

export type LicenseRequestStatusesAggregateGroupBy = {
  __typename?: 'LicenseRequestStatusesAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  value?: Maybe<LicenseRequestStatuses>;
};

export type LicenseRequestStatusesAggregateResponse = {
  __typename?: 'LicenseRequestStatusesAggregateResponse';
  avg?: Maybe<LicenseRequestStatusesAvgAggregate>;
  count?: Maybe<LicenseRequestStatusesCountAggregate>;
  groupBy?: Maybe<LicenseRequestStatusesAggregateGroupBy>;
  max?: Maybe<LicenseRequestStatusesMaxAggregate>;
  min?: Maybe<LicenseRequestStatusesMinAggregate>;
  sum?: Maybe<LicenseRequestStatusesSumAggregate>;
};

export type LicenseRequestStatusesAvgAggregate = {
  __typename?: 'LicenseRequestStatusesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type LicenseRequestStatusesCountAggregate = {
  __typename?: 'LicenseRequestStatusesCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type LicenseRequestStatusesFilterComparison = {
  eq?: InputMaybe<LicenseRequestStatuses>;
  gt?: InputMaybe<LicenseRequestStatuses>;
  gte?: InputMaybe<LicenseRequestStatuses>;
  iLike?: InputMaybe<LicenseRequestStatuses>;
  in?: InputMaybe<Array<LicenseRequestStatuses>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<LicenseRequestStatuses>;
  lt?: InputMaybe<LicenseRequestStatuses>;
  lte?: InputMaybe<LicenseRequestStatuses>;
  neq?: InputMaybe<LicenseRequestStatuses>;
  notILike?: InputMaybe<LicenseRequestStatuses>;
  notIn?: InputMaybe<Array<LicenseRequestStatuses>>;
  notLike?: InputMaybe<LicenseRequestStatuses>;
};

export type LicenseRequestStatusesMaxAggregate = {
  __typename?: 'LicenseRequestStatusesMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  value?: Maybe<LicenseRequestStatuses>;
};

export type LicenseRequestStatusesMinAggregate = {
  __typename?: 'LicenseRequestStatusesMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  value?: Maybe<LicenseRequestStatuses>;
};

export type LicenseRequestStatusesSumAggregate = {
  __typename?: 'LicenseRequestStatusesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type LicenseRequestSubscriptionFilter = {
  and?: InputMaybe<Array<LicenseRequestSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestSubscriptionFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<StringFieldComparison>;
  request_state?: InputMaybe<StringFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseRequestStatusesFilterComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type LicenseRequestSumAggregate = {
  __typename?: 'LicenseRequestSumAggregate';
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export enum LicenseRequestType {
  AutoRenew = 'AutoRenew',
  New = 'New',
  Reinstatement = 'Reinstatement',
  Renew = 'Renew'
}

export type LicenseRequestTypeFilterComparison = {
  eq?: InputMaybe<LicenseRequestType>;
  gt?: InputMaybe<LicenseRequestType>;
  gte?: InputMaybe<LicenseRequestType>;
  iLike?: InputMaybe<LicenseRequestType>;
  in?: InputMaybe<Array<LicenseRequestType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<LicenseRequestType>;
  lt?: InputMaybe<LicenseRequestType>;
  lte?: InputMaybe<LicenseRequestType>;
  neq?: InputMaybe<LicenseRequestType>;
  notILike?: InputMaybe<LicenseRequestType>;
  notIn?: InputMaybe<Array<LicenseRequestType>>;
  notLike?: InputMaybe<LicenseRequestType>;
};

export type LicenseRequestUpdateFilter = {
  and?: InputMaybe<Array<LicenseRequestUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseRequestUpdateFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<StringFieldComparison>;
  request_state?: InputMaybe<StringFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseRequestStatusesFilterComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type LicenseSort = {
  direction: SortDirection;
  field: LicenseSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicenseSortFields {
  ExpiresAt = 'expires_at',
  Extra = 'extra',
  Id = 'id',
  Monitor = 'monitor',
  Number = 'number',
  Primary = 'primary',
  ProviderId = 'provider_id',
  ProviderTypeCode = 'provider_type_code',
  RequestId = 'request_id',
  RequestType = 'request_type',
  StateCode = 'state_code',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Verified = 'verified',
  Verify = 'verify'
}

export enum LicenseStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Expired = 'Expired',
  Issue = 'Issue',
  None = 'None',
  Other = 'Other'
}

export type LicenseStatusFilterComparison = {
  eq?: InputMaybe<LicenseStatus>;
  gt?: InputMaybe<LicenseStatus>;
  gte?: InputMaybe<LicenseStatus>;
  iLike?: InputMaybe<LicenseStatus>;
  in?: InputMaybe<Array<LicenseStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<LicenseStatus>;
  lt?: InputMaybe<LicenseStatus>;
  lte?: InputMaybe<LicenseStatus>;
  neq?: InputMaybe<LicenseStatus>;
  notILike?: InputMaybe<LicenseStatus>;
  notIn?: InputMaybe<Array<LicenseStatus>>;
  notLike?: InputMaybe<LicenseStatus>;
};

export type LicenseSubscriptionFilter = {
  and?: InputMaybe<Array<LicenseSubscriptionFilter>>;
  expires_at?: InputMaybe<LocalDateFilterComparison>;
  extra?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  monitor?: InputMaybe<BooleanFieldComparison>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseSubscriptionFilter>>;
  primary?: InputMaybe<BooleanFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<NumberFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseStatusFilterComparison>;
  type?: InputMaybe<LicenseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<LicenseVerificationStatusFilterComparison>;
  verify?: InputMaybe<BooleanFieldComparison>;
};

export type LicenseSumAggregate = {
  __typename?: 'LicenseSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

export type LicenseToCme = {
  __typename?: 'LicenseToCme';
  cme: Cme;
  cme_cycle?: Maybe<CmeCycle>;
  cme_cycle_id?: Maybe<Scalars['Float']>;
  cme_id: Scalars['Float'];
  id: Scalars['Float'];
  license: License;
  license_id: Scalars['Float'];
};

export type LicenseToCmeConnection = {
  __typename?: 'LicenseToCmeConnection';
  /** Array of edges. */
  edges: Array<LicenseToCmeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type LicenseToCmeCursorPaging = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type LicenseToCmeCustomFilter = {
  cme_cycle?: InputMaybe<LicenseToCmeCycleFilter>;
  license_id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  state_code?: InputMaybe<Scalars['String']>;
};

export type LicenseToCmeCycleFilter = {
  license_id: Scalars['Float'];
  value: Scalars['Float'];
};

export type LicenseToCmeDto = {
  __typename?: 'LicenseToCmeDTO';
  cme?: Maybe<Cme>;
  cme_id: Scalars['Float'];
  id?: Maybe<Scalars['Float']>;
  license?: Maybe<License>;
  license_id: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type LicenseToCmeDeleteFilter = {
  and?: InputMaybe<Array<LicenseToCmeDeleteFilter>>;
  cme_cycle_id?: InputMaybe<NumberFieldComparison>;
  cme_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseToCmeDeleteFilter>>;
};

export type LicenseToCmeDeleteResponse = {
  __typename?: 'LicenseToCmeDeleteResponse';
  cme_cycle_id?: Maybe<Scalars['Float']>;
  cme_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  license_id?: Maybe<Scalars['Float']>;
};

export type LicenseToCmeEdge = {
  __typename?: 'LicenseToCmeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the LicenseToCme */
  node: LicenseToCme;
};

export type LicenseToCmeFilter = {
  and?: InputMaybe<Array<LicenseToCmeFilter>>;
  cme?: InputMaybe<LicenseToCmeFilterCmeFilter>;
  cme_cycle?: InputMaybe<LicenseToCmeFilterCmeCycleFilter>;
  cme_cycle_id?: InputMaybe<NumberFieldComparison>;
  cme_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license?: InputMaybe<LicenseToCmeFilterLicenseFilter>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseToCmeFilter>>;
};

export type LicenseToCmeFilterCmeCycleFilter = {
  and?: InputMaybe<Array<LicenseToCmeFilterCmeCycleFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseToCmeFilterCmeCycleFilter>>;
  period?: InputMaybe<StringFieldComparison>;
  value?: InputMaybe<NumberFieldComparison>;
};

export type LicenseToCmeFilterCmeFilter = {
  and?: InputMaybe<Array<LicenseToCmeFilterCmeFilter>>;
  apply_licenses_by_topic?: InputMaybe<JsonFieldComparison>;
  apply_licenses_ids?: InputMaybe<JsonFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  credits?: InputMaybe<StringFieldComparison>;
  document_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseToCmeFilterCmeFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  topic?: InputMaybe<StringFieldComparison>;
};

export type LicenseToCmeFilterLicenseFilter = {
  and?: InputMaybe<Array<LicenseToCmeFilterLicenseFilter>>;
  expires_at?: InputMaybe<LocalDateFilterComparison>;
  extra?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  monitor?: InputMaybe<BooleanFieldComparison>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicenseToCmeFilterLicenseFilter>>;
  primary?: InputMaybe<BooleanFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  provider_type_code?: InputMaybe<StringFieldComparison>;
  request_id?: InputMaybe<NumberFieldComparison>;
  request_type?: InputMaybe<LicenseRequestTypeFilterComparison>;
  state_code?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<LicenseStatusFilterComparison>;
  type?: InputMaybe<LicenseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<LicenseVerificationStatusFilterComparison>;
  verify?: InputMaybe<BooleanFieldComparison>;
};

export type LicenseToCmeSort = {
  direction: SortDirection;
  field: LicenseToCmeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicenseToCmeSortFields {
  CmeCycleId = 'cme_cycle_id',
  CmeId = 'cme_id',
  Id = 'id',
  LicenseId = 'license_id'
}

export type LicenseToCmeSubscriptionFilter = {
  and?: InputMaybe<Array<LicenseToCmeSubscriptionFilter>>;
  cme_cycle_id?: InputMaybe<NumberFieldComparison>;
  cme_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseToCmeSubscriptionFilter>>;
};

export type LicenseToCmeUpdateFilter = {
  and?: InputMaybe<Array<LicenseToCmeUpdateFilter>>;
  cme_cycle_id?: InputMaybe<NumberFieldComparison>;
  cme_id?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  license_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LicenseToCmeUpdateFilter>>;
};

export type LicenseToCmeViewConnection = {
  __typename?: 'LicenseToCmeViewConnection';
  /** Array of edges. */
  edges: Array<LicenseToCmeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export enum LicenseType {
  Csr = 'CSR',
  Dea = 'DEA',
  State = 'State'
}

export type LicenseTypeFilterComparison = {
  eq?: InputMaybe<LicenseType>;
  gt?: InputMaybe<LicenseType>;
  gte?: InputMaybe<LicenseType>;
  iLike?: InputMaybe<LicenseType>;
  in?: InputMaybe<Array<LicenseType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<LicenseType>;
  lt?: InputMaybe<LicenseType>;
  lte?: InputMaybe<LicenseType>;
  neq?: InputMaybe<LicenseType>;
  notILike?: InputMaybe<LicenseType>;
  notIn?: InputMaybe<Array<LicenseType>>;
  notLike?: InputMaybe<LicenseType>;
};

export enum LicenseVerificationStatus {
  Active = 'Active',
  Failed = 'Failed',
  Inactive = 'Inactive',
  NotVerified = 'NotVerified'
}

export type LicenseVerificationStatusFilterComparison = {
  eq?: InputMaybe<LicenseVerificationStatus>;
  gt?: InputMaybe<LicenseVerificationStatus>;
  gte?: InputMaybe<LicenseVerificationStatus>;
  iLike?: InputMaybe<LicenseVerificationStatus>;
  in?: InputMaybe<Array<LicenseVerificationStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<LicenseVerificationStatus>;
  lt?: InputMaybe<LicenseVerificationStatus>;
  lte?: InputMaybe<LicenseVerificationStatus>;
  neq?: InputMaybe<LicenseVerificationStatus>;
  notILike?: InputMaybe<LicenseVerificationStatus>;
  notIn?: InputMaybe<Array<LicenseVerificationStatus>>;
  notLike?: InputMaybe<LicenseVerificationStatus>;
};

export type LocalDateFilterComparison = {
  eq?: InputMaybe<Scalars['LocalDate']>;
  gt?: InputMaybe<Scalars['LocalDate']>;
  gte?: InputMaybe<Scalars['LocalDate']>;
  iLike?: InputMaybe<Scalars['LocalDate']>;
  in?: InputMaybe<Array<Scalars['LocalDate']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['LocalDate']>;
  lt?: InputMaybe<Scalars['LocalDate']>;
  lte?: InputMaybe<Scalars['LocalDate']>;
  neq?: InputMaybe<Scalars['LocalDate']>;
  notILike?: InputMaybe<Scalars['LocalDate']>;
  notIn?: InputMaybe<Array<Scalars['LocalDate']>>;
  notLike?: InputMaybe<Scalars['LocalDate']>;
};

export type LoginAsInput = {
  id: Scalars['Float'];
};

export type LoginInput = {
  device?: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
};

export type LoginResponseDto = {
  __typename?: 'LoginResponseDto';
  access_token: Scalars['String'];
  expires_at: Scalars['Date'];
  type: Scalars['String'];
};

export type LogoutResponseDto = {
  __typename?: 'LogoutResponseDto';
  result: Scalars['Boolean'];
  user_id?: Maybe<Scalars['Boolean']>;
};

export type MalpracticeInsurance = {
  __typename?: 'MalpracticeInsurance';
  annual_aggregate_amount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  effective_date?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  insurance_address: Scalars['JSON'];
  insurance_name: Scalars['String'];
  insurer_state: State;
  insurer_state_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  per_incident_amount?: Maybe<Scalars['Float']>;
  policy_number?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  retroactive_date?: Maybe<Scalars['String']>;
};

export type MalpracticeInsuranceAggregateFilter = {
  and?: InputMaybe<Array<MalpracticeInsuranceAggregateFilter>>;
  annual_aggregate_amount?: InputMaybe<NumberFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  insurance_address?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<MalpracticeInsuranceAggregateFilter>>;
  per_incident_amount?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type MalpracticeInsuranceConnection = {
  __typename?: 'MalpracticeInsuranceConnection';
  /** Array of edges. */
  edges: Array<MalpracticeInsuranceEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type MalpracticeInsuranceDeleteFilter = {
  and?: InputMaybe<Array<MalpracticeInsuranceDeleteFilter>>;
  annual_aggregate_amount?: InputMaybe<NumberFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  insurance_address?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<MalpracticeInsuranceDeleteFilter>>;
  per_incident_amount?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type MalpracticeInsuranceDeleteResponse = {
  __typename?: 'MalpracticeInsuranceDeleteResponse';
  annual_aggregate_amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  effective_date?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  insurance_address?: Maybe<Scalars['JSON']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurer_state_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  per_incident_amount?: Maybe<Scalars['Float']>;
  policy_number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  retroactive_date?: Maybe<Scalars['String']>;
};

export type MalpracticeInsuranceEdge = {
  __typename?: 'MalpracticeInsuranceEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the MalpracticeInsurance */
  node: MalpracticeInsurance;
};

export type MalpracticeInsuranceFilter = {
  and?: InputMaybe<Array<MalpracticeInsuranceFilter>>;
  annual_aggregate_amount?: InputMaybe<NumberFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  insurance_address?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<MalpracticeInsuranceFilter>>;
  per_incident_amount?: InputMaybe<NumberFieldComparison>;
  provider?: InputMaybe<MalpracticeInsuranceFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type MalpracticeInsuranceFilterProviderFilter = {
  and?: InputMaybe<Array<MalpracticeInsuranceFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MalpracticeInsuranceFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type MalpracticeInsuranceSort = {
  direction: SortDirection;
  field: MalpracticeInsuranceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MalpracticeInsuranceSortFields {
  AnnualAggregateAmount = 'annual_aggregate_amount',
  CreatedAt = 'createdAt',
  Id = 'id',
  InsuranceAddress = 'insurance_address',
  PerIncidentAmount = 'per_incident_amount',
  ProviderId = 'provider_id'
}

export type MalpracticeInsuranceSubscriptionFilter = {
  and?: InputMaybe<Array<MalpracticeInsuranceSubscriptionFilter>>;
  annual_aggregate_amount?: InputMaybe<NumberFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  insurance_address?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<MalpracticeInsuranceSubscriptionFilter>>;
  per_incident_amount?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type MalpracticeInsuranceUpdateFilter = {
  and?: InputMaybe<Array<MalpracticeInsuranceUpdateFilter>>;
  annual_aggregate_amount?: InputMaybe<NumberFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  insurance_address?: InputMaybe<JsonFieldComparison>;
  or?: InputMaybe<Array<MalpracticeInsuranceUpdateFilter>>;
  per_incident_amount?: InputMaybe<NumberFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type MassInviteInput = {
  items: Array<InviteInput>;
};

export type MeDto = {
  __typename?: 'MeDto';
  session?: Maybe<Session>;
  trial?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum MoreNamesType {
  AlternateSupervisor = 'ALTERNATE_SUPERVISOR',
  AutonomousSupervisor = 'AUTONOMOUS_SUPERVISOR',
  Birth = 'BIRTH',
  Credentialing = 'CREDENTIALING',
  FacilityOwner = 'FACILITY_OWNER',
  Father = 'FATHER',
  Fiance = 'FIANCE',
  Legal = 'LEGAL',
  Maiden = 'MAIDEN',
  Married = 'MARRIED',
  Mother = 'MOTHER',
  MotherMaiden = 'MOTHER_MAIDEN',
  NameChange = 'NAME_CHANGE',
  Nickname = 'NICKNAME',
  Other = 'OTHER',
  Preferred = 'PREFERRED',
  ProgramAdmin = 'PROGRAM_ADMIN',
  QueryOnly = 'QUERY_ONLY',
  Recruiter = 'RECRUITER',
  Referral = 'REFERRAL',
  RegionalDirector = 'REGIONAL_DIRECTOR',
  Spouse = 'SPOUSE',
  Supervisor = 'SUPERVISOR'
}

export type MoreNamesTypeFilterComparison = {
  eq?: InputMaybe<MoreNamesType>;
  gt?: InputMaybe<MoreNamesType>;
  gte?: InputMaybe<MoreNamesType>;
  iLike?: InputMaybe<MoreNamesType>;
  in?: InputMaybe<Array<MoreNamesType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<MoreNamesType>;
  lt?: InputMaybe<MoreNamesType>;
  lte?: InputMaybe<MoreNamesType>;
  neq?: InputMaybe<MoreNamesType>;
  notILike?: InputMaybe<MoreNamesType>;
  notIn?: InputMaybe<Array<MoreNamesType>>;
  notLike?: InputMaybe<MoreNamesType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addApply_licensesToCme: Cme;
  addAssigneesToProvider: Provider;
  addAssigneesToProviderGroup: ProviderGroup;
  addCmesToProvider: Provider;
  addExamsToProvider: Provider;
  addHealthcare_payorsToProvider: Provider;
  addImport_logs_failedsToLicense: License;
  addImport_logs_successesToLicense: License;
  addMalpractice_insurancesToProvider: Provider;
  addOrganizationsToProvider: Provider;
  addPeer_referencesToProvider: Provider;
  addProvidersToProviderGroup: ProviderGroup;
  addRolesToOrganization: Organization;
  addRolesToUser: User;
  addShareDocsToProviderShare: ProviderShare;
  addStatusesToLicenseRequest: LicenseRequest;
  addTeamMember: User;
  archiveProvider: Provider;
  cancelProfileShare: Scalars['Boolean'];
  changeDocumentType: Scalars['Boolean'];
  changeEmail: Scalars['Boolean'];
  changeEmailConfirm: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  chooseStep: SuccessResponse;
  closeRequest: SuccessResponse;
  createManyCmeCycles: Array<CmeCycle>;
  createManyCmeGeneralSettings: Array<CmeGeneralSettings>;
  createManyCmeSettings: Array<CmeSettings>;
  createManyCmes: Array<Cme>;
  createManyExams: Array<Exam>;
  createManyHealthcarePayors: Array<HealthcarePayors>;
  createManyLicensePrimarySourceFiles: Array<LicensePrimarySourceFile>;
  createManyLicenseRequestStatuses: Array<LicenseRequestStatus>;
  createManyLicenseRequests: Array<LicenseRequest>;
  createManyLicenseToCmes: Array<LicenseToCme>;
  createManyMalpracticeInsurances: Array<MalpracticeInsurance>;
  createManyOrganizations: Array<Organization>;
  createManyPeerReferences: Array<PeerReferences>;
  createManyProviderGroups: Array<ProviderGroup>;
  createManyProviderShareDocs: Array<ProviderShareDoc>;
  createManyProviderShares: Array<ProviderShare>;
  createManyProviderToGroups: Array<ProviderToGroup>;
  createManyProviderTypes: Array<ProviderType>;
  createManyStates: Array<State>;
  createOneAddress: Address;
  createOneBoardCertification: BoardCertification;
  createOneCme: Cme;
  createOneCmeCycle: CmeCycle;
  createOneCmeGeneralSettings: CmeGeneralSettings;
  createOneCmeSettings: CmeSettings;
  createOneCredentials: Credentials;
  createOneEducation: Education;
  createOneExam: Exam;
  createOneFacilityAffiliation: FacilityAffiliation;
  createOneHealthcarePayors: HealthcarePayors;
  createOneLegalAnswer: LegalAnswer;
  createOneLicense: License;
  createOneLicensePrimarySourceFile: LicensePrimarySourceFile;
  createOneLicenseRequest: LicenseRequest;
  createOneLicenseRequestStatus: LicenseRequestStatus;
  createOneLicenseToCme: LicenseToCme;
  createOneMalpracticeInsurance: MalpracticeInsurance;
  createOneOrganization: Organization;
  createOnePeerReferences: PeerReferences;
  createOnePracticeEmployer: PracticeEmployer;
  createOneProviderBirthInfo: ProviderBirthInfo;
  createOneProviderDemographicInfo: ProviderDemographicInfo;
  createOneProviderGroup: ProviderGroup;
  createOneProviderMoreNames: ProviderMoreNames;
  createOneProviderShare: ProviderShare;
  createOneProviderShareDoc: ProviderShareDoc;
  createOneProviderToGroup: ProviderToGroup;
  createOneProviderType: ProviderType;
  createOneRole: Role;
  createOneState: State;
  createOneWorkHistory: WorkHistory;
  deleteManyCmeCycles: DeleteManyResponse;
  deleteManyCmeGeneralSettings: DeleteManyResponse;
  deleteManyCmeSettings: DeleteManyResponse;
  deleteManyCmes: DeleteManyResponse;
  deleteManyExams: DeleteManyResponse;
  deleteManyHealthcarePayors: DeleteManyResponse;
  deleteManyLicensePrimarySourceFiles: DeleteManyResponse;
  deleteManyLicenseRequestStatuses: DeleteManyResponse;
  deleteManyLicenseRequests: DeleteManyResponse;
  deleteManyLicenseToCmes: DeleteManyResponse;
  deleteManyMalpracticeInsurances: DeleteManyResponse;
  deleteManyOrganizations: DeleteManyResponse;
  deleteManyPeerReferences: DeleteManyResponse;
  deleteManyProviderGroups: DeleteManyResponse;
  deleteManyProviderShareDocs: DeleteManyResponse;
  deleteManyProviderShares: DeleteManyResponse;
  deleteManyProviderToGroups: DeleteManyResponse;
  deleteManyProviderTypes: DeleteManyResponse;
  deleteManyStates: DeleteManyResponse;
  deleteOneAddress: AddressDeleteResponse;
  deleteOneBoardCertification: BoardCertificationDeleteResponse;
  deleteOneCme: CmeDeleteResponse;
  deleteOneCmeCycle: CmeCycleDeleteResponse;
  deleteOneCmeGeneralSettings: CmeGeneralSettingsDeleteResponse;
  deleteOneCmeSettings: CmeSettingsDeleteResponse;
  deleteOneCredentials: CredentialsDeleteResponse;
  deleteOneDocument: DocumentDeleteResponse;
  deleteOneEducation: EducationDeleteResponse;
  deleteOneExam: ExamDeleteResponse;
  deleteOneFacilityAffiliation: FacilityAffiliationDeleteResponse;
  deleteOneHealthcarePayors: HealthcarePayorsDeleteResponse;
  deleteOneLegalAnswer: LegalAnswerDeleteResponse;
  deleteOneLicense: LicenseDeleteResponse;
  deleteOneLicensePrimarySourceFile: LicensePrimarySourceFileDeleteResponse;
  deleteOneLicenseRequest: LicenseRequestDeleteResponse;
  deleteOneLicenseRequestStatus: LicenseRequestStatusDeleteResponse;
  deleteOneLicenseToCme: LicenseToCmeDeleteResponse;
  deleteOneMalpracticeInsurance: MalpracticeInsuranceDeleteResponse;
  deleteOneNpi: NpiDeleteResponse;
  deleteOneOrganization: OrganizationDeleteResponse;
  deleteOnePeerReferences: PeerReferencesDeleteResponse;
  deleteOnePracticeEmployer: PracticeEmployerDeleteResponse;
  deleteOneProvider: ProviderDeleteResponse;
  deleteOneProviderBirthInfo: ProviderBirthInfoDeleteResponse;
  deleteOneProviderDemographicInfo: ProviderDemographicInfoDeleteResponse;
  deleteOneProviderGroup: ProviderGroupDeleteResponse;
  deleteOneProviderMoreNames: ProviderMoreNamesDeleteResponse;
  deleteOneProviderShare: ProviderShareDeleteResponse;
  deleteOneProviderShareDoc: ProviderShareDocDeleteResponse;
  deleteOneProviderToGroup: ProviderToGroupDeleteResponse;
  deleteOneProviderType: ProviderTypeDeleteResponse;
  deleteOneRole: RoleDeleteResponse;
  deleteOneState: StateDeleteResponse;
  deleteOneWorkHistory: WorkHistoryDeleteResponse;
  fetchSingleLicense: SuccessResponse;
  forget: Scalars['Boolean'];
  inviteProvider: Provider;
  login: LoginResponseDto;
  loginAs: LoginResponseDto;
  logout: LogoutResponseDto;
  massInvite: SuccessResponse;
  npiImport: Npi;
  profileShare: Scalars['Boolean'];
  profileSharePreview: Scalars['Boolean'];
  putQueueConfig: SuccessResponse;
  removeApply_licensesFromCme: Cme;
  removeAssigneesFromProvider: Provider;
  removeAssigneesFromProviderGroup: ProviderGroup;
  removeCmeFromLicenseToCme: LicenseToCme;
  removeCme_cycleFromLicenseToCme: LicenseToCme;
  removeCmesFromProvider: Provider;
  removeDocumentFromCme: Cme;
  removeExamsFromProvider: Provider;
  removeHealthcare_payorsFromProvider: Provider;
  removeImport_logs_failedsFromLicense: License;
  removeImport_logs_successesFromLicense: License;
  removeInsurer_stateFromHealthcarePayors: HealthcarePayors;
  removeInsurer_stateFromMalpracticeInsurance: MalpracticeInsurance;
  removeLicenseFromLicensePrimarySourceFile: LicensePrimarySourceFile;
  removeLicenseFromLicenseRequest: LicenseRequest;
  removeLicenseFromLicenseToCme: LicenseToCme;
  removeMalpractice_insurancesFromProvider: Provider;
  removeOrganizationFromLicenseRequest: LicenseRequest;
  removeOrganizationFromRole: Role;
  removeOrganizationFromUser: User;
  removeOrganizationsFromProvider: Provider;
  removePeer_referencesFromProvider: Provider;
  removeProviderFromChangelog: Changelog;
  removeProviderFromCme: Cme;
  removeProviderFromExam: Exam;
  removeProviderFromHealthcarePayors: HealthcarePayors;
  removeProviderFromImportLog: ImportLog;
  removeProviderFromLicensePrimarySourceFile: LicensePrimarySourceFile;
  removeProviderFromMalpracticeInsurance: MalpracticeInsurance;
  removeProviderFromPeerReferences: PeerReferences;
  removeProviderFromProviderToGroup: ProviderToGroup;
  removeProviderShareFromProviderShareDoc: ProviderShareDoc;
  removeProviderTypeFromCmeGeneralSettings: CmeGeneralSettings;
  removeProviderTypeFromCmeSettings: CmeSettings;
  removeProvidersFromOrganization: Organization;
  removeProvidersFromProviderGroup: ProviderGroup;
  removeShareDocsFromProviderShare: ProviderShare;
  removeStateFromCmeSettings: CmeSettings;
  removeStatusesFromLicenseRequest: LicenseRequest;
  removeUserFromChangelog: Changelog;
  removeUserFromLicenseRequest: LicenseRequest;
  requestLicense: SuccessResponse;
  requestUpload: Array<UploadLinksResponse>;
  resendInvite: User;
  reset: Scalars['Boolean'];
  setApply_licensesOnCme: Cme;
  setAssigneesOnProvider: Provider;
  setAssigneesOnProviderGroup: ProviderGroup;
  setCmeOnLicenseToCme: LicenseToCme;
  setCmeSettings: SuccessResponse;
  setCme_cycleOnLicenseToCme: LicenseToCme;
  setCmesOnProvider: Provider;
  setDocumentOnCme: Cme;
  setExamsOnProvider: Provider;
  setHealthcare_payorsOnProvider: Provider;
  setImport_logs_failedsOnLicense: License;
  setImport_logs_successesOnLicense: License;
  setInsurer_stateOnHealthcarePayors: HealthcarePayors;
  setInsurer_stateOnMalpracticeInsurance: MalpracticeInsurance;
  setLicenseOnLicensePrimarySourceFile: LicensePrimarySourceFile;
  setLicenseOnLicenseRequest: LicenseRequest;
  setLicenseOnLicenseToCme: LicenseToCme;
  setMalpractice_insurancesOnProvider: Provider;
  setOrganizationOnLicenseRequest: LicenseRequest;
  setOrganizationOnRole: Role;
  setOrganizationOnUser: User;
  setOrganizationsOnProvider: Provider;
  setPassword: LoginResponseDto;
  setPeer_referencesOnProvider: Provider;
  setProviderOnChangelog: Changelog;
  setProviderOnCme: Cme;
  setProviderOnExam: Exam;
  setProviderOnHealthcarePayors: HealthcarePayors;
  setProviderOnImportLog: ImportLog;
  setProviderOnLicensePrimarySourceFile: LicensePrimarySourceFile;
  setProviderOnMalpracticeInsurance: MalpracticeInsurance;
  setProviderOnPeerReferences: PeerReferences;
  setProviderOnProviderToGroup: ProviderToGroup;
  setProviderShareOnProviderShareDoc: ProviderShareDoc;
  setProviderTypeOnCmeGeneralSettings: CmeGeneralSettings;
  setProviderTypeOnCmeSettings: CmeSettings;
  setProvidersOnOrganization: Organization;
  setProvidersOnProviderGroup: ProviderGroup;
  setRolesOnOrganization: Organization;
  setRolesOnUser: User;
  setShareDocsOnProviderShare: ProviderShare;
  setStateOnCmeSettings: CmeSettings;
  setStatusesOnLicenseRequest: LicenseRequest;
  setUserOnChangelog: Changelog;
  setUserOnLicenseRequest: LicenseRequest;
  sharePdfAuth: LoginResponseDto;
  storeSetFlag: SuccessResponse;
  trialInvite: LoginResponseDto;
  unarchiveProvider: Provider;
  updateManyCmeCycles: UpdateManyResponse;
  updateManyCmeGeneralSettings: UpdateManyResponse;
  updateManyCmeSettings: UpdateManyResponse;
  updateManyCmes: UpdateManyResponse;
  updateManyExams: UpdateManyResponse;
  updateManyHealthcarePayors: UpdateManyResponse;
  updateManyImportLogs: UpdateManyResponse;
  updateManyLicensePrimarySourceFiles: UpdateManyResponse;
  updateManyLicenseRequestStatuses: UpdateManyResponse;
  updateManyLicenseRequests: UpdateManyResponse;
  updateManyLicenseToCmes: UpdateManyResponse;
  updateManyMalpracticeInsurances: UpdateManyResponse;
  updateManyOrganizations: UpdateManyResponse;
  updateManyPeerReferences: UpdateManyResponse;
  updateManyProviderGroups: UpdateManyResponse;
  updateManyProviderShareDocs: UpdateManyResponse;
  updateManyProviderShares: UpdateManyResponse;
  updateManyProviderToGroups: UpdateManyResponse;
  updateManyProviderTypes: UpdateManyResponse;
  updateManyStates: UpdateManyResponse;
  updateOneAddress: Address;
  updateOneBoardCertification: BoardCertification;
  updateOneCme: Cme;
  updateOneCmeCycle: CmeCycle;
  updateOneCmeGeneralSettings: CmeGeneralSettings;
  updateOneCmeSettings: CmeSettings;
  updateOneCredentials: Credentials;
  updateOneDocument: Document;
  updateOneEducation: Education;
  updateOneExam: Exam;
  updateOneFacilityAffiliation: FacilityAffiliation;
  updateOneHealthcarePayors: HealthcarePayors;
  updateOneImportLog: ImportLog;
  updateOneLegalAnswer: LegalAnswer;
  updateOneLicense: License;
  updateOneLicensePrimarySourceFile: LicensePrimarySourceFile;
  updateOneLicenseRequest: LicenseRequest;
  updateOneLicenseRequestStatus: LicenseRequestStatus;
  updateOneLicenseToCme: LicenseToCme;
  updateOneMalpracticeInsurance: MalpracticeInsurance;
  updateOneOrganization: Organization;
  updateOnePeerReferences: PeerReferences;
  updateOnePracticeEmployer: PracticeEmployer;
  updateOneProvider: Provider;
  updateOneProviderBirthInfo: ProviderBirthInfo;
  updateOneProviderDemographicInfo: ProviderDemographicInfo;
  updateOneProviderGroup: ProviderGroup;
  updateOneProviderMoreNames: ProviderMoreNames;
  updateOneProviderShare: ProviderShare;
  updateOneProviderShareDoc: ProviderShareDoc;
  updateOneProviderToGroup: ProviderToGroup;
  updateOneProviderType: ProviderType;
  updateOneRole: Role;
  updateOneState: State;
  updateOneUser: User;
  updateOneWorkHistory: WorkHistory;
  updateProfileShare: Scalars['Boolean'];
  verifyUpload: Document;
  viewAsBack: LoginResponseDto;
};


export type MutationAddApply_LicensesToCmeArgs = {
  input: AddApply_LicensesToCmeInput;
};


export type MutationAddAssigneesToProviderArgs = {
  input: AddAssigneesToProviderInput;
};


export type MutationAddAssigneesToProviderGroupArgs = {
  input: AddAssigneesToProviderGroupInput;
};


export type MutationAddCmesToProviderArgs = {
  input: AddCmesToProviderInput;
};


export type MutationAddExamsToProviderArgs = {
  input: AddExamsToProviderInput;
};


export type MutationAddHealthcare_PayorsToProviderArgs = {
  input: AddHealthcare_PayorsToProviderInput;
};


export type MutationAddImport_Logs_FailedsToLicenseArgs = {
  input: AddImport_Logs_FailedsToLicenseInput;
};


export type MutationAddImport_Logs_SuccessesToLicenseArgs = {
  input: AddImport_Logs_SuccessesToLicenseInput;
};


export type MutationAddMalpractice_InsurancesToProviderArgs = {
  input: AddMalpractice_InsurancesToProviderInput;
};


export type MutationAddOrganizationsToProviderArgs = {
  input: AddOrganizationsToProviderInput;
};


export type MutationAddPeer_ReferencesToProviderArgs = {
  input: AddPeer_ReferencesToProviderInput;
};


export type MutationAddProvidersToProviderGroupArgs = {
  input: AddProvidersToProviderGroupInput;
};


export type MutationAddRolesToOrganizationArgs = {
  input: AddRolesToOrganizationInput;
};


export type MutationAddRolesToUserArgs = {
  input: AddRolesToUserInput;
};


export type MutationAddShareDocsToProviderShareArgs = {
  input: AddShareDocsToProviderShareInput;
};


export type MutationAddStatusesToLicenseRequestArgs = {
  input: AddStatusesToLicenseRequestInput;
};


export type MutationAddTeamMemberArgs = {
  input: AddTeamMemberInput;
};


export type MutationArchiveProviderArgs = {
  input: Scalars['Float'];
};


export type MutationCancelProfileShareArgs = {
  input: ProviderShareCancelDto;
};


export type MutationChangeDocumentTypeArgs = {
  id: Scalars['Float'];
  type: Scalars['String'];
};


export type MutationChangeEmailArgs = {
  input: ChangeEmail;
};


export type MutationChangeEmailConfirmArgs = {
  input: ChangeEmailConfirm;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChooseStepArgs = {
  input: ChooseStepRequestLicense;
};


export type MutationCloseRequestArgs = {
  input: CloseRequestLicense;
};


export type MutationCreateManyCmeCyclesArgs = {
  input: CreateManyCmeCyclesInput;
};


export type MutationCreateManyCmeGeneralSettingsArgs = {
  input: CreateManyCmeGeneralSettingsInput;
};


export type MutationCreateManyCmeSettingsArgs = {
  input: CreateManyCmeSettingsInput;
};


export type MutationCreateManyCmesArgs = {
  input: CreateManyCmesInput;
};


export type MutationCreateManyExamsArgs = {
  input: CreateManyExamsInput;
};


export type MutationCreateManyHealthcarePayorsArgs = {
  input: CreateManyHealthcarePayorsInput;
};


export type MutationCreateManyLicensePrimarySourceFilesArgs = {
  input: CreateManyLicensePrimarySourceFilesInput;
};


export type MutationCreateManyLicenseRequestStatusesArgs = {
  input: CreateManyLicenseRequestStatusesInput;
};


export type MutationCreateManyLicenseRequestsArgs = {
  input: CreateManyLicenseRequestsInput;
};


export type MutationCreateManyLicenseToCmesArgs = {
  input: CreateManyLicenseToCmesInput;
};


export type MutationCreateManyMalpracticeInsurancesArgs = {
  input: CreateManyMalpracticeInsurancesInput;
};


export type MutationCreateManyOrganizationsArgs = {
  input: CreateManyOrganizationsInput;
};


export type MutationCreateManyPeerReferencesArgs = {
  input: CreateManyPeerReferencesInput;
};


export type MutationCreateManyProviderGroupsArgs = {
  input: CreateManyProviderGroupsInput;
};


export type MutationCreateManyProviderShareDocsArgs = {
  input: CreateManyProviderShareDocsInput;
};


export type MutationCreateManyProviderSharesArgs = {
  input: CreateManyProviderSharesInput;
};


export type MutationCreateManyProviderToGroupsArgs = {
  input: CreateManyProviderToGroupsInput;
};


export type MutationCreateManyProviderTypesArgs = {
  input: CreateManyProviderTypesInput;
};


export type MutationCreateManyStatesArgs = {
  input: CreateManyStatesInput;
};


export type MutationCreateOneAddressArgs = {
  input: CreateOneAddressInput;
};


export type MutationCreateOneBoardCertificationArgs = {
  input: CreateOneBoardCertificationInput;
};


export type MutationCreateOneCmeArgs = {
  input: CreateOneCmeInput;
};


export type MutationCreateOneCmeCycleArgs = {
  input: CreateOneCmeCycleInput;
};


export type MutationCreateOneCmeGeneralSettingsArgs = {
  input: CreateOneCmeGeneralSettingsInput;
};


export type MutationCreateOneCmeSettingsArgs = {
  input: CreateOneCmeSettingsInput;
};


export type MutationCreateOneCredentialsArgs = {
  input: CreateOneCredentialsInput;
};


export type MutationCreateOneEducationArgs = {
  input: CreateOneEducationInput;
};


export type MutationCreateOneExamArgs = {
  input: CreateOneExamInput;
};


export type MutationCreateOneFacilityAffiliationArgs = {
  input: CreateOneFacilityAffiliationInput;
};


export type MutationCreateOneHealthcarePayorsArgs = {
  input: CreateOneHealthcarePayorsInput;
};


export type MutationCreateOneLegalAnswerArgs = {
  input: CreateOneLegalAnswerInput;
};


export type MutationCreateOneLicenseArgs = {
  input: CreateOneLicenseInput;
};


export type MutationCreateOneLicensePrimarySourceFileArgs = {
  input: CreateOneLicensePrimarySourceFileInput;
};


export type MutationCreateOneLicenseRequestArgs = {
  input: CreateOneLicenseRequestInput;
};


export type MutationCreateOneLicenseRequestStatusArgs = {
  input: CreateOneLicenseRequestStatusInput;
};


export type MutationCreateOneLicenseToCmeArgs = {
  input: CreateOneLicenseToCmeInput;
};


export type MutationCreateOneMalpracticeInsuranceArgs = {
  input: CreateOneMalpracticeInsuranceInput;
};


export type MutationCreateOneOrganizationArgs = {
  input: CreateOneOrganizationInput;
};


export type MutationCreateOnePeerReferencesArgs = {
  input: CreateOnePeerReferencesInput;
};


export type MutationCreateOnePracticeEmployerArgs = {
  input: CreateOnePracticeEmployerInput;
};


export type MutationCreateOneProviderBirthInfoArgs = {
  input: CreateOneProviderBirthInfoInput;
};


export type MutationCreateOneProviderDemographicInfoArgs = {
  input: CreateOneProviderDemographicInfoInput;
};


export type MutationCreateOneProviderGroupArgs = {
  input: CreateOneProviderGroupInput;
};


export type MutationCreateOneProviderMoreNamesArgs = {
  input: CreateOneProviderMoreNamesInput;
};


export type MutationCreateOneProviderShareArgs = {
  input: CreateOneProviderShareInput;
};


export type MutationCreateOneProviderShareDocArgs = {
  input: CreateOneProviderShareDocInput;
};


export type MutationCreateOneProviderToGroupArgs = {
  input: CreateOneProviderToGroupInput;
};


export type MutationCreateOneProviderTypeArgs = {
  input: CreateOneProviderTypeInput;
};


export type MutationCreateOneRoleArgs = {
  input: CreateOneRoleInput;
};


export type MutationCreateOneStateArgs = {
  input: CreateOneStateInput;
};


export type MutationCreateOneWorkHistoryArgs = {
  input: CreateOneWorkHistoryInput;
};


export type MutationDeleteManyCmeCyclesArgs = {
  input: DeleteManyCmeCyclesInput;
};


export type MutationDeleteManyCmeGeneralSettingsArgs = {
  input: DeleteManyCmeGeneralSettingsInput;
};


export type MutationDeleteManyCmeSettingsArgs = {
  input: DeleteManyCmeSettingsInput;
};


export type MutationDeleteManyCmesArgs = {
  input: DeleteManyCmesInput;
};


export type MutationDeleteManyExamsArgs = {
  input: DeleteManyExamsInput;
};


export type MutationDeleteManyHealthcarePayorsArgs = {
  input: DeleteManyHealthcarePayorsInput;
};


export type MutationDeleteManyLicensePrimarySourceFilesArgs = {
  input: DeleteManyLicensePrimarySourceFilesInput;
};


export type MutationDeleteManyLicenseRequestStatusesArgs = {
  input: DeleteManyLicenseRequestStatusesInput;
};


export type MutationDeleteManyLicenseRequestsArgs = {
  input: DeleteManyLicenseRequestsInput;
};


export type MutationDeleteManyLicenseToCmesArgs = {
  input: DeleteManyLicenseToCmesInput;
};


export type MutationDeleteManyMalpracticeInsurancesArgs = {
  input: DeleteManyMalpracticeInsurancesInput;
};


export type MutationDeleteManyOrganizationsArgs = {
  input: DeleteManyOrganizationsInput;
};


export type MutationDeleteManyPeerReferencesArgs = {
  input: DeleteManyPeerReferencesInput;
};


export type MutationDeleteManyProviderGroupsArgs = {
  input: DeleteManyProviderGroupsInput;
};


export type MutationDeleteManyProviderShareDocsArgs = {
  input: DeleteManyProviderShareDocsInput;
};


export type MutationDeleteManyProviderSharesArgs = {
  input: DeleteManyProviderSharesInput;
};


export type MutationDeleteManyProviderToGroupsArgs = {
  input: DeleteManyProviderToGroupsInput;
};


export type MutationDeleteManyProviderTypesArgs = {
  input: DeleteManyProviderTypesInput;
};


export type MutationDeleteManyStatesArgs = {
  input: DeleteManyStatesInput;
};


export type MutationDeleteOneAddressArgs = {
  input: DeleteOneAddressInput;
};


export type MutationDeleteOneBoardCertificationArgs = {
  input: DeleteOneBoardCertificationInput;
};


export type MutationDeleteOneCmeArgs = {
  input: DeleteOneCmeInput;
};


export type MutationDeleteOneCmeCycleArgs = {
  input: DeleteOneCmeCycleInput;
};


export type MutationDeleteOneCmeGeneralSettingsArgs = {
  input: DeleteOneCmeGeneralSettingsInput;
};


export type MutationDeleteOneCmeSettingsArgs = {
  input: DeleteOneCmeSettingsInput;
};


export type MutationDeleteOneCredentialsArgs = {
  input: DeleteOneCredentialsInput;
};


export type MutationDeleteOneDocumentArgs = {
  input: DeleteOneDocumentInput;
};


export type MutationDeleteOneEducationArgs = {
  input: DeleteOneEducationInput;
};


export type MutationDeleteOneExamArgs = {
  input: DeleteOneExamInput;
};


export type MutationDeleteOneFacilityAffiliationArgs = {
  input: DeleteOneFacilityAffiliationInput;
};


export type MutationDeleteOneHealthcarePayorsArgs = {
  input: DeleteOneHealthcarePayorsInput;
};


export type MutationDeleteOneLegalAnswerArgs = {
  input: DeleteOneLegalAnswerInput;
};


export type MutationDeleteOneLicenseArgs = {
  input: DeleteOneLicenseInput;
};


export type MutationDeleteOneLicensePrimarySourceFileArgs = {
  input: DeleteOneLicensePrimarySourceFileInput;
};


export type MutationDeleteOneLicenseRequestArgs = {
  input: DeleteOneLicenseRequestInput;
};


export type MutationDeleteOneLicenseRequestStatusArgs = {
  input: DeleteOneLicenseRequestStatusInput;
};


export type MutationDeleteOneLicenseToCmeArgs = {
  input: DeleteOneLicenseToCmeInput;
};


export type MutationDeleteOneMalpracticeInsuranceArgs = {
  input: DeleteOneMalpracticeInsuranceInput;
};


export type MutationDeleteOneNpiArgs = {
  input: DeleteOneNpiInput;
};


export type MutationDeleteOneOrganizationArgs = {
  input: DeleteOneOrganizationInput;
};


export type MutationDeleteOnePeerReferencesArgs = {
  input: DeleteOnePeerReferencesInput;
};


export type MutationDeleteOnePracticeEmployerArgs = {
  input: DeleteOnePracticeEmployerInput;
};


export type MutationDeleteOneProviderArgs = {
  input: DeleteOneProviderInput;
};


export type MutationDeleteOneProviderBirthInfoArgs = {
  input: DeleteOneProviderBirthInfoInput;
};


export type MutationDeleteOneProviderDemographicInfoArgs = {
  input: DeleteOneProviderDemographicInfoInput;
};


export type MutationDeleteOneProviderGroupArgs = {
  input: DeleteOneProviderGroupInput;
};


export type MutationDeleteOneProviderMoreNamesArgs = {
  input: DeleteOneProviderMoreNamesInput;
};


export type MutationDeleteOneProviderShareArgs = {
  input: DeleteOneProviderShareInput;
};


export type MutationDeleteOneProviderShareDocArgs = {
  input: DeleteOneProviderShareDocInput;
};


export type MutationDeleteOneProviderToGroupArgs = {
  input: DeleteOneProviderToGroupInput;
};


export type MutationDeleteOneProviderTypeArgs = {
  input: DeleteOneProviderTypeInput;
};


export type MutationDeleteOneRoleArgs = {
  input: DeleteOneRoleInput;
};


export type MutationDeleteOneStateArgs = {
  input: DeleteOneStateInput;
};


export type MutationDeleteOneWorkHistoryArgs = {
  input: DeleteOneWorkHistoryInput;
};


export type MutationFetchSingleLicenseArgs = {
  input: FetchSingleLicenseInput;
};


export type MutationForgetArgs = {
  input: ForgetInput;
};


export type MutationInviteProviderArgs = {
  input: InviteProviderInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginAsArgs = {
  input: LoginAsInput;
};


export type MutationMassInviteArgs = {
  input: MassInviteInput;
};


export type MutationNpiImportArgs = {
  input: NpiImportInput;
};


export type MutationProfileShareArgs = {
  input: ProviderShareDto;
};


export type MutationProfileSharePreviewArgs = {
  input: ProviderSharePreviewInputDto;
};


export type MutationPutQueueConfigArgs = {
  input: QueueConfigInput;
};


export type MutationRemoveApply_LicensesFromCmeArgs = {
  input: RemoveApply_LicensesFromCmeInput;
};


export type MutationRemoveAssigneesFromProviderArgs = {
  input: RemoveAssigneesFromProviderInput;
};


export type MutationRemoveAssigneesFromProviderGroupArgs = {
  input: RemoveAssigneesFromProviderGroupInput;
};


export type MutationRemoveCmeFromLicenseToCmeArgs = {
  input: RemoveCmeFromLicenseToCmeInput;
};


export type MutationRemoveCme_CycleFromLicenseToCmeArgs = {
  input: RemoveCme_CycleFromLicenseToCmeInput;
};


export type MutationRemoveCmesFromProviderArgs = {
  input: RemoveCmesFromProviderInput;
};


export type MutationRemoveDocumentFromCmeArgs = {
  input: RemoveDocumentFromCmeInput;
};


export type MutationRemoveExamsFromProviderArgs = {
  input: RemoveExamsFromProviderInput;
};


export type MutationRemoveHealthcare_PayorsFromProviderArgs = {
  input: RemoveHealthcare_PayorsFromProviderInput;
};


export type MutationRemoveImport_Logs_FailedsFromLicenseArgs = {
  input: RemoveImport_Logs_FailedsFromLicenseInput;
};


export type MutationRemoveImport_Logs_SuccessesFromLicenseArgs = {
  input: RemoveImport_Logs_SuccessesFromLicenseInput;
};


export type MutationRemoveInsurer_StateFromHealthcarePayorsArgs = {
  input: RemoveInsurer_StateFromHealthcarePayorsInput;
};


export type MutationRemoveInsurer_StateFromMalpracticeInsuranceArgs = {
  input: RemoveInsurer_StateFromMalpracticeInsuranceInput;
};


export type MutationRemoveLicenseFromLicensePrimarySourceFileArgs = {
  input: RemoveLicenseFromLicensePrimarySourceFileInput;
};


export type MutationRemoveLicenseFromLicenseRequestArgs = {
  input: RemoveLicenseFromLicenseRequestInput;
};


export type MutationRemoveLicenseFromLicenseToCmeArgs = {
  input: RemoveLicenseFromLicenseToCmeInput;
};


export type MutationRemoveMalpractice_InsurancesFromProviderArgs = {
  input: RemoveMalpractice_InsurancesFromProviderInput;
};


export type MutationRemoveOrganizationFromLicenseRequestArgs = {
  input: RemoveOrganizationFromLicenseRequestInput;
};


export type MutationRemoveOrganizationFromRoleArgs = {
  input: RemoveOrganizationFromRoleInput;
};


export type MutationRemoveOrganizationFromUserArgs = {
  input: RemoveOrganizationFromUserInput;
};


export type MutationRemoveOrganizationsFromProviderArgs = {
  input: RemoveOrganizationsFromProviderInput;
};


export type MutationRemovePeer_ReferencesFromProviderArgs = {
  input: RemovePeer_ReferencesFromProviderInput;
};


export type MutationRemoveProviderFromChangelogArgs = {
  input: RemoveProviderFromChangelogInput;
};


export type MutationRemoveProviderFromCmeArgs = {
  input: RemoveProviderFromCmeInput;
};


export type MutationRemoveProviderFromExamArgs = {
  input: RemoveProviderFromExamInput;
};


export type MutationRemoveProviderFromHealthcarePayorsArgs = {
  input: RemoveProviderFromHealthcarePayorsInput;
};


export type MutationRemoveProviderFromImportLogArgs = {
  input: RemoveProviderFromImportLogInput;
};


export type MutationRemoveProviderFromLicensePrimarySourceFileArgs = {
  input: RemoveProviderFromLicensePrimarySourceFileInput;
};


export type MutationRemoveProviderFromMalpracticeInsuranceArgs = {
  input: RemoveProviderFromMalpracticeInsuranceInput;
};


export type MutationRemoveProviderFromPeerReferencesArgs = {
  input: RemoveProviderFromPeerReferencesInput;
};


export type MutationRemoveProviderFromProviderToGroupArgs = {
  input: RemoveProviderFromProviderToGroupInput;
};


export type MutationRemoveProviderShareFromProviderShareDocArgs = {
  input: RemoveProviderShareFromProviderShareDocInput;
};


export type MutationRemoveProviderTypeFromCmeGeneralSettingsArgs = {
  input: RemoveProviderTypeFromCmeGeneralSettingsInput;
};


export type MutationRemoveProviderTypeFromCmeSettingsArgs = {
  input: RemoveProviderTypeFromCmeSettingsInput;
};


export type MutationRemoveProvidersFromOrganizationArgs = {
  input: RemoveProvidersFromOrganizationInput;
};


export type MutationRemoveProvidersFromProviderGroupArgs = {
  input: RemoveProvidersFromProviderGroupInput;
};


export type MutationRemoveShareDocsFromProviderShareArgs = {
  input: RemoveShareDocsFromProviderShareInput;
};


export type MutationRemoveStateFromCmeSettingsArgs = {
  input: RemoveStateFromCmeSettingsInput;
};


export type MutationRemoveStatusesFromLicenseRequestArgs = {
  input: RemoveStatusesFromLicenseRequestInput;
};


export type MutationRemoveUserFromChangelogArgs = {
  input: RemoveUserFromChangelogInput;
};


export type MutationRemoveUserFromLicenseRequestArgs = {
  input: RemoveUserFromLicenseRequestInput;
};


export type MutationRequestLicenseArgs = {
  input: RequestLicense;
};


export type MutationRequestUploadArgs = {
  input: UploadLinkRequestInput;
};


export type MutationResendInviteArgs = {
  input: InviteInput;
};


export type MutationResetArgs = {
  input: ForgetConfirmInput;
};


export type MutationSetApply_LicensesOnCmeArgs = {
  input: SetApply_LicensesOnCmeInput;
};


export type MutationSetAssigneesOnProviderArgs = {
  input: SetAssigneesOnProviderInput;
};


export type MutationSetAssigneesOnProviderGroupArgs = {
  input: SetAssigneesOnProviderGroupInput;
};


export type MutationSetCmeOnLicenseToCmeArgs = {
  input: SetCmeOnLicenseToCmeInput;
};


export type MutationSetCmeSettingsArgs = {
  input: CmeSettingsInput;
};


export type MutationSetCme_CycleOnLicenseToCmeArgs = {
  input: SetCme_CycleOnLicenseToCmeInput;
};


export type MutationSetCmesOnProviderArgs = {
  input: SetCmesOnProviderInput;
};


export type MutationSetDocumentOnCmeArgs = {
  input: SetDocumentOnCmeInput;
};


export type MutationSetExamsOnProviderArgs = {
  input: SetExamsOnProviderInput;
};


export type MutationSetHealthcare_PayorsOnProviderArgs = {
  input: SetHealthcare_PayorsOnProviderInput;
};


export type MutationSetImport_Logs_FailedsOnLicenseArgs = {
  input: SetImport_Logs_FailedsOnLicenseInput;
};


export type MutationSetImport_Logs_SuccessesOnLicenseArgs = {
  input: SetImport_Logs_SuccessesOnLicenseInput;
};


export type MutationSetInsurer_StateOnHealthcarePayorsArgs = {
  input: SetInsurer_StateOnHealthcarePayorsInput;
};


export type MutationSetInsurer_StateOnMalpracticeInsuranceArgs = {
  input: SetInsurer_StateOnMalpracticeInsuranceInput;
};


export type MutationSetLicenseOnLicensePrimarySourceFileArgs = {
  input: SetLicenseOnLicensePrimarySourceFileInput;
};


export type MutationSetLicenseOnLicenseRequestArgs = {
  input: SetLicenseOnLicenseRequestInput;
};


export type MutationSetLicenseOnLicenseToCmeArgs = {
  input: SetLicenseOnLicenseToCmeInput;
};


export type MutationSetMalpractice_InsurancesOnProviderArgs = {
  input: SetMalpractice_InsurancesOnProviderInput;
};


export type MutationSetOrganizationOnLicenseRequestArgs = {
  input: SetOrganizationOnLicenseRequestInput;
};


export type MutationSetOrganizationOnRoleArgs = {
  input: SetOrganizationOnRoleInput;
};


export type MutationSetOrganizationOnUserArgs = {
  input: SetOrganizationOnUserInput;
};


export type MutationSetOrganizationsOnProviderArgs = {
  input: SetOrganizationsOnProviderInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};


export type MutationSetPeer_ReferencesOnProviderArgs = {
  input: SetPeer_ReferencesOnProviderInput;
};


export type MutationSetProviderOnChangelogArgs = {
  input: SetProviderOnChangelogInput;
};


export type MutationSetProviderOnCmeArgs = {
  input: SetProviderOnCmeInput;
};


export type MutationSetProviderOnExamArgs = {
  input: SetProviderOnExamInput;
};


export type MutationSetProviderOnHealthcarePayorsArgs = {
  input: SetProviderOnHealthcarePayorsInput;
};


export type MutationSetProviderOnImportLogArgs = {
  input: SetProviderOnImportLogInput;
};


export type MutationSetProviderOnLicensePrimarySourceFileArgs = {
  input: SetProviderOnLicensePrimarySourceFileInput;
};


export type MutationSetProviderOnMalpracticeInsuranceArgs = {
  input: SetProviderOnMalpracticeInsuranceInput;
};


export type MutationSetProviderOnPeerReferencesArgs = {
  input: SetProviderOnPeerReferencesInput;
};


export type MutationSetProviderOnProviderToGroupArgs = {
  input: SetProviderOnProviderToGroupInput;
};


export type MutationSetProviderShareOnProviderShareDocArgs = {
  input: SetProviderShareOnProviderShareDocInput;
};


export type MutationSetProviderTypeOnCmeGeneralSettingsArgs = {
  input: SetProviderTypeOnCmeGeneralSettingsInput;
};


export type MutationSetProviderTypeOnCmeSettingsArgs = {
  input: SetProviderTypeOnCmeSettingsInput;
};


export type MutationSetProvidersOnOrganizationArgs = {
  input: SetProvidersOnOrganizationInput;
};


export type MutationSetProvidersOnProviderGroupArgs = {
  input: SetProvidersOnProviderGroupInput;
};


export type MutationSetRolesOnOrganizationArgs = {
  input: SetRolesOnOrganizationInput;
};


export type MutationSetRolesOnUserArgs = {
  input: SetRolesOnUserInput;
};


export type MutationSetShareDocsOnProviderShareArgs = {
  input: SetShareDocsOnProviderShareInput;
};


export type MutationSetStateOnCmeSettingsArgs = {
  input: SetStateOnCmeSettingsInput;
};


export type MutationSetStatusesOnLicenseRequestArgs = {
  input: SetStatusesOnLicenseRequestInput;
};


export type MutationSetUserOnChangelogArgs = {
  input: SetUserOnChangelogInput;
};


export type MutationSetUserOnLicenseRequestArgs = {
  input: SetUserOnLicenseRequestInput;
};


export type MutationSharePdfAuthArgs = {
  input: SharePdfAuthDto;
};


export type MutationStoreSetFlagArgs = {
  input: StoreSetFlagInput;
};


export type MutationTrialInviteArgs = {
  input: TrialInviteProvider;
};


export type MutationUnarchiveProviderArgs = {
  input: Scalars['Float'];
};


export type MutationUpdateManyCmeCyclesArgs = {
  input: UpdateManyCmeCyclesInput;
};


export type MutationUpdateManyCmeGeneralSettingsArgs = {
  input: UpdateManyCmeGeneralSettingsInput;
};


export type MutationUpdateManyCmeSettingsArgs = {
  input: UpdateManyCmeSettingsInput;
};


export type MutationUpdateManyCmesArgs = {
  input: UpdateManyCmesInput;
};


export type MutationUpdateManyExamsArgs = {
  input: UpdateManyExamsInput;
};


export type MutationUpdateManyHealthcarePayorsArgs = {
  input: UpdateManyHealthcarePayorsInput;
};


export type MutationUpdateManyImportLogsArgs = {
  input: UpdateManyImportLogsInput;
};


export type MutationUpdateManyLicensePrimarySourceFilesArgs = {
  input: UpdateManyLicensePrimarySourceFilesInput;
};


export type MutationUpdateManyLicenseRequestStatusesArgs = {
  input: UpdateManyLicenseRequestStatusesInput;
};


export type MutationUpdateManyLicenseRequestsArgs = {
  input: UpdateManyLicenseRequestsInput;
};


export type MutationUpdateManyLicenseToCmesArgs = {
  input: UpdateManyLicenseToCmesInput;
};


export type MutationUpdateManyMalpracticeInsurancesArgs = {
  input: UpdateManyMalpracticeInsurancesInput;
};


export type MutationUpdateManyOrganizationsArgs = {
  input: UpdateManyOrganizationsInput;
};


export type MutationUpdateManyPeerReferencesArgs = {
  input: UpdateManyPeerReferencesInput;
};


export type MutationUpdateManyProviderGroupsArgs = {
  input: UpdateManyProviderGroupsInput;
};


export type MutationUpdateManyProviderShareDocsArgs = {
  input: UpdateManyProviderShareDocsInput;
};


export type MutationUpdateManyProviderSharesArgs = {
  input: UpdateManyProviderSharesInput;
};


export type MutationUpdateManyProviderToGroupsArgs = {
  input: UpdateManyProviderToGroupsInput;
};


export type MutationUpdateManyProviderTypesArgs = {
  input: UpdateManyProviderTypesInput;
};


export type MutationUpdateManyStatesArgs = {
  input: UpdateManyStatesInput;
};


export type MutationUpdateOneAddressArgs = {
  input: UpdateOneAddressInput;
};


export type MutationUpdateOneBoardCertificationArgs = {
  input: UpdateOneBoardCertificationInput;
};


export type MutationUpdateOneCmeArgs = {
  input: UpdateOneCmeInput;
};


export type MutationUpdateOneCmeCycleArgs = {
  input: UpdateOneCmeCycleInput;
};


export type MutationUpdateOneCmeGeneralSettingsArgs = {
  input: UpdateOneCmeGeneralSettingsInput;
};


export type MutationUpdateOneCmeSettingsArgs = {
  input: UpdateOneCmeSettingsInput;
};


export type MutationUpdateOneCredentialsArgs = {
  input: UpdateOneCredentialsInput;
};


export type MutationUpdateOneDocumentArgs = {
  input: UpdateOneDocumentInput;
};


export type MutationUpdateOneEducationArgs = {
  input: UpdateOneEducationInput;
};


export type MutationUpdateOneExamArgs = {
  input: UpdateOneExamInput;
};


export type MutationUpdateOneFacilityAffiliationArgs = {
  input: UpdateOneFacilityAffiliationInput;
};


export type MutationUpdateOneHealthcarePayorsArgs = {
  input: UpdateOneHealthcarePayorsInput;
};


export type MutationUpdateOneImportLogArgs = {
  input: UpdateOneImportLogInput;
};


export type MutationUpdateOneLegalAnswerArgs = {
  input: UpdateOneLegalAnswerInput;
};


export type MutationUpdateOneLicenseArgs = {
  input: UpdateOneLicenseInput;
};


export type MutationUpdateOneLicensePrimarySourceFileArgs = {
  input: UpdateOneLicensePrimarySourceFileInput;
};


export type MutationUpdateOneLicenseRequestArgs = {
  input: UpdateOneLicenseRequestInput;
};


export type MutationUpdateOneLicenseRequestStatusArgs = {
  input: UpdateOneLicenseRequestStatusInput;
};


export type MutationUpdateOneLicenseToCmeArgs = {
  input: UpdateOneLicenseToCmeInput;
};


export type MutationUpdateOneMalpracticeInsuranceArgs = {
  input: UpdateOneMalpracticeInsuranceInput;
};


export type MutationUpdateOneOrganizationArgs = {
  input: UpdateOneOrganizationInput;
};


export type MutationUpdateOnePeerReferencesArgs = {
  input: UpdateOnePeerReferencesInput;
};


export type MutationUpdateOnePracticeEmployerArgs = {
  input: UpdateOnePracticeEmployerInput;
};


export type MutationUpdateOneProviderArgs = {
  input: UpdateOneProviderInput;
};


export type MutationUpdateOneProviderBirthInfoArgs = {
  input: UpdateOneProviderBirthInfoInput;
};


export type MutationUpdateOneProviderDemographicInfoArgs = {
  input: UpdateOneProviderDemographicInfoInput;
};


export type MutationUpdateOneProviderGroupArgs = {
  input: UpdateOneProviderGroupInput;
};


export type MutationUpdateOneProviderMoreNamesArgs = {
  input: UpdateOneProviderMoreNamesInput;
};


export type MutationUpdateOneProviderShareArgs = {
  input: UpdateOneProviderShareInput;
};


export type MutationUpdateOneProviderShareDocArgs = {
  input: UpdateOneProviderShareDocInput;
};


export type MutationUpdateOneProviderToGroupArgs = {
  input: UpdateOneProviderToGroupInput;
};


export type MutationUpdateOneProviderTypeArgs = {
  input: UpdateOneProviderTypeInput;
};


export type MutationUpdateOneRoleArgs = {
  input: UpdateOneRoleInput;
};


export type MutationUpdateOneStateArgs = {
  input: UpdateOneStateInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationUpdateOneWorkHistoryArgs = {
  input: UpdateOneWorkHistoryInput;
};


export type MutationUpdateProfileShareArgs = {
  input: ProviderShareDto;
};


export type MutationVerifyUploadArgs = {
  id: Scalars['Float'];
};

export type NcsbnIdCheckDto = {
  __typename?: 'NcsbnIdCheckDTO';
  error?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  ncsbn_id: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
  user_id: Scalars['Float'];
};

export type NcsbnIdInput = {
  ncsbn_id: Scalars['String'];
  provider_id: Scalars['Float'];
};

export type Npi = {
  __typename?: 'Npi';
  createdAt: Scalars['Date'];
  credential?: Maybe<Scalars['String']>;
  last_checked_at?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  number: Scalars['String'];
  provider: Provider;
  provider_id: Scalars['Float'];
  status: NpiStatus;
  taxonomy?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

export type NpiDeleteResponse = {
  __typename?: 'NpiDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  credential?: Maybe<Scalars['String']>;
  last_checked_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  status?: Maybe<NpiStatus>;
  taxonomy?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type NpiEdge = {
  __typename?: 'NpiEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Npi */
  node: Npi;
};

export type NpiFetchInput = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  number: Scalars['String'];
  provider_id?: InputMaybe<Scalars['Float']>;
};

export type NpiImportInput = {
  number: Scalars['String'];
  provider_id: Scalars['Float'];
};

export type NpiRefreshResponse = {
  __typename?: 'NpiRefreshResponse';
  message: Scalars['String'];
};

export type NpiResponse = {
  __typename?: 'NpiResponse';
  result: Scalars['JSON'];
};

export enum NpiStatus {
  A = 'A',
  D = 'D'
}

export type NpiSubscriptionFilter = {
  and?: InputMaybe<Array<NpiSubscriptionFilter>>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<NpiSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type NpiSummaryResponse = {
  __typename?: 'NpiSummaryResponse';
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  type?: Maybe<ProviderType>;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']>;
};

export type Organization = {
  __typename?: 'Organization';
  address: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  is_root: Scalars['Boolean'];
  licenses_count?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  providers: Provider;
  providers_count?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Role>>;
  rolesAggregate: Array<OrganizationRolesAggregateResponse>;
  tax_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type OrganizationRolesArgs = {
  filter?: RoleFilter;
  sorting?: Array<RoleSort>;
};


export type OrganizationRolesAggregateArgs = {
  filter?: InputMaybe<RoleAggregateFilter>;
};

export type OrganizationAggregateFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<OrganizationAggregateFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  is_root?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationAggregateFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type OrganizationAggregateGroupBy = {
  __typename?: 'OrganizationAggregateGroupBy';
  address?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  is_root?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrganizationAggregateResponse = {
  __typename?: 'OrganizationAggregateResponse';
  avg?: Maybe<OrganizationAvgAggregate>;
  count?: Maybe<OrganizationCountAggregate>;
  groupBy?: Maybe<OrganizationAggregateGroupBy>;
  max?: Maybe<OrganizationMaxAggregate>;
  min?: Maybe<OrganizationMinAggregate>;
  sum?: Maybe<OrganizationSumAggregate>;
};

export type OrganizationAvgAggregate = {
  __typename?: 'OrganizationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** Array of edges. */
  edges: Array<OrganizationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type OrganizationCountAggregate = {
  __typename?: 'OrganizationCountAggregate';
  address?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_root?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  npi?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type OrganizationDeleteFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<OrganizationDeleteFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  is_root?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationDeleteFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type OrganizationDeleteResponse = {
  __typename?: 'OrganizationDeleteResponse';
  address?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  is_root?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  tax_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Organization */
  node: Organization;
};

export type OrganizationFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<OrganizationFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  is_root?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  providers?: InputMaybe<OrganizationFilterProviderFilter>;
  type?: InputMaybe<StringFieldComparison>;
};

export type OrganizationFilterProviderFilter = {
  and?: InputMaybe<Array<OrganizationFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type OrganizationMaxAggregate = {
  __typename?: 'OrganizationMaxAggregate';
  address?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrganizationMinAggregate = {
  __typename?: 'OrganizationMinAggregate';
  address?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrganizationRolesAggregateGroupBy = {
  __typename?: 'OrganizationRolesAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  provider_assign?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
};

export type OrganizationRolesAggregateResponse = {
  __typename?: 'OrganizationRolesAggregateResponse';
  avg?: Maybe<OrganizationRolesAvgAggregate>;
  count?: Maybe<OrganizationRolesCountAggregate>;
  groupBy?: Maybe<OrganizationRolesAggregateGroupBy>;
  max?: Maybe<OrganizationRolesMaxAggregate>;
  min?: Maybe<OrganizationRolesMinAggregate>;
  sum?: Maybe<OrganizationRolesSumAggregate>;
};

export type OrganizationRolesAvgAggregate = {
  __typename?: 'OrganizationRolesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export type OrganizationRolesCountAggregate = {
  __typename?: 'OrganizationRolesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  provider_assign?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['Int']>;
};

export type OrganizationRolesMaxAggregate = {
  __typename?: 'OrganizationRolesMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
};

export type OrganizationRolesMinAggregate = {
  __typename?: 'OrganizationRolesMinAggregate';
  id?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
};

export type OrganizationRolesSumAggregate = {
  __typename?: 'OrganizationRolesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export type OrganizationSort = {
  direction: SortDirection;
  field: OrganizationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrganizationSortFields {
  Address = 'address',
  Email = 'email',
  Id = 'id',
  IsRoot = 'is_root',
  Name = 'name',
  Npi = 'npi',
  Phone = 'phone',
  Type = 'type'
}

export type OrganizationSubscriptionFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<OrganizationSubscriptionFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  is_root?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationSubscriptionFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type OrganizationSumAggregate = {
  __typename?: 'OrganizationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type OrganizationUpdateFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<OrganizationUpdateFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  is_root?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationUpdateFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export enum ParStatus {
  No = 'No',
  Yes = 'Yes'
}

export type ParStatusFilterComparison = {
  eq?: InputMaybe<ParStatus>;
  gt?: InputMaybe<ParStatus>;
  gte?: InputMaybe<ParStatus>;
  iLike?: InputMaybe<ParStatus>;
  in?: InputMaybe<Array<ParStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ParStatus>;
  lt?: InputMaybe<ParStatus>;
  lte?: InputMaybe<ParStatus>;
  neq?: InputMaybe<ParStatus>;
  notILike?: InputMaybe<ParStatus>;
  notIn?: InputMaybe<Array<ParStatus>>;
  notLike?: InputMaybe<ParStatus>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export enum ParseInterval {
  Daily = 'DAILY',
  EveryEightHour = 'EVERY_EIGHT_HOUR',
  EveryFiveMin = 'EVERY_FIVE_MIN',
  EveryFourHour = 'EVERY_FOUR_HOUR',
  EveryHour = 'EVERY_HOUR',
  EveryThirtyMin = 'EVERY_THIRTY_MIN',
  EveryTwentyHour = 'EVERY_TWENTY_HOUR',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type PeerReferences = {
  __typename?: 'PeerReferences';
  createdAt: Scalars['Date'];
  degree?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  id: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prefix?: Maybe<PeerReferencesPrefix>;
  provider: Provider;
  provider_id: Scalars['Float'];
  speciality?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type PeerReferencesAggregateFilter = {
  and?: InputMaybe<Array<PeerReferencesAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PeerReferencesAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PeerReferencesConnection = {
  __typename?: 'PeerReferencesConnection';
  /** Array of edges. */
  edges: Array<PeerReferencesEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PeerReferencesDeleteFilter = {
  and?: InputMaybe<Array<PeerReferencesDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PeerReferencesDeleteFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PeerReferencesDeleteResponse = {
  __typename?: 'PeerReferencesDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  degree?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prefix?: Maybe<PeerReferencesPrefix>;
  provider_id?: Maybe<Scalars['Float']>;
  speciality?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PeerReferencesEdge = {
  __typename?: 'PeerReferencesEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the PeerReferences */
  node: PeerReferences;
};

export type PeerReferencesFilter = {
  and?: InputMaybe<Array<PeerReferencesFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PeerReferencesFilter>>;
  provider?: InputMaybe<PeerReferencesFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PeerReferencesFilterProviderFilter = {
  and?: InputMaybe<Array<PeerReferencesFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PeerReferencesFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export enum PeerReferencesPrefix {
  Capt = 'CAPT',
  Dr = 'DR',
  Maj = 'MAJ',
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  Prof = 'PROF',
  Rev = 'REV',
  Sir = 'SIR'
}

export type PeerReferencesSort = {
  direction: SortDirection;
  field: PeerReferencesSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PeerReferencesSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'provider_id',
  UpdatedAt = 'updatedAt'
}

export type PeerReferencesSubscriptionFilter = {
  and?: InputMaybe<Array<PeerReferencesSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PeerReferencesSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PeerReferencesUpdateFilter = {
  and?: InputMaybe<Array<PeerReferencesUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PeerReferencesUpdateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PracticeEmployer = {
  __typename?: 'PracticeEmployer';
  createdAt: Scalars['Date'];
  employer_address: Scalars['JSON'];
  employer_name: Scalars['String'];
  end_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  office_type: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  start_at: Scalars['String'];
};

export type PracticeEmployerAggregateFilter = {
  and?: InputMaybe<Array<PracticeEmployerAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  employer_address?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PracticeEmployerAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type PracticeEmployerDeleteResponse = {
  __typename?: 'PracticeEmployerDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  employer_name?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  office_type?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  start_at?: Maybe<Scalars['String']>;
};

export type PracticeEmployerEdge = {
  __typename?: 'PracticeEmployerEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the PracticeEmployer */
  node: PracticeEmployer;
};

export type PracticeEmployerFilter = {
  and?: InputMaybe<Array<PracticeEmployerFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  employer_address?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PracticeEmployerFilter>>;
  provider?: InputMaybe<PracticeEmployerFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type PracticeEmployerFilterProviderFilter = {
  and?: InputMaybe<Array<PracticeEmployerFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PracticeEmployerFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type PracticeEmployerSort = {
  direction: SortDirection;
  field: PracticeEmployerSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PracticeEmployerSortFields {
  CreatedAt = 'createdAt',
  EmployerAddress = 'employer_address',
  Id = 'id',
  ProviderId = 'provider_id'
}

export type PracticeEmployerSubscriptionFilter = {
  and?: InputMaybe<Array<PracticeEmployerSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  employer_address?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<PracticeEmployerSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type Provider = {
  __typename?: 'Provider';
  addresses: Array<Address>;
  addressesAggregate: Array<ProviderAddressesAggregateResponse>;
  archived_at?: Maybe<Scalars['DateTime']>;
  assignees: Array<User>;
  assigneesAggregate: Array<ProviderAssigneesAggregateResponse>;
  avatar?: Maybe<Document>;
  birth_info?: Maybe<ProviderBirthInfo>;
  birthdate?: Maybe<Scalars['LocalDate']>;
  board_certifications: Array<BoardCertification>;
  board_certificationsAggregate: Array<ProviderBoard_CertificationsAggregateResponse>;
  cmes: Array<Cme>;
  cmesAggregate: Array<ProviderCmesAggregateResponse>;
  createdAt: Scalars['Date'];
  credentials: Array<Credentials>;
  credentialsAggregate: Array<ProviderCredentialsAggregateResponse>;
  demographic_info?: Maybe<ProviderDemographicInfo>;
  documents: Array<Document>;
  documentsAggregate: Array<ProviderDocumentsAggregateResponse>;
  documentsBadges?: Maybe<DocumentsBadgesDto>;
  driver_license?: Maybe<Scalars['String']>;
  educations: Array<Education>;
  educationsAggregate: Array<ProviderEducationsAggregateResponse>;
  email: Scalars['String'];
  exams: Array<Exam>;
  examsAggregate: Array<ProviderExamsAggregateResponse>;
  facility_affiliations: Array<FacilityAffiliation>;
  facility_affiliationsAggregate: Array<ProviderFacility_AffiliationsAggregateResponse>;
  first_name: Scalars['String'];
  full_name: Scalars['String'];
  gender?: Maybe<ProviderGender>;
  healthcare_payors: Array<HealthcarePayors>;
  healthcare_payorsAggregate: Array<ProviderHealthcare_PayorsAggregateResponse>;
  id?: Maybe<Scalars['Float']>;
  import_logs: Array<ImportLog>;
  last_name: Scalars['String'];
  legal_answers: Array<LegalAnswer>;
  legal_answersAggregate: Array<ProviderLegal_AnswersAggregateResponse>;
  licenses: Array<License>;
  licensesAggregate: Array<ProviderLicensesAggregateResponse>;
  malpractice_insurances: Array<MalpracticeInsurance>;
  malpractice_insurancesAggregate: Array<ProviderMalpractice_InsurancesAggregateResponse>;
  middle_name?: Maybe<Scalars['String']>;
  more_names: Array<ProviderMoreNames>;
  more_namesAggregate: Array<ProviderMore_NamesAggregateResponse>;
  ncsbn_id?: Maybe<Scalars['String']>;
  ncsbn_id_check: Scalars['Boolean'];
  npi?: Maybe<Npi>;
  organizations: Array<Organization>;
  organizationsAggregate: Array<ProviderOrganizationsAggregateResponse>;
  peer_references: Array<PeerReferences>;
  peer_referencesAggregate: Array<ProviderPeer_ReferencesAggregateResponse>;
  phone?: Maybe<Scalars['String']>;
  phone_verified: Scalars['Boolean'];
  practice_employers: Array<PracticeEmployer>;
  practice_employersAggregate: Array<ProviderPractice_EmployersAggregateResponse>;
  primary_receipt_ncsbn_id: Scalars['Boolean'];
  sessions: ProviderSessionsConnection;
  sessionsAggregate: Array<ProviderSessionsAggregateResponse>;
  specialty?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  stats?: Maybe<ProviderStatsDto>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type: ProviderType;
  type_code: Scalars['String'];
  user: User;
  user_id: Scalars['Float'];
  work_email?: Maybe<Scalars['String']>;
  work_histories: Array<WorkHistory>;
  work_historiesAggregate: Array<ProviderWork_HistoriesAggregateResponse>;
  work_phone?: Maybe<Scalars['String']>;
};


export type ProviderAddressesArgs = {
  filter?: AddressFilter;
  sorting?: Array<AddressSort>;
};


export type ProviderAddressesAggregateArgs = {
  filter?: InputMaybe<AddressAggregateFilter>;
};


export type ProviderAssigneesArgs = {
  filter?: UserFilter;
  sorting?: Array<UserSort>;
};


export type ProviderAssigneesAggregateArgs = {
  filter?: InputMaybe<UserAggregateFilter>;
};


export type ProviderBoard_CertificationsArgs = {
  filter?: BoardCertificationFilter;
  sorting?: Array<BoardCertificationSort>;
};


export type ProviderBoard_CertificationsAggregateArgs = {
  filter?: InputMaybe<BoardCertificationAggregateFilter>;
};


export type ProviderCmesArgs = {
  filter?: CmeFilter;
  sorting?: Array<CmeSort>;
};


export type ProviderCmesAggregateArgs = {
  filter?: InputMaybe<CmeAggregateFilter>;
};


export type ProviderCredentialsArgs = {
  filter?: CredentialsFilter;
  sorting?: Array<CredentialsSort>;
};


export type ProviderCredentialsAggregateArgs = {
  filter?: InputMaybe<CredentialsAggregateFilter>;
};


export type ProviderDocumentsArgs = {
  filter?: DocumentFilter;
  sorting?: Array<DocumentSort>;
};


export type ProviderDocumentsAggregateArgs = {
  filter?: InputMaybe<DocumentAggregateFilter>;
};


export type ProviderEducationsArgs = {
  filter?: EducationFilter;
  sorting?: Array<EducationSort>;
};


export type ProviderEducationsAggregateArgs = {
  filter?: InputMaybe<EducationAggregateFilter>;
};


export type ProviderExamsArgs = {
  filter?: ExamFilter;
  sorting?: Array<ExamSort>;
};


export type ProviderExamsAggregateArgs = {
  filter?: InputMaybe<ExamAggregateFilter>;
};


export type ProviderFacility_AffiliationsArgs = {
  filter?: FacilityAffiliationFilter;
  sorting?: Array<FacilityAffiliationSort>;
};


export type ProviderFacility_AffiliationsAggregateArgs = {
  filter?: InputMaybe<FacilityAffiliationAggregateFilter>;
};


export type ProviderHealthcare_PayorsArgs = {
  filter?: HealthcarePayorsFilter;
  sorting?: Array<HealthcarePayorsSort>;
};


export type ProviderHealthcare_PayorsAggregateArgs = {
  filter?: InputMaybe<HealthcarePayorsAggregateFilter>;
};


export type ProviderLegal_AnswersArgs = {
  filter?: LegalAnswerFilter;
  sorting?: Array<LegalAnswerSort>;
};


export type ProviderLegal_AnswersAggregateArgs = {
  filter?: InputMaybe<LegalAnswerAggregateFilter>;
};


export type ProviderLicensesArgs = {
  filter?: LicenseFilter;
  sorting?: Array<LicenseSort>;
};


export type ProviderLicensesAggregateArgs = {
  filter?: InputMaybe<LicenseAggregateFilter>;
};


export type ProviderMalpractice_InsurancesArgs = {
  filter?: MalpracticeInsuranceFilter;
  sorting?: Array<MalpracticeInsuranceSort>;
};


export type ProviderMalpractice_InsurancesAggregateArgs = {
  filter?: InputMaybe<MalpracticeInsuranceAggregateFilter>;
};


export type ProviderMore_NamesArgs = {
  filter?: ProviderMoreNamesFilter;
  sorting?: Array<ProviderMoreNamesSort>;
};


export type ProviderMore_NamesAggregateArgs = {
  filter?: InputMaybe<ProviderMoreNamesAggregateFilter>;
};


export type ProviderOrganizationsArgs = {
  filter?: OrganizationFilter;
  sorting?: Array<OrganizationSort>;
};


export type ProviderOrganizationsAggregateArgs = {
  filter?: InputMaybe<OrganizationAggregateFilter>;
};


export type ProviderPeer_ReferencesArgs = {
  filter?: PeerReferencesFilter;
  sorting?: Array<PeerReferencesSort>;
};


export type ProviderPeer_ReferencesAggregateArgs = {
  filter?: InputMaybe<PeerReferencesAggregateFilter>;
};


export type ProviderPractice_EmployersArgs = {
  filter?: PracticeEmployerFilter;
  sorting?: Array<PracticeEmployerSort>;
};


export type ProviderPractice_EmployersAggregateArgs = {
  filter?: InputMaybe<PracticeEmployerAggregateFilter>;
};


export type ProviderSessionsArgs = {
  filter?: SessionFilter;
  paging?: OffsetPaging;
  sorting?: Array<SessionSort>;
};


export type ProviderSessionsAggregateArgs = {
  filter?: InputMaybe<SessionAggregateFilter>;
};


export type ProviderWork_HistoriesArgs = {
  filter?: WorkHistoryFilter;
  sorting?: Array<WorkHistorySort>;
};


export type ProviderWork_HistoriesAggregateArgs = {
  filter?: InputMaybe<WorkHistoryAggregateFilter>;
};

export type ProviderAddressesAggregateGroupBy = {
  __typename?: 'ProviderAddressesAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  npi_number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderAddressesAggregateResponse = {
  __typename?: 'ProviderAddressesAggregateResponse';
  avg?: Maybe<ProviderAddressesAvgAggregate>;
  count?: Maybe<ProviderAddressesCountAggregate>;
  groupBy?: Maybe<ProviderAddressesAggregateGroupBy>;
  max?: Maybe<ProviderAddressesMaxAggregate>;
  min?: Maybe<ProviderAddressesMinAggregate>;
  sum?: Maybe<ProviderAddressesSumAggregate>;
};

export type ProviderAddressesAvgAggregate = {
  __typename?: 'ProviderAddressesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderAddressesCountAggregate = {
  __typename?: 'ProviderAddressesCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  npi_number?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

export type ProviderAddressesMaxAggregate = {
  __typename?: 'ProviderAddressesMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  npi_number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderAddressesMinAggregate = {
  __typename?: 'ProviderAddressesMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  npi_number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderAddressesSumAggregate = {
  __typename?: 'ProviderAddressesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderAggregateFilter = {
  and?: InputMaybe<Array<ProviderAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderAggregateFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type ProviderAggregateGroupBy = {
  __typename?: 'ProviderAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  driver_license?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<ProviderGender>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

export type ProviderAggregateResponse = {
  __typename?: 'ProviderAggregateResponse';
  avg?: Maybe<ProviderAvgAggregate>;
  count?: Maybe<ProviderCountAggregate>;
  groupBy?: Maybe<ProviderAggregateGroupBy>;
  max?: Maybe<ProviderMaxAggregate>;
  min?: Maybe<ProviderMinAggregate>;
  sum?: Maybe<ProviderSumAggregate>;
};

export type ProviderAssigneesAggregateGroupBy = {
  __typename?: 'ProviderAssigneesAggregateGroupBy';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  sortByAssignes?: Maybe<Array<Scalars['Float']>>;
  status?: Maybe<UserStatus>;
};

export type ProviderAssigneesAggregateResponse = {
  __typename?: 'ProviderAssigneesAggregateResponse';
  avg?: Maybe<ProviderAssigneesAvgAggregate>;
  count?: Maybe<ProviderAssigneesCountAggregate>;
  groupBy?: Maybe<ProviderAssigneesAggregateGroupBy>;
  max?: Maybe<ProviderAssigneesMaxAggregate>;
  min?: Maybe<ProviderAssigneesMinAggregate>;
  sum?: Maybe<ProviderAssigneesSumAggregate>;
};

export type ProviderAssigneesAvgAggregate = {
  __typename?: 'ProviderAssigneesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export type ProviderAssigneesCountAggregate = {
  __typename?: 'ProviderAssigneesCountAggregate';
  email?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  sortByAssignes?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type ProviderAssigneesDto = {
  __typename?: 'ProviderAssigneesDTO';
  provider_id: Scalars['Float'];
  type: Scalars['String'];
  user_id: Scalars['Float'];
};

export type ProviderAssigneesMaxAggregate = {
  __typename?: 'ProviderAssigneesMaxAggregate';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  sortByAssignes?: Maybe<Array<Scalars['Float']>>;
  status?: Maybe<UserStatus>;
};

export type ProviderAssigneesMinAggregate = {
  __typename?: 'ProviderAssigneesMinAggregate';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  sortByAssignes?: Maybe<Array<Scalars['Float']>>;
  status?: Maybe<UserStatus>;
};

export type ProviderAssigneesSumAggregate = {
  __typename?: 'ProviderAssigneesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export type ProviderAvgAggregate = {
  __typename?: 'ProviderAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderBirthInfo = {
  __typename?: 'ProviderBirthInfo';
  birthCity?: Maybe<Scalars['String']>;
  birthCountry?: Maybe<CountryCode>;
  birthCounty?: Maybe<Scalars['String']>;
  birthProvince?: Maybe<Scalars['String']>;
  countryOfCitizenship?: Maybe<CountryCode>;
  createdAt: Scalars['Date'];
  id: Scalars['Float'];
  provider_id: Scalars['Float'];
};

export type ProviderBirthInfoConnection = {
  __typename?: 'ProviderBirthInfoConnection';
  /** Array of edges. */
  edges: Array<ProviderBirthInfoEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ProviderBirthInfoDeleteResponse = {
  __typename?: 'ProviderBirthInfoDeleteResponse';
  birthCity?: Maybe<Scalars['String']>;
  birthCountry?: Maybe<CountryCode>;
  birthCounty?: Maybe<Scalars['String']>;
  birthProvince?: Maybe<Scalars['String']>;
  countryOfCitizenship?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderBirthInfoEdge = {
  __typename?: 'ProviderBirthInfoEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderBirthInfo */
  node: ProviderBirthInfo;
};

export type ProviderBirthInfoFilter = {
  and?: InputMaybe<Array<ProviderBirthInfoFilter>>;
  birthCity?: InputMaybe<StringFieldComparison>;
  birthCountry?: InputMaybe<CountryCodeFilterComparison>;
  birthCounty?: InputMaybe<StringFieldComparison>;
  birthProvince?: InputMaybe<StringFieldComparison>;
  countryOfCitizenship?: InputMaybe<CountryCodeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderBirthInfoFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderBirthInfoSort = {
  direction: SortDirection;
  field: ProviderBirthInfoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderBirthInfoSortFields {
  BirthCity = 'birthCity',
  BirthCountry = 'birthCountry',
  BirthCounty = 'birthCounty',
  BirthProvince = 'birthProvince',
  CountryOfCitizenship = 'countryOfCitizenship',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'provider_id'
}

export type ProviderBirthInfoSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderBirthInfoSubscriptionFilter>>;
  birthCity?: InputMaybe<StringFieldComparison>;
  birthCountry?: InputMaybe<CountryCodeFilterComparison>;
  birthCounty?: InputMaybe<StringFieldComparison>;
  birthProvince?: InputMaybe<StringFieldComparison>;
  countryOfCitizenship?: InputMaybe<CountryCodeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderBirthInfoSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderBoard_CertificationsAggregateGroupBy = {
  __typename?: 'ProviderBoard_certificationsAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderBoard_CertificationsAggregateResponse = {
  __typename?: 'ProviderBoard_certificationsAggregateResponse';
  avg?: Maybe<ProviderBoard_CertificationsAvgAggregate>;
  count?: Maybe<ProviderBoard_CertificationsCountAggregate>;
  groupBy?: Maybe<ProviderBoard_CertificationsAggregateGroupBy>;
  max?: Maybe<ProviderBoard_CertificationsMaxAggregate>;
  min?: Maybe<ProviderBoard_CertificationsMinAggregate>;
  sum?: Maybe<ProviderBoard_CertificationsSumAggregate>;
};

export type ProviderBoard_CertificationsAvgAggregate = {
  __typename?: 'ProviderBoard_certificationsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderBoard_CertificationsCountAggregate = {
  __typename?: 'ProviderBoard_certificationsCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

export type ProviderBoard_CertificationsMaxAggregate = {
  __typename?: 'ProviderBoard_certificationsMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderBoard_CertificationsMinAggregate = {
  __typename?: 'ProviderBoard_certificationsMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderBoard_CertificationsSumAggregate = {
  __typename?: 'ProviderBoard_certificationsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderCmesAggregateGroupBy = {
  __typename?: 'ProviderCmesAggregateGroupBy';
  apply_licenses_by_topic?: Maybe<Scalars['JSON']>;
  apply_licenses_ids?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  credits?: Maybe<Scalars['String']>;
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  topic?: Maybe<Scalars['String']>;
};

export type ProviderCmesAggregateResponse = {
  __typename?: 'ProviderCmesAggregateResponse';
  avg?: Maybe<ProviderCmesAvgAggregate>;
  count?: Maybe<ProviderCmesCountAggregate>;
  groupBy?: Maybe<ProviderCmesAggregateGroupBy>;
  max?: Maybe<ProviderCmesMaxAggregate>;
  min?: Maybe<ProviderCmesMinAggregate>;
  sum?: Maybe<ProviderCmesSumAggregate>;
};

export type ProviderCmesAvgAggregate = {
  __typename?: 'ProviderCmesAvgAggregate';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderCmesCountAggregate = {
  __typename?: 'ProviderCmesCountAggregate';
  apply_licenses_by_topic?: Maybe<Scalars['Int']>;
  apply_licenses_ids?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  document_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['Int']>;
};

export type ProviderCmesMaxAggregate = {
  __typename?: 'ProviderCmesMaxAggregate';
  apply_licenses_by_topic?: Maybe<Scalars['JSON']>;
  apply_licenses_ids?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  credits?: Maybe<Scalars['String']>;
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  topic?: Maybe<Scalars['String']>;
};

export type ProviderCmesMinAggregate = {
  __typename?: 'ProviderCmesMinAggregate';
  apply_licenses_by_topic?: Maybe<Scalars['JSON']>;
  apply_licenses_ids?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  credits?: Maybe<Scalars['String']>;
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  topic?: Maybe<Scalars['String']>;
};

export type ProviderCmesSumAggregate = {
  __typename?: 'ProviderCmesSumAggregate';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  /** Array of edges. */
  edges: Array<ProviderEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ProviderCountAggregate = {
  __typename?: 'ProviderCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  driver_license?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  specialty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['Int']>;
  type_code?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  work_email?: Maybe<Scalars['Int']>;
  work_phone?: Maybe<Scalars['Int']>;
};

export type ProviderCredentialsAggregateGroupBy = {
  __typename?: 'ProviderCredentialsAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
};

export type ProviderCredentialsAggregateResponse = {
  __typename?: 'ProviderCredentialsAggregateResponse';
  avg?: Maybe<ProviderCredentialsAvgAggregate>;
  count?: Maybe<ProviderCredentialsCountAggregate>;
  groupBy?: Maybe<ProviderCredentialsAggregateGroupBy>;
  max?: Maybe<ProviderCredentialsMaxAggregate>;
  min?: Maybe<ProviderCredentialsMinAggregate>;
  sum?: Maybe<ProviderCredentialsSumAggregate>;
};

export type ProviderCredentialsAvgAggregate = {
  __typename?: 'ProviderCredentialsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderCredentialsCountAggregate = {
  __typename?: 'ProviderCredentialsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  login?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['Int']>;
};

export type ProviderCredentialsMaxAggregate = {
  __typename?: 'ProviderCredentialsMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
};

export type ProviderCredentialsMinAggregate = {
  __typename?: 'ProviderCredentialsMinAggregate';
  id?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
};

export type ProviderCredentialsSumAggregate = {
  __typename?: 'ProviderCredentialsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderDeleteResponse = {
  __typename?: 'ProviderDeleteResponse';
  archived_at?: Maybe<Scalars['DateTime']>;
  birthdate?: Maybe<Scalars['LocalDate']>;
  createdAt?: Maybe<Scalars['Date']>;
  driver_license?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<ProviderGender>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  ncsbn_id?: Maybe<Scalars['String']>;
  ncsbn_id_check?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_verified?: Maybe<Scalars['Boolean']>;
  primary_receipt_ncsbn_id?: Maybe<Scalars['Boolean']>;
  specialty?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

export type ProviderDemographicInfo = {
  __typename?: 'ProviderDemographicInfo';
  createdAt: Scalars['Date'];
  ethnicity?: Maybe<Ethnicity>;
  eyeColor?: Maybe<EyeColor>;
  hairColor?: Maybe<HairColor>;
  heightFt?: Maybe<Scalars['Float']>;
  heightIn?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  otherEthnicity?: Maybe<Scalars['String']>;
  provider_id: Scalars['Float'];
  weightLbs?: Maybe<Scalars['Float']>;
};

export type ProviderDemographicInfoConnection = {
  __typename?: 'ProviderDemographicInfoConnection';
  /** Array of edges. */
  edges: Array<ProviderDemographicInfoEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ProviderDemographicInfoDeleteResponse = {
  __typename?: 'ProviderDemographicInfoDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  ethnicity?: Maybe<Ethnicity>;
  eyeColor?: Maybe<EyeColor>;
  hairColor?: Maybe<HairColor>;
  heightFt?: Maybe<Scalars['Float']>;
  heightIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  otherEthnicity?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  weightLbs?: Maybe<Scalars['Float']>;
};

export type ProviderDemographicInfoEdge = {
  __typename?: 'ProviderDemographicInfoEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderDemographicInfo */
  node: ProviderDemographicInfo;
};

export type ProviderDemographicInfoFilter = {
  and?: InputMaybe<Array<ProviderDemographicInfoFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  ethnicity?: InputMaybe<EthnicityFilterComparison>;
  eyeColor?: InputMaybe<EyeColorFilterComparison>;
  hairColor?: InputMaybe<HairColorFilterComparison>;
  heightFt?: InputMaybe<NumberFieldComparison>;
  heightIn?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderDemographicInfoFilter>>;
  otherEthnicity?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  weightLbs?: InputMaybe<NumberFieldComparison>;
};

export type ProviderDemographicInfoSort = {
  direction: SortDirection;
  field: ProviderDemographicInfoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderDemographicInfoSortFields {
  CreatedAt = 'createdAt',
  Ethnicity = 'ethnicity',
  EyeColor = 'eyeColor',
  HairColor = 'hairColor',
  HeightFt = 'heightFt',
  HeightIn = 'heightIn',
  Id = 'id',
  OtherEthnicity = 'otherEthnicity',
  ProviderId = 'provider_id',
  WeightLbs = 'weightLbs'
}

export type ProviderDemographicInfoSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderDemographicInfoSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  ethnicity?: InputMaybe<EthnicityFilterComparison>;
  eyeColor?: InputMaybe<EyeColorFilterComparison>;
  hairColor?: InputMaybe<HairColorFilterComparison>;
  heightFt?: InputMaybe<NumberFieldComparison>;
  heightIn?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderDemographicInfoSubscriptionFilter>>;
  otherEthnicity?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  weightLbs?: InputMaybe<NumberFieldComparison>;
};

export type ProviderDocumentsAggregateGroupBy = {
  __typename?: 'ProviderDocumentsAggregateGroupBy';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type ProviderDocumentsAggregateResponse = {
  __typename?: 'ProviderDocumentsAggregateResponse';
  avg?: Maybe<ProviderDocumentsAvgAggregate>;
  count?: Maybe<ProviderDocumentsCountAggregate>;
  groupBy?: Maybe<ProviderDocumentsAggregateGroupBy>;
  max?: Maybe<ProviderDocumentsMaxAggregate>;
  min?: Maybe<ProviderDocumentsMinAggregate>;
  sum?: Maybe<ProviderDocumentsSumAggregate>;
};

export type ProviderDocumentsAvgAggregate = {
  __typename?: 'ProviderDocumentsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type ProviderDocumentsCountAggregate = {
  __typename?: 'ProviderDocumentsCountAggregate';
  extra?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mime?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  uploadedAt?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Int']>;
  visibility?: Maybe<Scalars['Int']>;
};

export type ProviderDocumentsMaxAggregate = {
  __typename?: 'ProviderDocumentsMaxAggregate';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type ProviderDocumentsMinAggregate = {
  __typename?: 'ProviderDocumentsMinAggregate';
  extra?: Maybe<Scalars['DocumentsExtra']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type ProviderDocumentsSumAggregate = {
  __typename?: 'ProviderDocumentsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['Float']>;
};

export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Provider */
  node: Provider;
};

export type ProviderEducationsAggregateGroupBy = {
  __typename?: 'ProviderEducationsAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  institutionAddress?: Maybe<Scalars['JSON']>;
  provider_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderEducationsAggregateResponse = {
  __typename?: 'ProviderEducationsAggregateResponse';
  avg?: Maybe<ProviderEducationsAvgAggregate>;
  count?: Maybe<ProviderEducationsCountAggregate>;
  groupBy?: Maybe<ProviderEducationsAggregateGroupBy>;
  max?: Maybe<ProviderEducationsMaxAggregate>;
  min?: Maybe<ProviderEducationsMinAggregate>;
  sum?: Maybe<ProviderEducationsSumAggregate>;
};

export type ProviderEducationsAvgAggregate = {
  __typename?: 'ProviderEducationsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderEducationsCountAggregate = {
  __typename?: 'ProviderEducationsCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  institutionAddress?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type ProviderEducationsMaxAggregate = {
  __typename?: 'ProviderEducationsMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  institutionAddress?: Maybe<Scalars['JSON']>;
  provider_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderEducationsMinAggregate = {
  __typename?: 'ProviderEducationsMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  institutionAddress?: Maybe<Scalars['JSON']>;
  provider_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderEducationsSumAggregate = {
  __typename?: 'ProviderEducationsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderExamsAggregateGroupBy = {
  __typename?: 'ProviderExamsAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderExamsAggregateResponse = {
  __typename?: 'ProviderExamsAggregateResponse';
  avg?: Maybe<ProviderExamsAvgAggregate>;
  count?: Maybe<ProviderExamsCountAggregate>;
  groupBy?: Maybe<ProviderExamsAggregateGroupBy>;
  max?: Maybe<ProviderExamsMaxAggregate>;
  min?: Maybe<ProviderExamsMinAggregate>;
  sum?: Maybe<ProviderExamsSumAggregate>;
};

export type ProviderExamsAvgAggregate = {
  __typename?: 'ProviderExamsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderExamsCountAggregate = {
  __typename?: 'ProviderExamsCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type ProviderExamsMaxAggregate = {
  __typename?: 'ProviderExamsMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderExamsMinAggregate = {
  __typename?: 'ProviderExamsMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderExamsSumAggregate = {
  __typename?: 'ProviderExamsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderFacility_AffiliationsAggregateGroupBy = {
  __typename?: 'ProviderFacility_affiliationsAggregateGroupBy';
  address?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderFacility_AffiliationsAggregateResponse = {
  __typename?: 'ProviderFacility_affiliationsAggregateResponse';
  avg?: Maybe<ProviderFacility_AffiliationsAvgAggregate>;
  count?: Maybe<ProviderFacility_AffiliationsCountAggregate>;
  groupBy?: Maybe<ProviderFacility_AffiliationsAggregateGroupBy>;
  max?: Maybe<ProviderFacility_AffiliationsMaxAggregate>;
  min?: Maybe<ProviderFacility_AffiliationsMinAggregate>;
  sum?: Maybe<ProviderFacility_AffiliationsSumAggregate>;
};

export type ProviderFacility_AffiliationsAvgAggregate = {
  __typename?: 'ProviderFacility_affiliationsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderFacility_AffiliationsCountAggregate = {
  __typename?: 'ProviderFacility_affiliationsCountAggregate';
  address?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

export type ProviderFacility_AffiliationsMaxAggregate = {
  __typename?: 'ProviderFacility_affiliationsMaxAggregate';
  address?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderFacility_AffiliationsMinAggregate = {
  __typename?: 'ProviderFacility_affiliationsMinAggregate';
  address?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderFacility_AffiliationsSumAggregate = {
  __typename?: 'ProviderFacility_affiliationsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderFilter = {
  and?: InputMaybe<Array<ProviderFilter>>;
  assignees?: InputMaybe<ProviderFilterUserFilter>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<ProviderFilterNpiFilter>;
  or?: InputMaybe<Array<ProviderFilter>>;
  organizations?: InputMaybe<ProviderFilterOrganizationFilter>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type ProviderFilterNpiFilter = {
  and?: InputMaybe<Array<ProviderFilterNpiFilter>>;
  number?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderFilterNpiFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderFilterOrganizationFilter = {
  address?: InputMaybe<JsonFieldComparison>;
  and?: InputMaybe<Array<ProviderFilterOrganizationFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  is_root?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  npi?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderFilterOrganizationFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderFilterUserFilter = {
  and?: InputMaybe<Array<ProviderFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderFilterUserFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type ProviderFilterValuesDto = {
  __typename?: 'ProviderFilterValuesDto';
  specialties: Array<FilterOption>;
};

export enum ProviderGender {
  Female = 'Female',
  Male = 'Male'
}

export type ProviderGenderFilterComparison = {
  eq?: InputMaybe<ProviderGender>;
  gt?: InputMaybe<ProviderGender>;
  gte?: InputMaybe<ProviderGender>;
  iLike?: InputMaybe<ProviderGender>;
  in?: InputMaybe<Array<ProviderGender>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ProviderGender>;
  lt?: InputMaybe<ProviderGender>;
  lte?: InputMaybe<ProviderGender>;
  neq?: InputMaybe<ProviderGender>;
  notILike?: InputMaybe<ProviderGender>;
  notIn?: InputMaybe<Array<ProviderGender>>;
  notLike?: InputMaybe<ProviderGender>;
};

export type ProviderGroup = {
  __typename?: 'ProviderGroup';
  assignees: Array<User>;
  assigneesAggregate: Array<ProviderGroupAssigneesAggregateResponse>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  licensesCount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  owner: User;
  owner_id: Scalars['Float'];
  providers: ProviderGroupProvidersConnection;
  providersAggregate: Array<ProviderGroupProvidersAggregateResponse>;
  type: Scalars['String'];
};


export type ProviderGroupAssigneesArgs = {
  filter?: UserFilter;
  sorting?: Array<UserSort>;
};


export type ProviderGroupAssigneesAggregateArgs = {
  filter?: InputMaybe<UserAggregateFilter>;
};


export type ProviderGroupProvidersArgs = {
  filter?: ProviderFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderSort>;
};


export type ProviderGroupProvidersAggregateArgs = {
  filter?: InputMaybe<ProviderAggregateFilter>;
};

export type ProviderGroupAggregateFilter = {
  and?: InputMaybe<Array<ProviderGroupAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderGroupAggregateFilter>>;
};

export type ProviderGroupAggregateGroupBy = {
  __typename?: 'ProviderGroupAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupAggregateResponse = {
  __typename?: 'ProviderGroupAggregateResponse';
  avg?: Maybe<ProviderGroupAvgAggregate>;
  count?: Maybe<ProviderGroupCountAggregate>;
  groupBy?: Maybe<ProviderGroupAggregateGroupBy>;
  max?: Maybe<ProviderGroupMaxAggregate>;
  min?: Maybe<ProviderGroupMinAggregate>;
  sum?: Maybe<ProviderGroupSumAggregate>;
};

export type ProviderGroupAssigneesAggregateGroupBy = {
  __typename?: 'ProviderGroupAssigneesAggregateGroupBy';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  sortByAssignes?: Maybe<Array<Scalars['Float']>>;
  status?: Maybe<UserStatus>;
};

export type ProviderGroupAssigneesAggregateResponse = {
  __typename?: 'ProviderGroupAssigneesAggregateResponse';
  avg?: Maybe<ProviderGroupAssigneesAvgAggregate>;
  count?: Maybe<ProviderGroupAssigneesCountAggregate>;
  groupBy?: Maybe<ProviderGroupAssigneesAggregateGroupBy>;
  max?: Maybe<ProviderGroupAssigneesMaxAggregate>;
  min?: Maybe<ProviderGroupAssigneesMinAggregate>;
  sum?: Maybe<ProviderGroupAssigneesSumAggregate>;
};

export type ProviderGroupAssigneesAvgAggregate = {
  __typename?: 'ProviderGroupAssigneesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupAssigneesCountAggregate = {
  __typename?: 'ProviderGroupAssigneesCountAggregate';
  email?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  sortByAssignes?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type ProviderGroupAssigneesMaxAggregate = {
  __typename?: 'ProviderGroupAssigneesMaxAggregate';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  sortByAssignes?: Maybe<Array<Scalars['Float']>>;
  status?: Maybe<UserStatus>;
};

export type ProviderGroupAssigneesMinAggregate = {
  __typename?: 'ProviderGroupAssigneesMinAggregate';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  sortByAssignes?: Maybe<Array<Scalars['Float']>>;
  status?: Maybe<UserStatus>;
};

export type ProviderGroupAssigneesSumAggregate = {
  __typename?: 'ProviderGroupAssigneesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  org_id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupAvgAggregate = {
  __typename?: 'ProviderGroupAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupConnection = {
  __typename?: 'ProviderGroupConnection';
  /** Array of edges. */
  edges: Array<ProviderGroupEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ProviderGroupCountAggregate = {
  __typename?: 'ProviderGroupCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type ProviderGroupDeleteFilter = {
  and?: InputMaybe<Array<ProviderGroupDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderGroupDeleteFilter>>;
};

export type ProviderGroupDeleteResponse = {
  __typename?: 'ProviderGroupDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderGroupEdge = {
  __typename?: 'ProviderGroupEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderGroup */
  node: ProviderGroup;
};

export type ProviderGroupFilter = {
  and?: InputMaybe<Array<ProviderGroupFilter>>;
  assignees?: InputMaybe<ProviderGroupFilterUserFilter>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderGroupFilter>>;
  providers?: InputMaybe<ProviderGroupFilterProviderFilter>;
};

export type ProviderGroupFilterProviderFilter = {
  and?: InputMaybe<Array<ProviderGroupFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderGroupFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type ProviderGroupFilterUserFilter = {
  and?: InputMaybe<Array<ProviderGroupFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderGroupFilterUserFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type ProviderGroupMaxAggregate = {
  __typename?: 'ProviderGroupMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupMinAggregate = {
  __typename?: 'ProviderGroupMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupProvidersAggregateGroupBy = {
  __typename?: 'ProviderGroupProvidersAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  driver_license?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<ProviderGender>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

export type ProviderGroupProvidersAggregateResponse = {
  __typename?: 'ProviderGroupProvidersAggregateResponse';
  avg?: Maybe<ProviderGroupProvidersAvgAggregate>;
  count?: Maybe<ProviderGroupProvidersCountAggregate>;
  groupBy?: Maybe<ProviderGroupProvidersAggregateGroupBy>;
  max?: Maybe<ProviderGroupProvidersMaxAggregate>;
  min?: Maybe<ProviderGroupProvidersMinAggregate>;
  sum?: Maybe<ProviderGroupProvidersSumAggregate>;
};

export type ProviderGroupProvidersAvgAggregate = {
  __typename?: 'ProviderGroupProvidersAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupProvidersConnection = {
  __typename?: 'ProviderGroupProvidersConnection';
  /** Array of edges. */
  edges: Array<ProviderEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ProviderGroupProvidersCountAggregate = {
  __typename?: 'ProviderGroupProvidersCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  driver_license?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  specialty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  suffix?: Maybe<Scalars['Int']>;
  type_code?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  work_email?: Maybe<Scalars['Int']>;
  work_phone?: Maybe<Scalars['Int']>;
};

export type ProviderGroupProvidersMaxAggregate = {
  __typename?: 'ProviderGroupProvidersMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  driver_license?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<ProviderGender>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

export type ProviderGroupProvidersMinAggregate = {
  __typename?: 'ProviderGroupProvidersMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  driver_license?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<ProviderGender>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

export type ProviderGroupProvidersSumAggregate = {
  __typename?: 'ProviderGroupProvidersSumAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupSort = {
  direction: SortDirection;
  field: ProviderGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderGroupSortFields {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type ProviderGroupSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderGroupSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderGroupSubscriptionFilter>>;
};

export type ProviderGroupSumAggregate = {
  __typename?: 'ProviderGroupSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ProviderGroupUpdateFilter = {
  and?: InputMaybe<Array<ProviderGroupUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderGroupUpdateFilter>>;
};

export type ProviderHealthcare_PayorsAggregateGroupBy = {
  __typename?: 'ProviderHealthcare_payorsAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  par_status?: Maybe<ParStatus>;
  payor_contact?: Maybe<Scalars['JSON']>;
  provider_id?: Maybe<Scalars['Float']>;
  status?: Maybe<HealthcarePayorsStatus>;
};

export type ProviderHealthcare_PayorsAggregateResponse = {
  __typename?: 'ProviderHealthcare_payorsAggregateResponse';
  avg?: Maybe<ProviderHealthcare_PayorsAvgAggregate>;
  count?: Maybe<ProviderHealthcare_PayorsCountAggregate>;
  groupBy?: Maybe<ProviderHealthcare_PayorsAggregateGroupBy>;
  max?: Maybe<ProviderHealthcare_PayorsMaxAggregate>;
  min?: Maybe<ProviderHealthcare_PayorsMinAggregate>;
  sum?: Maybe<ProviderHealthcare_PayorsSumAggregate>;
};

export type ProviderHealthcare_PayorsAvgAggregate = {
  __typename?: 'ProviderHealthcare_payorsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderHealthcare_PayorsCountAggregate = {
  __typename?: 'ProviderHealthcare_payorsCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  par_status?: Maybe<Scalars['Int']>;
  payor_contact?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type ProviderHealthcare_PayorsMaxAggregate = {
  __typename?: 'ProviderHealthcare_payorsMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  par_status?: Maybe<ParStatus>;
  payor_contact?: Maybe<Scalars['JSON']>;
  provider_id?: Maybe<Scalars['Float']>;
  status?: Maybe<HealthcarePayorsStatus>;
};

export type ProviderHealthcare_PayorsMinAggregate = {
  __typename?: 'ProviderHealthcare_payorsMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  par_status?: Maybe<ParStatus>;
  payor_contact?: Maybe<Scalars['JSON']>;
  provider_id?: Maybe<Scalars['Float']>;
  status?: Maybe<HealthcarePayorsStatus>;
};

export type ProviderHealthcare_PayorsSumAggregate = {
  __typename?: 'ProviderHealthcare_payorsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderLegal_AnswersAggregateGroupBy = {
  __typename?: 'ProviderLegal_answersAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderLegal_AnswersAggregateResponse = {
  __typename?: 'ProviderLegal_answersAggregateResponse';
  avg?: Maybe<ProviderLegal_AnswersAvgAggregate>;
  count?: Maybe<ProviderLegal_AnswersCountAggregate>;
  groupBy?: Maybe<ProviderLegal_AnswersAggregateGroupBy>;
  max?: Maybe<ProviderLegal_AnswersMaxAggregate>;
  min?: Maybe<ProviderLegal_AnswersMinAggregate>;
  sum?: Maybe<ProviderLegal_AnswersSumAggregate>;
};

export type ProviderLegal_AnswersAvgAggregate = {
  __typename?: 'ProviderLegal_answersAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderLegal_AnswersCountAggregate = {
  __typename?: 'ProviderLegal_answersCountAggregate';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

export type ProviderLegal_AnswersMaxAggregate = {
  __typename?: 'ProviderLegal_answersMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderLegal_AnswersMinAggregate = {
  __typename?: 'ProviderLegal_answersMinAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderLegal_AnswersSumAggregate = {
  __typename?: 'ProviderLegal_answersSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderLicensesAggregateGroupBy = {
  __typename?: 'ProviderLicensesAggregateGroupBy';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  monitor?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
  verify?: Maybe<Scalars['Boolean']>;
};

export type ProviderLicensesAggregateResponse = {
  __typename?: 'ProviderLicensesAggregateResponse';
  avg?: Maybe<ProviderLicensesAvgAggregate>;
  count?: Maybe<ProviderLicensesCountAggregate>;
  groupBy?: Maybe<ProviderLicensesAggregateGroupBy>;
  max?: Maybe<ProviderLicensesMaxAggregate>;
  min?: Maybe<ProviderLicensesMinAggregate>;
  sum?: Maybe<ProviderLicensesSumAggregate>;
};

export type ProviderLicensesAvgAggregate = {
  __typename?: 'ProviderLicensesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

export type ProviderLicensesCountAggregate = {
  __typename?: 'ProviderLicensesCountAggregate';
  expires_at?: Maybe<Scalars['Int']>;
  extra?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  monitor?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  provider_type_code?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
  request_type?: Maybe<Scalars['Int']>;
  state_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Int']>;
  verify?: Maybe<Scalars['Int']>;
};

export type ProviderLicensesMaxAggregate = {
  __typename?: 'ProviderLicensesMaxAggregate';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
};

export type ProviderLicensesMinAggregate = {
  __typename?: 'ProviderLicensesMinAggregate';
  expires_at?: Maybe<Scalars['LocalDate']>;
  extra?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_type_code?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['Float']>;
  request_type?: Maybe<LicenseRequestType>;
  state_code?: Maybe<Scalars['String']>;
  status?: Maybe<LicenseStatus>;
  type?: Maybe<LicenseType>;
  updatedAt?: Maybe<Scalars['Date']>;
  verified?: Maybe<LicenseVerificationStatus>;
};

export type ProviderLicensesSumAggregate = {
  __typename?: 'ProviderLicensesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

export type ProviderMalpractice_InsurancesAggregateGroupBy = {
  __typename?: 'ProviderMalpractice_insurancesAggregateGroupBy';
  annual_aggregate_amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  insurance_address?: Maybe<Scalars['JSON']>;
  per_incident_amount?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderMalpractice_InsurancesAggregateResponse = {
  __typename?: 'ProviderMalpractice_insurancesAggregateResponse';
  avg?: Maybe<ProviderMalpractice_InsurancesAvgAggregate>;
  count?: Maybe<ProviderMalpractice_InsurancesCountAggregate>;
  groupBy?: Maybe<ProviderMalpractice_InsurancesAggregateGroupBy>;
  max?: Maybe<ProviderMalpractice_InsurancesMaxAggregate>;
  min?: Maybe<ProviderMalpractice_InsurancesMinAggregate>;
  sum?: Maybe<ProviderMalpractice_InsurancesSumAggregate>;
};

export type ProviderMalpractice_InsurancesAvgAggregate = {
  __typename?: 'ProviderMalpractice_insurancesAvgAggregate';
  annual_aggregate_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_incident_amount?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderMalpractice_InsurancesCountAggregate = {
  __typename?: 'ProviderMalpractice_insurancesCountAggregate';
  annual_aggregate_amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurance_address?: Maybe<Scalars['Int']>;
  per_incident_amount?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

export type ProviderMalpractice_InsurancesMaxAggregate = {
  __typename?: 'ProviderMalpractice_insurancesMaxAggregate';
  annual_aggregate_amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  insurance_address?: Maybe<Scalars['JSON']>;
  per_incident_amount?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderMalpractice_InsurancesMinAggregate = {
  __typename?: 'ProviderMalpractice_insurancesMinAggregate';
  annual_aggregate_amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  insurance_address?: Maybe<Scalars['JSON']>;
  per_incident_amount?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderMalpractice_InsurancesSumAggregate = {
  __typename?: 'ProviderMalpractice_insurancesSumAggregate';
  annual_aggregate_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_incident_amount?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderMaxAggregate = {
  __typename?: 'ProviderMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  driver_license?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<ProviderGender>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

export type ProviderMinAggregate = {
  __typename?: 'ProviderMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  driver_license?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<ProviderGender>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  status?: Maybe<ProviderStatus>;
  suffix?: Maybe<ProviderSuffix>;
  type_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Float']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
};

export type ProviderMoreNames = {
  __typename?: 'ProviderMoreNames';
  createdAt: Scalars['Date'];
  end_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  moreNamesType?: Maybe<MoreNamesType>;
  provider_id: Scalars['Float'];
  start_date?: Maybe<Scalars['String']>;
};

export type ProviderMoreNamesAggregateFilter = {
  and?: InputMaybe<Array<ProviderMoreNamesAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  end_date?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  moreNamesType?: InputMaybe<MoreNamesTypeFilterComparison>;
  or?: InputMaybe<Array<ProviderMoreNamesAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  start_date?: InputMaybe<StringFieldComparison>;
};

export type ProviderMoreNamesConnection = {
  __typename?: 'ProviderMoreNamesConnection';
  /** Array of edges. */
  edges: Array<ProviderMoreNamesEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ProviderMoreNamesDeleteResponse = {
  __typename?: 'ProviderMoreNamesDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  moreNamesType?: Maybe<MoreNamesType>;
  provider_id?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
};

export type ProviderMoreNamesEdge = {
  __typename?: 'ProviderMoreNamesEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderMoreNames */
  node: ProviderMoreNames;
};

export type ProviderMoreNamesFilter = {
  and?: InputMaybe<Array<ProviderMoreNamesFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  end_date?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  moreNamesType?: InputMaybe<MoreNamesTypeFilterComparison>;
  or?: InputMaybe<Array<ProviderMoreNamesFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  start_date?: InputMaybe<StringFieldComparison>;
};

export type ProviderMoreNamesSort = {
  direction: SortDirection;
  field: ProviderMoreNamesSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderMoreNamesSortFields {
  CreatedAt = 'createdAt',
  EndDate = 'end_date',
  FirstName = 'first_name',
  Id = 'id',
  LastName = 'last_name',
  MiddleName = 'middle_name',
  MoreNamesType = 'moreNamesType',
  ProviderId = 'provider_id',
  StartDate = 'start_date'
}

export type ProviderMoreNamesSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderMoreNamesSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  end_date?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  moreNamesType?: InputMaybe<MoreNamesTypeFilterComparison>;
  or?: InputMaybe<Array<ProviderMoreNamesSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  start_date?: InputMaybe<StringFieldComparison>;
};

export type ProviderMore_NamesAggregateGroupBy = {
  __typename?: 'ProviderMore_namesAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  moreNamesType?: Maybe<MoreNamesType>;
  provider_id?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
};

export type ProviderMore_NamesAggregateResponse = {
  __typename?: 'ProviderMore_namesAggregateResponse';
  avg?: Maybe<ProviderMore_NamesAvgAggregate>;
  count?: Maybe<ProviderMore_NamesCountAggregate>;
  groupBy?: Maybe<ProviderMore_NamesAggregateGroupBy>;
  max?: Maybe<ProviderMore_NamesMaxAggregate>;
  min?: Maybe<ProviderMore_NamesMinAggregate>;
  sum?: Maybe<ProviderMore_NamesSumAggregate>;
};

export type ProviderMore_NamesAvgAggregate = {
  __typename?: 'ProviderMore_namesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderMore_NamesCountAggregate = {
  __typename?: 'ProviderMore_namesCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['Int']>;
  middle_name?: Maybe<Scalars['Int']>;
  moreNamesType?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['Int']>;
};

export type ProviderMore_NamesMaxAggregate = {
  __typename?: 'ProviderMore_namesMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  moreNamesType?: Maybe<MoreNamesType>;
  provider_id?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
};

export type ProviderMore_NamesMinAggregate = {
  __typename?: 'ProviderMore_namesMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  moreNamesType?: Maybe<MoreNamesType>;
  provider_id?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
};

export type ProviderMore_NamesSumAggregate = {
  __typename?: 'ProviderMore_namesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderOrganizationsAggregateGroupBy = {
  __typename?: 'ProviderOrganizationsAggregateGroupBy';
  address?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  is_root?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderOrganizationsAggregateResponse = {
  __typename?: 'ProviderOrganizationsAggregateResponse';
  avg?: Maybe<ProviderOrganizationsAvgAggregate>;
  count?: Maybe<ProviderOrganizationsCountAggregate>;
  groupBy?: Maybe<ProviderOrganizationsAggregateGroupBy>;
  max?: Maybe<ProviderOrganizationsMaxAggregate>;
  min?: Maybe<ProviderOrganizationsMinAggregate>;
  sum?: Maybe<ProviderOrganizationsSumAggregate>;
};

export type ProviderOrganizationsAvgAggregate = {
  __typename?: 'ProviderOrganizationsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ProviderOrganizationsCountAggregate = {
  __typename?: 'ProviderOrganizationsCountAggregate';
  address?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_root?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  npi?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type ProviderOrganizationsMaxAggregate = {
  __typename?: 'ProviderOrganizationsMaxAggregate';
  address?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderOrganizationsMinAggregate = {
  __typename?: 'ProviderOrganizationsMinAggregate';
  address?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderOrganizationsSumAggregate = {
  __typename?: 'ProviderOrganizationsSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ProviderOrgsDto = {
  __typename?: 'ProviderOrgsDTO';
  org?: Maybe<Organization>;
  org_id: Scalars['Float'];
  provider_id: Scalars['Float'];
  type: Scalars['String'];
};

export type ProviderPeer_ReferencesAggregateGroupBy = {
  __typename?: 'ProviderPeer_referencesAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProviderPeer_ReferencesAggregateResponse = {
  __typename?: 'ProviderPeer_referencesAggregateResponse';
  avg?: Maybe<ProviderPeer_ReferencesAvgAggregate>;
  count?: Maybe<ProviderPeer_ReferencesCountAggregate>;
  groupBy?: Maybe<ProviderPeer_ReferencesAggregateGroupBy>;
  max?: Maybe<ProviderPeer_ReferencesMaxAggregate>;
  min?: Maybe<ProviderPeer_ReferencesMinAggregate>;
  sum?: Maybe<ProviderPeer_ReferencesSumAggregate>;
};

export type ProviderPeer_ReferencesAvgAggregate = {
  __typename?: 'ProviderPeer_referencesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderPeer_ReferencesCountAggregate = {
  __typename?: 'ProviderPeer_referencesCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ProviderPeer_ReferencesMaxAggregate = {
  __typename?: 'ProviderPeer_referencesMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProviderPeer_ReferencesMinAggregate = {
  __typename?: 'ProviderPeer_referencesMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProviderPeer_ReferencesSumAggregate = {
  __typename?: 'ProviderPeer_referencesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderPractice_EmployersAggregateGroupBy = {
  __typename?: 'ProviderPractice_employersAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderPractice_EmployersAggregateResponse = {
  __typename?: 'ProviderPractice_employersAggregateResponse';
  avg?: Maybe<ProviderPractice_EmployersAvgAggregate>;
  count?: Maybe<ProviderPractice_EmployersCountAggregate>;
  groupBy?: Maybe<ProviderPractice_EmployersAggregateGroupBy>;
  max?: Maybe<ProviderPractice_EmployersMaxAggregate>;
  min?: Maybe<ProviderPractice_EmployersMinAggregate>;
  sum?: Maybe<ProviderPractice_EmployersSumAggregate>;
};

export type ProviderPractice_EmployersAvgAggregate = {
  __typename?: 'ProviderPractice_employersAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderPractice_EmployersCountAggregate = {
  __typename?: 'ProviderPractice_employersCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  employer_address?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

export type ProviderPractice_EmployersMaxAggregate = {
  __typename?: 'ProviderPractice_employersMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderPractice_EmployersMinAggregate = {
  __typename?: 'ProviderPractice_employersMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderPractice_EmployersSumAggregate = {
  __typename?: 'ProviderPractice_employersSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderSessionsAggregateGroupBy = {
  __typename?: 'ProviderSessionsAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderSessionsAggregateResponse = {
  __typename?: 'ProviderSessionsAggregateResponse';
  avg?: Maybe<ProviderSessionsAvgAggregate>;
  count?: Maybe<ProviderSessionsCountAggregate>;
  groupBy?: Maybe<ProviderSessionsAggregateGroupBy>;
  max?: Maybe<ProviderSessionsMaxAggregate>;
  min?: Maybe<ProviderSessionsMinAggregate>;
  sum?: Maybe<ProviderSessionsSumAggregate>;
};

export type ProviderSessionsAvgAggregate = {
  __typename?: 'ProviderSessionsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderSessionsConnection = {
  __typename?: 'ProviderSessionsConnection';
  /** Array of nodes. */
  nodes: Array<Session>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type ProviderSessionsCountAggregate = {
  __typename?: 'ProviderSessionsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type ProviderSessionsMaxAggregate = {
  __typename?: 'ProviderSessionsMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderSessionsMinAggregate = {
  __typename?: 'ProviderSessionsMinAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderSessionsSumAggregate = {
  __typename?: 'ProviderSessionsSumAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderShare = {
  __typename?: 'ProviderShare';
  address: Scalars['Boolean'];
  birth_info: Scalars['Boolean'];
  board_certification: Scalars['Boolean'];
  cancelAt?: Maybe<Scalars['Date']>;
  cme: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  csr_licenses: Scalars['Boolean'];
  dea_licenses: Scalars['Boolean'];
  demographic_info: Scalars['Boolean'];
  education: Scalars['Boolean'];
  email: Scalars['Boolean'];
  exams: Scalars['Boolean'];
  facility_affiliations: Scalars['Boolean'];
  healthcare_payors: Scalars['Boolean'];
  id: Scalars['Float'];
  legal_questions: Scalars['Boolean'];
  malpractice_insurance: Scalars['Boolean'];
  more_names: Scalars['Boolean'];
  npdb: Scalars['Boolean'];
  npi: Scalars['Boolean'];
  peer_references: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
  practice_employer: Scalars['Boolean'];
  provider_id: Scalars['Float'];
  shareDocs: Array<ProviderShareDoc>;
  share_to?: Maybe<Array<Scalars['String']>>;
  state: Scalars['String'];
  state_licenses: Scalars['Boolean'];
  type: Scalars['String'];
  work_email: Scalars['Boolean'];
  work_history: Scalars['Boolean'];
  work_phone: Scalars['Boolean'];
};


export type ProviderShareShareDocsArgs = {
  filter?: ProviderShareDocFilter;
  sorting?: Array<ProviderShareDocSort>;
};

export type ProviderShareCancelDto = {
  id?: InputMaybe<Scalars['Float']>;
  sharedDocIds: Array<Scalars['Float']>;
};

export type ProviderShareConnection = {
  __typename?: 'ProviderShareConnection';
  /** Array of edges. */
  edges: Array<ProviderShareEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ProviderShareDto = {
  address: Scalars['Boolean'];
  birth_info: Scalars['Boolean'];
  board_certification: Scalars['Boolean'];
  cancelAt?: InputMaybe<Scalars['Date']>;
  cme: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['Date']>;
  csr_licenses: Scalars['Boolean'];
  dea_licenses: Scalars['Boolean'];
  demographic_info: Scalars['Boolean'];
  education: Scalars['Boolean'];
  email: Scalars['Boolean'];
  exams: Scalars['Boolean'];
  facility_affiliations: Scalars['Boolean'];
  healthcare_payors: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Float']>;
  isChangedOptions: Scalars['Boolean'];
  legal_questions: Scalars['Boolean'];
  malpractice_insurance: Scalars['Boolean'];
  more_names: Scalars['Boolean'];
  npdb: Scalars['Boolean'];
  npi: Scalars['Boolean'];
  peer_references: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
  practice_employer: Scalars['Boolean'];
  provider_id: Scalars['Float'];
  share_to: Array<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_licenses: Scalars['Boolean'];
  type: Scalars['String'];
  work_email: Scalars['Boolean'];
  work_history: Scalars['Boolean'];
  work_phone: Scalars['Boolean'];
};

export type ProviderShareDeleteFilter = {
  and?: InputMaybe<Array<ProviderShareDeleteFilter>>;
  cancelAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareDeleteFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderShareDeleteResponse = {
  __typename?: 'ProviderShareDeleteResponse';
  address?: Maybe<Scalars['Boolean']>;
  birth_info?: Maybe<Scalars['Boolean']>;
  board_certification?: Maybe<Scalars['Boolean']>;
  cancelAt?: Maybe<Scalars['Date']>;
  cme?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  csr_licenses?: Maybe<Scalars['Boolean']>;
  dea_licenses?: Maybe<Scalars['Boolean']>;
  demographic_info?: Maybe<Scalars['Boolean']>;
  education?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  exams?: Maybe<Scalars['Boolean']>;
  facility_affiliations?: Maybe<Scalars['Boolean']>;
  healthcare_payors?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  legal_questions?: Maybe<Scalars['Boolean']>;
  malpractice_insurance?: Maybe<Scalars['Boolean']>;
  more_names?: Maybe<Scalars['Boolean']>;
  npdb?: Maybe<Scalars['Boolean']>;
  npi?: Maybe<Scalars['Boolean']>;
  peer_references?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  photo?: Maybe<Scalars['Boolean']>;
  practice_employer?: Maybe<Scalars['Boolean']>;
  provider_id?: Maybe<Scalars['Float']>;
  share_to?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<Scalars['String']>;
  state_licenses?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['Boolean']>;
  work_history?: Maybe<Scalars['Boolean']>;
  work_phone?: Maybe<Scalars['Boolean']>;
};

export type ProviderShareDoc = {
  __typename?: 'ProviderShareDoc';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  path?: Maybe<Scalars['String']>;
  providerShare: ProviderShare;
  provider_id: Scalars['Float'];
  share_id: Scalars['Float'];
  signedUrl: Scalars['String'];
};

export type ProviderShareDocConnection = {
  __typename?: 'ProviderShareDocConnection';
  /** Array of edges. */
  edges: Array<ProviderShareDocEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ProviderShareDocDeleteFilter = {
  and?: InputMaybe<Array<ProviderShareDocDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareDocDeleteFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  share_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderShareDocDeleteResponse = {
  __typename?: 'ProviderShareDocDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  share_id?: Maybe<Scalars['Float']>;
};

export type ProviderShareDocEdge = {
  __typename?: 'ProviderShareDocEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderShareDoc */
  node: ProviderShareDoc;
};

export type ProviderShareDocFilter = {
  and?: InputMaybe<Array<ProviderShareDocFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareDocFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  providerShare?: InputMaybe<ProviderShareDocFilterProviderShareFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  share_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderShareDocFilterProviderShareFilter = {
  and?: InputMaybe<Array<ProviderShareDocFilterProviderShareFilter>>;
  cancelAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareDocFilterProviderShareFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderShareDocSort = {
  direction: SortDirection;
  field: ProviderShareDocSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderShareDocSortFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  Path = 'path',
  ProviderId = 'provider_id',
  ShareId = 'share_id'
}

export type ProviderShareDocSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderShareDocSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareDocSubscriptionFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  share_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderShareDocUpdateFilter = {
  and?: InputMaybe<Array<ProviderShareDocUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareDocUpdateFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  share_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderShareEdge = {
  __typename?: 'ProviderShareEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderShare */
  node: ProviderShare;
};

export type ProviderShareFilter = {
  and?: InputMaybe<Array<ProviderShareFilter>>;
  cancelAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  shareDocs?: InputMaybe<ProviderShareFilterProviderShareDocFilter>;
  state?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderShareFilterProviderShareDocFilter = {
  and?: InputMaybe<Array<ProviderShareFilterProviderShareDocFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareFilterProviderShareDocFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  share_id?: InputMaybe<NumberFieldComparison>;
};

export type ProviderSharePreviewDto = {
  __typename?: 'ProviderSharePreviewDTO';
  address: Scalars['Boolean'];
  birth_info: Scalars['Boolean'];
  board_certification: Scalars['Boolean'];
  cme: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  csr_licenses: Scalars['Boolean'];
  dea_licenses: Scalars['Boolean'];
  demographic_info: Scalars['Boolean'];
  education: Scalars['Boolean'];
  email: Scalars['Boolean'];
  exams: Scalars['Boolean'];
  facility_affiliations: Scalars['Boolean'];
  healthcare_payors: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isChangedOptions: Scalars['Boolean'];
  legal_questions: Scalars['Boolean'];
  malpractice_insurance: Scalars['Boolean'];
  more_names: Scalars['Boolean'];
  npdb: Scalars['Boolean'];
  npi: Scalars['Boolean'];
  peer_references: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
  practice_employer: Scalars['Boolean'];
  provider_id: Scalars['Float'];
  share_to?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<Scalars['String']>;
  state_licenses: Scalars['Boolean'];
  type: Scalars['String'];
  work_email: Scalars['Boolean'];
  work_history: Scalars['Boolean'];
  work_phone: Scalars['Boolean'];
};

export type ProviderSharePreviewInputDto = {
  address: Scalars['Boolean'];
  birth_info: Scalars['Boolean'];
  board_certification: Scalars['Boolean'];
  cme: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['Date']>;
  csr_licenses: Scalars['Boolean'];
  dea_licenses: Scalars['Boolean'];
  demographic_info: Scalars['Boolean'];
  education: Scalars['Boolean'];
  email: Scalars['Boolean'];
  exams: Scalars['Boolean'];
  facility_affiliations: Scalars['Boolean'];
  healthcare_payors: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Float']>;
  isChangedOptions: Scalars['Boolean'];
  legal_questions: Scalars['Boolean'];
  malpractice_insurance: Scalars['Boolean'];
  more_names: Scalars['Boolean'];
  npdb: Scalars['Boolean'];
  npi: Scalars['Boolean'];
  peer_references: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
  practice_employer: Scalars['Boolean'];
  provider_id: Scalars['Float'];
  share_to?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<Scalars['String']>;
  state_licenses: Scalars['Boolean'];
  type: Scalars['String'];
  work_email: Scalars['Boolean'];
  work_history: Scalars['Boolean'];
  work_phone: Scalars['Boolean'];
};

export type ProviderShareSort = {
  direction: SortDirection;
  field: ProviderShareSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderShareSortFields {
  CancelAt = 'cancelAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'provider_id',
  State = 'state',
  Type = 'type'
}

export type ProviderShareSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderShareSubscriptionFilter>>;
  cancelAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderShareUpdateFilter = {
  and?: InputMaybe<Array<ProviderShareUpdateFilter>>;
  cancelAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderShareUpdateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderSort = {
  direction: SortDirection;
  field: ProviderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderSortFields {
  CreatedAt = 'createdAt',
  DriverLicense = 'driver_license',
  Email = 'email',
  FirstName = 'first_name',
  FullName = 'full_name',
  Gender = 'gender',
  Id = 'id',
  LastName = 'last_name',
  MiddleName = 'middle_name',
  Phone = 'phone',
  Specialty = 'specialty',
  Status = 'status',
  Suffix = 'suffix',
  TypeCode = 'type_code',
  UserId = 'user_id',
  WorkEmail = 'work_email',
  WorkPhone = 'work_phone'
}

export type ProviderStatsDto = {
  __typename?: 'ProviderStatsDTO';
  address: Scalars['Float'];
  board_certification: Scalars['Float'];
  csr_licenses: Scalars['Float'];
  dea_licenses: Scalars['Float'];
  documents: Scalars['Float'];
  educations: Scalars['Float'];
  exams: Scalars['Float'];
  facility_affiliations: Scalars['Float'];
  healthcare_payors: Scalars['Float'];
  legal_questions: Scalars['Float'];
  logins: Scalars['Float'];
  malpractice_insurance: Scalars['Float'];
  npdb: Scalars['Float'];
  npi: Scalars['Float'];
  peer_references: Scalars['Float'];
  practice_employer: Scalars['Float'];
  state_licenses: Scalars['Float'];
  work_history: Scalars['Float'];
};

export enum ProviderStatus {
  Abandoned = 'Abandoned',
  Assigned = 'Assigned',
  Fulfilled = 'Fulfilled',
  InProgress = 'InProgress',
  New = 'New',
  Onboarding = 'Onboarding',
  Rfa = 'Rfa',
  Stalled = 'Stalled',
  Unresponsive = 'Unresponsive'
}

export type ProviderStatusFilterComparison = {
  eq?: InputMaybe<ProviderStatus>;
  gt?: InputMaybe<ProviderStatus>;
  gte?: InputMaybe<ProviderStatus>;
  iLike?: InputMaybe<ProviderStatus>;
  in?: InputMaybe<Array<ProviderStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ProviderStatus>;
  lt?: InputMaybe<ProviderStatus>;
  lte?: InputMaybe<ProviderStatus>;
  neq?: InputMaybe<ProviderStatus>;
  notILike?: InputMaybe<ProviderStatus>;
  notIn?: InputMaybe<Array<ProviderStatus>>;
  notLike?: InputMaybe<ProviderStatus>;
};

export type ProviderSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderSubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderSubscriptionFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export enum ProviderSuffix {
  Ii = 'Ii',
  Iii = 'Iii',
  Iv = 'Iv',
  Jr = 'Jr',
  Sr = 'Sr',
  V = 'V',
  Vi = 'Vi'
}

export type ProviderSuffixFilterComparison = {
  eq?: InputMaybe<ProviderSuffix>;
  gt?: InputMaybe<ProviderSuffix>;
  gte?: InputMaybe<ProviderSuffix>;
  iLike?: InputMaybe<ProviderSuffix>;
  in?: InputMaybe<Array<ProviderSuffix>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ProviderSuffix>;
  lt?: InputMaybe<ProviderSuffix>;
  lte?: InputMaybe<ProviderSuffix>;
  neq?: InputMaybe<ProviderSuffix>;
  notILike?: InputMaybe<ProviderSuffix>;
  notIn?: InputMaybe<Array<ProviderSuffix>>;
  notLike?: InputMaybe<ProviderSuffix>;
};

export type ProviderSumAggregate = {
  __typename?: 'ProviderSumAggregate';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

export type ProviderToGroup = {
  __typename?: 'ProviderToGroup';
  group_id: Scalars['Float'];
  provider: Provider;
  provider_id: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type ProviderToGroupConnection = {
  __typename?: 'ProviderToGroupConnection';
  /** Array of edges. */
  edges: Array<ProviderToGroupEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ProviderToGroupDeleteFilter = {
  and?: InputMaybe<Array<ProviderToGroupDeleteFilter>>;
  group_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderToGroupDeleteFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderToGroupDeleteResponse = {
  __typename?: 'ProviderToGroupDeleteResponse';
  group_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderToGroupEdge = {
  __typename?: 'ProviderToGroupEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderToGroup */
  node: ProviderToGroup;
};

export type ProviderToGroupFilter = {
  and?: InputMaybe<Array<ProviderToGroupFilter>>;
  group_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderToGroupFilter>>;
  provider?: InputMaybe<ProviderToGroupFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderToGroupFilterProviderFilter = {
  and?: InputMaybe<Array<ProviderToGroupFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderToGroupFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type ProviderToGroupSort = {
  direction: SortDirection;
  field: ProviderToGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderToGroupSortFields {
  GroupId = 'group_id',
  ProviderId = 'provider_id',
  Type = 'type'
}

export type ProviderToGroupSubscriptionFilter = {
  and?: InputMaybe<Array<ProviderToGroupSubscriptionFilter>>;
  group_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderToGroupSubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderToGroupUpdateFilter = {
  and?: InputMaybe<Array<ProviderToGroupUpdateFilter>>;
  group_id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ProviderToGroupUpdateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type ProviderType = {
  __typename?: 'ProviderType';
  code: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ProviderTypeConnection = {
  __typename?: 'ProviderTypeConnection';
  /** Array of edges. */
  edges: Array<ProviderTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ProviderTypeDeleteFilter = {
  and?: InputMaybe<Array<ProviderTypeDeleteFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderTypeDeleteFilter>>;
};

export type ProviderTypeDeleteResponse = {
  __typename?: 'ProviderTypeDeleteResponse';
  code?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProviderTypeEdge = {
  __typename?: 'ProviderTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ProviderType */
  node: ProviderType;
};

export type ProviderTypeFilter = {
  and?: InputMaybe<Array<ProviderTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderTypeFilter>>;
};

export type ProviderTypeSort = {
  direction: SortDirection;
  field: ProviderTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProviderTypeSortFields {
  Code = 'code'
}

export type ProviderTypeUpdateFilter = {
  and?: InputMaybe<Array<ProviderTypeUpdateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProviderTypeUpdateFilter>>;
};

export type ProviderWork_HistoriesAggregateGroupBy = {
  __typename?: 'ProviderWork_historiesAggregateGroupBy';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderWork_HistoriesAggregateResponse = {
  __typename?: 'ProviderWork_historiesAggregateResponse';
  avg?: Maybe<ProviderWork_HistoriesAvgAggregate>;
  count?: Maybe<ProviderWork_HistoriesCountAggregate>;
  groupBy?: Maybe<ProviderWork_HistoriesAggregateGroupBy>;
  max?: Maybe<ProviderWork_HistoriesMaxAggregate>;
  min?: Maybe<ProviderWork_HistoriesMinAggregate>;
  sum?: Maybe<ProviderWork_HistoriesSumAggregate>;
};

export type ProviderWork_HistoriesAvgAggregate = {
  __typename?: 'ProviderWork_historiesAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderWork_HistoriesCountAggregate = {
  __typename?: 'ProviderWork_historiesCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  employer_address?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

export type ProviderWork_HistoriesMaxAggregate = {
  __typename?: 'ProviderWork_historiesMaxAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderWork_HistoriesMinAggregate = {
  __typename?: 'ProviderWork_historiesMinAggregate';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type ProviderWork_HistoriesSumAggregate = {
  __typename?: 'ProviderWork_historiesSumAggregate';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

export type QualityCountersResponse = {
  __typename?: 'QualityCountersResponse';
  build: Scalars['String'];
  counters: Scalars['JSON'];
};

export type QualityIssuesResponse = {
  __typename?: 'QualityIssuesResponse';
  data: Scalars['JSON'];
};

export type Query = {
  __typename?: 'Query';
  badges: LegacyBadges;
  browsers: Array<BrowserDto>;
  changelogs: ChangelogConnection;
  checkInviteLink: SuccessCheckInviteLinkResponse;
  cme: Cme;
  cmeCycle: CmeCycle;
  cmeCycles: CmeCycleConnection;
  cmeGeneralSettings: CmeGeneralSettingsConnection;
  cmeSettings: CmeSettingsConnection;
  cmes: CmeConnection;
  credentials: CredentialsConnection;
  credentialsAggregate: Array<CredentialsAggregateResponse>;
  creditsHistory: LicenseToCmeViewConnection;
  dashboard: Dashboard;
  devToolsUsers: Array<User>;
  document: Document;
  documentAggregate: Array<DocumentAggregateResponse>;
  documents: DocumentConnection;
  error: Scalars['Boolean'];
  exam: Exam;
  exams: ExamConnection;
  getCmeSettings: CmeSettingsQueryResponse;
  getProfileShareOptions: ProviderSharePreviewDto;
  healthcarePayors: HealthcarePayorsConnection;
  healthz: Scalars['String'];
  importLog: ImportLog;
  importLogs: ImportLogConnection;
  legalAnswer: LegalAnswer;
  legalAnswers: LegalAnswerConnection;
  legalQuestion: LegalQuestion;
  legalQuestions: Array<LegalQuestion>;
  license: License;
  licenseAggregate: Array<LicenseAggregateResponse>;
  licensePrimarySourceFile: LicensePrimarySourceFile;
  licensePrimarySourceFiles: LicensePrimarySourceFileConnection;
  licenseRequest: LicenseRequest;
  licenseRequestAggregate: Array<LicenseRequestAggregateResponse>;
  licenseRequestStatus: LicenseRequestStatus;
  licenseRequestStatuses: LicenseRequestStatusConnection;
  licenseRequests: LicenseRequestConnection;
  licenseToCme: LicenseToCme;
  licenseToCmes: LicenseToCmeConnection;
  licenses: LicenseConnection;
  malpracticeInsurance: MalpracticeInsurance;
  malpracticeInsurances: MalpracticeInsuranceConnection;
  me: MeDto;
  ncsbnIdCheck: SuccessResponse;
  npi: NpiResponse;
  npiCheck: SuccessResponse;
  npiRefresh: NpiRefreshResponse;
  npiSummary: NpiSummaryResponse;
  organization: Organization;
  organizationAggregate: Array<OrganizationAggregateResponse>;
  organizations: OrganizationConnection;
  peerReferences: PeerReferencesConnection;
  provider: Provider;
  providerAggregate: Array<ProviderAggregateResponse>;
  providerBirthInfo: ProviderBirthInfo;
  providerBirthInfos: ProviderBirthInfoConnection;
  providerDemographicInfo: ProviderDemographicInfo;
  providerDemographicInfos: ProviderDemographicInfoConnection;
  providerDocumentsBadges: DocumentsBadges;
  providerFilterValues: ProviderFilterValuesDto;
  providerGroup: ProviderGroup;
  providerGroupAggregate: Array<ProviderGroupAggregateResponse>;
  providerGroups: ProviderGroupConnection;
  providerMoreNames: ProviderMoreNamesConnection;
  providerProfileShares: ProviderShareConnection;
  providerShare: ProviderShare;
  providerShareDoc: ProviderShareDoc;
  providerShareDocs: ProviderShareDocConnection;
  providerShares: ProviderShareConnection;
  providerToGroup: ProviderToGroup;
  providerToGroups: ProviderToGroupConnection;
  providerType: ProviderType;
  providerTypes: ProviderTypeConnection;
  providers: ProviderConnection;
  qualityCounters: QualityCountersResponse;
  qualityIssues: QualityIssuesResponse;
  queueConfig: QueueConfigResponse;
  requiredDocuments: Array<Scalars['String']>;
  role: Role;
  roles: Array<Role>;
  shareProfileCheckEmail: SharedProfileInfo;
  sharedProfile: SharedProfileInfo;
  state: State;
  states: StateConnection;
  static: StaticData;
  storeGetFlag: FlagResponse;
  totpSecretQrcode: TotpSecretQrcode;
  user: User;
  users: UserConnection;
};


export type QueryChangelogsArgs = {
  date?: InputMaybe<Scalars['Float']>;
  filter?: ChangelogFilter;
  paging?: CursorPaging;
  search?: InputMaybe<Scalars['String']>;
  sorting?: Array<ChangelogSort>;
};


export type QueryCheckInviteLinkArgs = {
  input: CheckInviteLinkInput;
};


export type QueryCmeArgs = {
  id: Scalars['Float'];
};


export type QueryCmeCycleArgs = {
  id: Scalars['Float'];
};


export type QueryCmeCyclesArgs = {
  filter?: CmeCycleFilter;
  paging?: CursorPaging;
  sorting?: Array<CmeCycleSort>;
};


export type QueryCmeGeneralSettingsArgs = {
  filter?: CmeGeneralSettingsFilter;
  paging?: CursorPaging;
  sorting?: Array<CmeGeneralSettingsSort>;
};


export type QueryCmeSettingsArgs = {
  filter?: CmeSettingsFilter;
  paging?: CursorPaging;
  sorting?: Array<CmeSettingsSort>;
};


export type QueryCmesArgs = {
  filter?: CmeFilter;
  paging?: CursorPaging;
  sorting?: Array<CmeSort>;
};


export type QueryCredentialsArgs = {
  filter?: CredentialsFilter;
  paging?: CursorPaging;
  sorting?: Array<CredentialsSort>;
};


export type QueryCredentialsAggregateArgs = {
  filter?: InputMaybe<CredentialsAggregateFilter>;
};


export type QueryCreditsHistoryArgs = {
  filter: LicenseToCmeCustomFilter;
  paging: LicenseToCmeCursorPaging;
};


export type QueryDocumentArgs = {
  id: Scalars['Float'];
};


export type QueryDocumentAggregateArgs = {
  filter?: InputMaybe<DocumentAggregateFilter>;
};


export type QueryDocumentsArgs = {
  filter?: DocumentFilter;
  paging?: CursorPaging;
  sorting?: Array<DocumentSort>;
};


export type QueryExamArgs = {
  id: Scalars['Float'];
};


export type QueryExamsArgs = {
  filter?: ExamFilter;
  paging?: CursorPaging;
  sorting?: Array<ExamSort>;
};


export type QueryGetProfileShareOptionsArgs = {
  preview_id: Scalars['String'];
};


export type QueryHealthcarePayorsArgs = {
  filter?: HealthcarePayorsFilter;
  paging?: CursorPaging;
  sorting?: Array<HealthcarePayorsSort>;
};


export type QueryImportLogArgs = {
  id: Scalars['Float'];
};


export type QueryImportLogsArgs = {
  filter?: ImportLogFilter;
  paging?: CursorPaging;
  sorting?: Array<ImportLogSort>;
};


export type QueryLegalAnswerArgs = {
  id: Scalars['Float'];
};


export type QueryLegalAnswersArgs = {
  filter?: LegalAnswerFilter;
  paging?: CursorPaging;
  sorting?: Array<LegalAnswerSort>;
};


export type QueryLegalQuestionArgs = {
  id: Scalars['Float'];
};


export type QueryLegalQuestionsArgs = {
  filter?: LegalQuestionFilter;
  sorting?: Array<LegalQuestionSort>;
};


export type QueryLicenseArgs = {
  id: Scalars['Float'];
};


export type QueryLicenseAggregateArgs = {
  filter?: InputMaybe<LicenseAggregateFilter>;
};


export type QueryLicensePrimarySourceFileArgs = {
  id: Scalars['Float'];
};


export type QueryLicensePrimarySourceFilesArgs = {
  filter?: LicensePrimarySourceFileFilter;
  paging?: CursorPaging;
  sorting?: Array<LicensePrimarySourceFileSort>;
};


export type QueryLicenseRequestArgs = {
  id: Scalars['Float'];
};


export type QueryLicenseRequestAggregateArgs = {
  filter?: InputMaybe<LicenseRequestAggregateFilter>;
};


export type QueryLicenseRequestStatusArgs = {
  id: Scalars['Float'];
};


export type QueryLicenseRequestStatusesArgs = {
  filter?: LicenseRequestStatusFilter;
  paging?: CursorPaging;
  sorting?: Array<LicenseRequestStatusSort>;
};


export type QueryLicenseRequestsArgs = {
  filter?: LicenseRequestFilter;
  paging?: CursorPaging;
  sorting?: Array<LicenseRequestSort>;
};


export type QueryLicenseToCmeArgs = {
  id: Scalars['Float'];
};


export type QueryLicenseToCmesArgs = {
  filter?: LicenseToCmeFilter;
  paging?: CursorPaging;
  sorting?: Array<LicenseToCmeSort>;
};


export type QueryLicensesArgs = {
  filter?: LicenseFilter;
  paging?: CursorPaging;
  sorting?: Array<LicenseSort>;
};


export type QueryMalpracticeInsuranceArgs = {
  id: Scalars['Float'];
};


export type QueryMalpracticeInsurancesArgs = {
  filter?: MalpracticeInsuranceFilter;
  paging?: CursorPaging;
  sorting?: Array<MalpracticeInsuranceSort>;
};


export type QueryNcsbnIdCheckArgs = {
  input: NcsbnIdInput;
};


export type QueryNpiArgs = {
  input: NpiFetchInput;
};


export type QueryNpiCheckArgs = {
  input: NpiImportInput;
};


export type QueryNpiRefreshArgs = {
  input: NpiImportInput;
};


export type QueryNpiSummaryArgs = {
  input: NpiFetchInput;
};


export type QueryOrganizationArgs = {
  id: Scalars['Float'];
};


export type QueryOrganizationAggregateArgs = {
  filter?: InputMaybe<OrganizationAggregateFilter>;
};


export type QueryOrganizationsArgs = {
  filter?: OrganizationFilter;
  paging?: CursorPaging;
  sorting?: Array<OrganizationSort>;
};


export type QueryPeerReferencesArgs = {
  filter?: PeerReferencesFilter;
  paging?: CursorPaging;
  sorting?: Array<PeerReferencesSort>;
};


export type QueryProviderArgs = {
  id: Scalars['Float'];
};


export type QueryProviderAggregateArgs = {
  filter?: InputMaybe<ProviderAggregateFilter>;
};


export type QueryProviderBirthInfoArgs = {
  id: Scalars['Float'];
};


export type QueryProviderBirthInfosArgs = {
  filter?: ProviderBirthInfoFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderBirthInfoSort>;
};


export type QueryProviderDemographicInfoArgs = {
  id: Scalars['Float'];
};


export type QueryProviderDemographicInfosArgs = {
  filter?: ProviderDemographicInfoFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderDemographicInfoSort>;
};


export type QueryProviderDocumentsBadgesArgs = {
  provider_id: Scalars['Float'];
};


export type QueryProviderGroupArgs = {
  id: Scalars['Float'];
};


export type QueryProviderGroupAggregateArgs = {
  filter?: InputMaybe<ProviderGroupAggregateFilter>;
};


export type QueryProviderGroupsArgs = {
  filter?: ProviderGroupFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderGroupSort>;
};


export type QueryProviderMoreNamesArgs = {
  filter?: ProviderMoreNamesFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderMoreNamesSort>;
};


export type QueryProviderProfileSharesArgs = {
  date?: InputMaybe<Scalars['Float']>;
  filter?: ProviderShareFilter;
  paging?: CursorPaging;
  search?: InputMaybe<Scalars['String']>;
  sorting?: Array<ProviderShareSort>;
};


export type QueryProviderShareArgs = {
  id: Scalars['Float'];
};


export type QueryProviderShareDocArgs = {
  id: Scalars['Float'];
};


export type QueryProviderShareDocsArgs = {
  filter?: ProviderShareDocFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderShareDocSort>;
};


export type QueryProviderSharesArgs = {
  filter?: ProviderShareFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderShareSort>;
};


export type QueryProviderToGroupArgs = {
  id: Scalars['ID'];
};


export type QueryProviderToGroupsArgs = {
  filter?: ProviderToGroupFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderToGroupSort>;
};


export type QueryProviderTypeArgs = {
  id: Scalars['String'];
};


export type QueryProviderTypesArgs = {
  filter?: ProviderTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderTypeSort>;
};


export type QueryProvidersArgs = {
  filter?: ProviderFilter;
  paging?: CursorPaging;
  sorting?: Array<ProviderSort>;
};


export type QueryQualityIssuesArgs = {
  input: Scalars['String'];
};


export type QueryRoleArgs = {
  id: Scalars['Float'];
};


export type QueryRolesArgs = {
  filter?: RoleFilter;
  sorting?: Array<RoleSort>;
};


export type QueryShareProfileCheckEmailArgs = {
  input: SharedProfileDto;
};


export type QuerySharedProfileArgs = {
  input: SharedProfileDto;
};


export type QueryStateArgs = {
  id: Scalars['String'];
};


export type QueryStatesArgs = {
  filter?: StateFilter;
  paging?: CursorPaging;
  sorting?: Array<StateSort>;
};


export type QueryStoreGetFlagArgs = {
  input: StoreGetFlagInput;
};


export type QueryUserArgs = {
  id: Scalars['Float'];
};


export type QueryUsersArgs = {
  filter?: UserFilter;
  paging?: CursorPaging;
  sorting?: Array<UserSort>;
};

export type QueueConfigInput = {
  config: Scalars['JSON'];
};

export type QueueConfigResponse = {
  __typename?: 'QueueConfigResponse';
  config: Scalars['JSON'];
};

export type RemoveApply_LicensesFromCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveAssigneesFromProviderGroupInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveAssigneesFromProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveCmeFromLicenseToCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveCme_CycleFromLicenseToCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveCmesFromProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveDocumentFromCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveExamsFromProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveHealthcare_PayorsFromProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveImport_Logs_FailedsFromLicenseInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveImport_Logs_SuccessesFromLicenseInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveInsurer_StateFromHealthcarePayorsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type RemoveInsurer_StateFromMalpracticeInsuranceInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type RemoveLicenseFromLicensePrimarySourceFileInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveLicenseFromLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveLicenseFromLicenseToCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveMalpractice_InsurancesFromProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveOrganizationFromLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveOrganizationFromRoleInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveOrganizationFromUserInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveOrganizationsFromProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemovePeer_ReferencesFromProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveProviderFromChangelogInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromExamInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromHealthcarePayorsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromImportLogInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromLicensePrimarySourceFileInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromMalpracticeInsuranceInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromPeerReferencesInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderFromProviderToGroupInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderShareFromProviderShareDocInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProviderTypeFromCmeGeneralSettingsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type RemoveProviderTypeFromCmeSettingsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type RemoveProvidersFromOrganizationInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveProvidersFromProviderGroupInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveShareDocsFromProviderShareInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveStateFromCmeSettingsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type RemoveStatusesFromLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type RemoveUserFromChangelogInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RemoveUserFromLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type RequestLicense = {
  csr_registration?: InputMaybe<Scalars['Boolean']>;
  dea_registration?: InputMaybe<Scalars['Boolean']>;
  fcvs_registration?: InputMaybe<Scalars['Boolean']>;
  license_id?: InputMaybe<Scalars['Int']>;
  provider_type_code: Scalars['String'];
  request_license_type: RequestLicenseType;
  state_code: Scalars['String'];
};

export enum RequestLicenseType {
  LicenseRenewal = 'LICENSE_RENEWAL',
  NewLicense = 'NEW_LICENSE'
}

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  org_id?: Maybe<Scalars['Float']>;
  organization?: Maybe<Organization>;
  permissions: Scalars['JSON'];
  provider_assign: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  users?: Maybe<RoleUsersConnection>;
};


export type RoleUsersArgs = {
  filter?: UserFilter;
  paging?: CursorPaging;
  sorting?: Array<UserSort>;
};

export type RoleAggregateFilter = {
  and?: InputMaybe<Array<RoleAggregateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  meta?: InputMaybe<JsonFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoleAggregateFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_assign?: InputMaybe<BooleanFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
};

export type RoleDeleteResponse = {
  __typename?: 'RoleDeleteResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  permissions?: Maybe<Scalars['JSON']>;
  provider_assign?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
};

export type RoleFilter = {
  and?: InputMaybe<Array<RoleFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  meta?: InputMaybe<JsonFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoleFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_assign?: InputMaybe<BooleanFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
  users?: InputMaybe<RoleFilterUserFilter>;
};

export type RoleFilterUserFilter = {
  and?: InputMaybe<Array<RoleFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoleFilterUserFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type RoleSort = {
  direction: SortDirection;
  field: RoleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RoleSortFields {
  Id = 'id',
  Meta = 'meta',
  Name = 'name',
  OrgId = 'org_id',
  ProviderAssign = 'provider_assign',
  Slug = 'slug'
}

export type RoleSubscriptionFilter = {
  and?: InputMaybe<Array<RoleSubscriptionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  meta?: InputMaybe<JsonFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoleSubscriptionFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_assign?: InputMaybe<BooleanFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
};

export type RoleUsersConnection = {
  __typename?: 'RoleUsersConnection';
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SelectOption = {
  __typename?: 'SelectOption';
  data: Scalars['JSON'];
  is_grouped: Scalars['Boolean'];
  key: Scalars['Float'];
};

export type SelectOptionEdge = {
  __typename?: 'SelectOptionEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the SelectOption */
  node: SelectOption;
};

export type SelectOptions = {
  __typename?: 'SelectOptions';
  board_certification: Scalars['JSON'];
  cme_type: Scalars['JSON'];
  degree: Scalars['JSON'];
  education_type: Scalars['JSON'];
  exam_type: Scalars['JSON'];
  file_type: Scalars['JSON'];
  office_type: Scalars['JSON'];
  practice_employer: Scalars['JSON'];
  specialty: Scalars['JSON'];
  staff_category: Scalars['JSON'];
};

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['Date'];
  device?: Maybe<Scalars['String']>;
  expires_at: Scalars['Date'];
  id: Scalars['Float'];
  ip?: Maybe<Scalars['String']>;
  parent_user_id?: Maybe<Scalars['Float']>;
  token: Scalars['String'];
  user_id: Scalars['Float'];
};

export type SessionAggregateFilter = {
  and?: InputMaybe<Array<SessionAggregateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<SessionAggregateFilter>>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type SessionFilter = {
  and?: InputMaybe<Array<SessionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<SessionFilter>>;
  user?: InputMaybe<SessionFilterUserFilter>;
  user_id?: InputMaybe<NumberFieldComparison>;
};

export type SessionFilterUserFilter = {
  and?: InputMaybe<Array<SessionFilterUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SessionFilterUserFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type SessionSort = {
  direction: SortDirection;
  field: SessionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SessionSortFields {
  Id = 'id',
  UserId = 'user_id'
}

export type SetApply_LicensesOnCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetAssigneesOnProviderGroupInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetAssigneesOnProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetCmeOnLicenseToCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetCme_CycleOnLicenseToCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetCmesOnProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetDocumentOnCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetExamsOnProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetHealthcare_PayorsOnProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetImport_Logs_FailedsOnLicenseInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetImport_Logs_SuccessesOnLicenseInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetInsurer_StateOnHealthcarePayorsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type SetInsurer_StateOnMalpracticeInsuranceInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type SetLicenseOnLicensePrimarySourceFileInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetLicenseOnLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetLicenseOnLicenseToCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetMalpractice_InsurancesOnProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetOrganizationOnLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetOrganizationOnRoleInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetOrganizationOnUserInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetOrganizationsOnProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetPasswordInput = {
  confirm_password: Scalars['String'];
  first_name?: InputMaybe<Scalars['String']>;
  first_time: Scalars['Boolean'];
  last_name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
  user_id: Scalars['String'];
};

export type SetPeer_ReferencesOnProviderInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetProviderOnChangelogInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnCmeInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnExamInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnHealthcarePayorsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnImportLogInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnLicensePrimarySourceFileInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnMalpracticeInsuranceInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnPeerReferencesInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderOnProviderToGroupInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderShareOnProviderShareDocInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProviderTypeOnCmeGeneralSettingsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type SetProviderTypeOnCmeSettingsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type SetProvidersOnOrganizationInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetProvidersOnProviderGroupInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetRolesOnOrganizationInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetRolesOnUserInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetShareDocsOnProviderShareInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetStateOnCmeSettingsInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['String'];
};

export type SetStatusesOnLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Float']>;
};

export type SetUserOnChangelogInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SetUserOnLicenseRequestInput = {
  /** The id of the record. */
  id: Scalars['Float'];
  /** The id of relation. */
  relationId: Scalars['Float'];
};

export type SharePdfAccessDto = {
  __typename?: 'SharePdfAccessDto';
  email: Scalars['String'];
};

export type SharePdfAuthDto = {
  code: Scalars['String'];
  hash: Scalars['String'];
};

export type SharePdfChangeDto = {
  __typename?: 'SharePdfChangeDTO';
  email: Scalars['String'];
  path: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type SharePdfPreviewDto = {
  __typename?: 'SharePdfPreviewDTO';
  filePath: Scalars['String'];
  provider_id: Scalars['Float'];
};

export type SharedProfileDto = {
  hash: Scalars['String'];
  resend?: InputMaybe<Scalars['Boolean']>;
};

export type SharedProfileInfo = {
  __typename?: 'SharedProfileInfo';
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type State = {
  __typename?: 'State';
  code: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type StateConnection = {
  __typename?: 'StateConnection';
  /** Array of edges. */
  edges: Array<StateEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type StateDeleteFilter = {
  and?: InputMaybe<Array<StateDeleteFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StateDeleteFilter>>;
};

export type StateDeleteResponse = {
  __typename?: 'StateDeleteResponse';
  code?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type StateEdge = {
  __typename?: 'StateEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the State */
  node: State;
};

export type StateFilter = {
  and?: InputMaybe<Array<StateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StateFilter>>;
};

export type StateSort = {
  direction: SortDirection;
  field: StateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StateSortFields {
  Code = 'code'
}

export type StateUpdateFilter = {
  and?: InputMaybe<Array<StateUpdateFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StateUpdateFilter>>;
};

export type StaticData = {
  __typename?: 'StaticData';
  provider_types: Array<ProviderType>;
  select_options: SelectOptions;
  states: Array<State>;
};

export type StoreGetFlagInput = {
  key: Scalars['String'];
};

export type StoreSetFlagInput = {
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
  similar?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  badgesUpdated: BadgeUpdateResult;
  createdAddress: Address;
  createdBoardCertification: BoardCertification;
  createdChangelog: Changelog;
  createdCme: Cme;
  createdCmeSettings: CmeSettings;
  createdCredentials: Credentials;
  createdDocument: Document;
  createdEducation: Education;
  createdExam: Exam;
  createdFacilityAffiliation: FacilityAffiliation;
  createdHealthcarePayors: HealthcarePayors;
  createdImportLog: ImportLog;
  createdLegalAnswer: LegalAnswer;
  createdLegalQuestion: LegalQuestion;
  createdLicense: License;
  createdLicensePrimarySourceFile: LicensePrimarySourceFile;
  createdLicenseRequest: LicenseRequest;
  createdLicenseRequestStatus: LicenseRequestStatus;
  createdLicenseToCme: LicenseToCme;
  createdMalpracticeInsurance: MalpracticeInsurance;
  createdNpi: Npi;
  createdOrganization: Organization;
  createdPeerReferences: PeerReferences;
  createdPracticeEmployer: PracticeEmployer;
  createdProvider: Provider;
  createdProviderBirthInfo: ProviderBirthInfo;
  createdProviderDemographicInfo: ProviderDemographicInfo;
  createdProviderGroup: ProviderGroup;
  createdProviderMoreNames: ProviderMoreNames;
  createdProviderShare: ProviderShare;
  createdProviderShareDoc: ProviderShareDoc;
  createdProviderToGroup: ProviderToGroup;
  createdRole: Role;
  createdUser: User;
  createdWorkHistory: WorkHistory;
  deletedManyAddresses: DeleteManyResponse;
  deletedManyBoardCertifications: DeleteManyResponse;
  deletedManyChangelogs: DeleteManyResponse;
  deletedManyCmeSettings: DeleteManyResponse;
  deletedManyCmes: DeleteManyResponse;
  deletedManyCredentials: DeleteManyResponse;
  deletedManyDocuments: DeleteManyResponse;
  deletedManyEducations: DeleteManyResponse;
  deletedManyExams: DeleteManyResponse;
  deletedManyFacilityAffiliations: DeleteManyResponse;
  deletedManyHealthcarePayors: DeleteManyResponse;
  deletedManyImportLogs: DeleteManyResponse;
  deletedManyLegalAnswers: DeleteManyResponse;
  deletedManyLegalQuestions: DeleteManyResponse;
  deletedManyLicensePrimarySourceFiles: DeleteManyResponse;
  deletedManyLicenseRequestStatuses: DeleteManyResponse;
  deletedManyLicenseRequests: DeleteManyResponse;
  deletedManyLicenseToCmes: DeleteManyResponse;
  deletedManyLicenses: DeleteManyResponse;
  deletedManyMalpracticeInsurances: DeleteManyResponse;
  deletedManyNpis: DeleteManyResponse;
  deletedManyOrganizations: DeleteManyResponse;
  deletedManyPeerReferences: DeleteManyResponse;
  deletedManyPracticeEmployers: DeleteManyResponse;
  deletedManyProviderBirthInfos: DeleteManyResponse;
  deletedManyProviderDemographicInfos: DeleteManyResponse;
  deletedManyProviderGroups: DeleteManyResponse;
  deletedManyProviderMoreNames: DeleteManyResponse;
  deletedManyProviderShareDocs: DeleteManyResponse;
  deletedManyProviderShares: DeleteManyResponse;
  deletedManyProviderToGroups: DeleteManyResponse;
  deletedManyProviders: DeleteManyResponse;
  deletedManyRoles: DeleteManyResponse;
  deletedManyUsers: DeleteManyResponse;
  deletedManyWorkHistories: DeleteManyResponse;
  deletedOneAddress: AddressDeleteResponse;
  deletedOneBoardCertification: BoardCertificationDeleteResponse;
  deletedOneChangelog: ChangelogDeleteResponse;
  deletedOneCme: CmeDeleteResponse;
  deletedOneCmeSettings: CmeSettingsDeleteResponse;
  deletedOneCredentials: CredentialsDeleteResponse;
  deletedOneDocument: DocumentDeleteResponse;
  deletedOneEducation: EducationDeleteResponse;
  deletedOneExam: ExamDeleteResponse;
  deletedOneFacilityAffiliation: FacilityAffiliationDeleteResponse;
  deletedOneHealthcarePayors: HealthcarePayorsDeleteResponse;
  deletedOneImportLog: ImportLogDeleteResponse;
  deletedOneLegalAnswer: LegalAnswerDeleteResponse;
  deletedOneLegalQuestion: LegalQuestionDeleteResponse;
  deletedOneLicense: LicenseDeleteResponse;
  deletedOneLicensePrimarySourceFile: LicensePrimarySourceFileDeleteResponse;
  deletedOneLicenseRequest: LicenseRequestDeleteResponse;
  deletedOneLicenseRequestStatus: LicenseRequestStatusDeleteResponse;
  deletedOneLicenseToCme: LicenseToCmeDeleteResponse;
  deletedOneMalpracticeInsurance: MalpracticeInsuranceDeleteResponse;
  deletedOneNpi: NpiDeleteResponse;
  deletedOneOrganization: OrganizationDeleteResponse;
  deletedOnePeerReferences: PeerReferencesDeleteResponse;
  deletedOnePracticeEmployer: PracticeEmployerDeleteResponse;
  deletedOneProvider: ProviderDeleteResponse;
  deletedOneProviderBirthInfo: ProviderBirthInfoDeleteResponse;
  deletedOneProviderDemographicInfo: ProviderDemographicInfoDeleteResponse;
  deletedOneProviderGroup: ProviderGroupDeleteResponse;
  deletedOneProviderMoreNames: ProviderMoreNamesDeleteResponse;
  deletedOneProviderShare: ProviderShareDeleteResponse;
  deletedOneProviderShareDoc: ProviderShareDocDeleteResponse;
  deletedOneProviderToGroup: ProviderToGroupDeleteResponse;
  deletedOneRole: RoleDeleteResponse;
  deletedOneUser: UserDeleteResponse;
  deletedOneWorkHistory: WorkHistoryDeleteResponse;
  licenseToCmeChanged: LicenseToCmeDto;
  ncsbnIdChecked: NcsbnIdCheckDto;
  onLogout: LogoutResponseDto;
  onMe: MeDto;
  pdfSharePreview: SharePdfPreviewDto;
  providerAssigneesChanged: ProviderAssigneesDto;
  providerDocumentsBadgesUpdated: DocumentsBadgesUpdateResult;
  providerOrgsChanged: ProviderOrgsDto;
  providerToGroupChanged: ProviderToGroup;
  sharePdfAccess: SharePdfAccessDto;
  sharedPdfChange: SharePdfChangeDto;
  shutdown: Scalars['Boolean'];
  updatedCmeSettings: CmeSettingsResponse;
  updatedManyAddresses: UpdateManyResponse;
  updatedManyBoardCertifications: UpdateManyResponse;
  updatedManyChangelogs: UpdateManyResponse;
  updatedManyCmeSettings: UpdateManyResponse;
  updatedManyCmes: UpdateManyResponse;
  updatedManyCredentials: UpdateManyResponse;
  updatedManyDocuments: UpdateManyResponse;
  updatedManyEducations: UpdateManyResponse;
  updatedManyExams: UpdateManyResponse;
  updatedManyFacilityAffiliations: UpdateManyResponse;
  updatedManyHealthcarePayors: UpdateManyResponse;
  updatedManyImportLogs: UpdateManyResponse;
  updatedManyLegalAnswers: UpdateManyResponse;
  updatedManyLegalQuestions: UpdateManyResponse;
  updatedManyLicensePrimarySourceFiles: UpdateManyResponse;
  updatedManyLicenseRequestStatuses: UpdateManyResponse;
  updatedManyLicenseRequests: UpdateManyResponse;
  updatedManyLicenseToCmes: UpdateManyResponse;
  updatedManyLicenses: UpdateManyResponse;
  updatedManyMalpracticeInsurances: UpdateManyResponse;
  updatedManyNpis: UpdateManyResponse;
  updatedManyOrganizations: UpdateManyResponse;
  updatedManyPeerReferences: UpdateManyResponse;
  updatedManyPracticeEmployers: UpdateManyResponse;
  updatedManyProviderBirthInfos: UpdateManyResponse;
  updatedManyProviderDemographicInfos: UpdateManyResponse;
  updatedManyProviderGroups: UpdateManyResponse;
  updatedManyProviderMoreNames: UpdateManyResponse;
  updatedManyProviderShareDocs: UpdateManyResponse;
  updatedManyProviderShares: UpdateManyResponse;
  updatedManyProviderToGroups: UpdateManyResponse;
  updatedManyProviders: UpdateManyResponse;
  updatedManyRoles: UpdateManyResponse;
  updatedManyUsers: UpdateManyResponse;
  updatedManyWorkHistories: UpdateManyResponse;
  updatedOneAddress: Address;
  updatedOneBoardCertification: BoardCertification;
  updatedOneChangelog: Changelog;
  updatedOneCme: Cme;
  updatedOneCmeSettings: CmeSettings;
  updatedOneCredentials: Credentials;
  updatedOneDocument: Document;
  updatedOneEducation: Education;
  updatedOneExam: Exam;
  updatedOneFacilityAffiliation: FacilityAffiliation;
  updatedOneHealthcarePayors: HealthcarePayors;
  updatedOneImportLog: ImportLog;
  updatedOneLegalAnswer: LegalAnswer;
  updatedOneLegalQuestion: LegalQuestion;
  updatedOneLicense: License;
  updatedOneLicensePrimarySourceFile: LicensePrimarySourceFile;
  updatedOneLicenseRequest: LicenseRequest;
  updatedOneLicenseRequestStatus: LicenseRequestStatus;
  updatedOneLicenseToCme: LicenseToCme;
  updatedOneMalpracticeInsurance: MalpracticeInsurance;
  updatedOneNpi: Npi;
  updatedOneOrganization: Organization;
  updatedOnePeerReferences: PeerReferences;
  updatedOnePracticeEmployer: PracticeEmployer;
  updatedOneProvider: Provider;
  updatedOneProviderBirthInfo: ProviderBirthInfo;
  updatedOneProviderDemographicInfo: ProviderDemographicInfo;
  updatedOneProviderGroup: ProviderGroup;
  updatedOneProviderMoreNames: ProviderMoreNames;
  updatedOneProviderShare: ProviderShare;
  updatedOneProviderShareDoc: ProviderShareDoc;
  updatedOneProviderToGroup: ProviderToGroup;
  updatedOneRole: Role;
  updatedOneUser: User;
  updatedOneWorkHistory: WorkHistory;
  updatedQueueConfig: QueueConfigResponse;
  userRolesChanged: UserRolesDto;
};


export type SubscriptionBadgesUpdatedArgs = {
  user_id?: InputMaybe<Scalars['Float']>;
};


export type SubscriptionCreatedAddressArgs = {
  input?: InputMaybe<CreateAddressSubscriptionFilterInput>;
};


export type SubscriptionCreatedBoardCertificationArgs = {
  input?: InputMaybe<CreateBoardCertificationSubscriptionFilterInput>;
};


export type SubscriptionCreatedChangelogArgs = {
  input?: InputMaybe<CreateChangelogSubscriptionFilterInput>;
};


export type SubscriptionCreatedCmeArgs = {
  input?: InputMaybe<CreateCmeSubscriptionFilterInput>;
};


export type SubscriptionCreatedCmeSettingsArgs = {
  input?: InputMaybe<CreateCmeSettingsSubscriptionFilterInput>;
};


export type SubscriptionCreatedCredentialsArgs = {
  input?: InputMaybe<CreateCredentialsSubscriptionFilterInput>;
};


export type SubscriptionCreatedDocumentArgs = {
  input?: InputMaybe<CreateDocumentSubscriptionFilterInput>;
};


export type SubscriptionCreatedEducationArgs = {
  input?: InputMaybe<CreateEducationSubscriptionFilterInput>;
};


export type SubscriptionCreatedExamArgs = {
  input?: InputMaybe<CreateExamSubscriptionFilterInput>;
};


export type SubscriptionCreatedFacilityAffiliationArgs = {
  input?: InputMaybe<CreateFacilityAffiliationSubscriptionFilterInput>;
};


export type SubscriptionCreatedHealthcarePayorsArgs = {
  input?: InputMaybe<CreateHealthcarePayorsSubscriptionFilterInput>;
};


export type SubscriptionCreatedImportLogArgs = {
  input?: InputMaybe<CreateImportLogSubscriptionFilterInput>;
};


export type SubscriptionCreatedLegalAnswerArgs = {
  input?: InputMaybe<CreateLegalAnswerSubscriptionFilterInput>;
};


export type SubscriptionCreatedLegalQuestionArgs = {
  input?: InputMaybe<CreateLegalQuestionSubscriptionFilterInput>;
};


export type SubscriptionCreatedLicenseArgs = {
  input?: InputMaybe<CreateLicenseSubscriptionFilterInput>;
};


export type SubscriptionCreatedLicensePrimarySourceFileArgs = {
  input?: InputMaybe<CreateLicensePrimarySourceFileSubscriptionFilterInput>;
};


export type SubscriptionCreatedLicenseRequestArgs = {
  input?: InputMaybe<CreateLicenseRequestSubscriptionFilterInput>;
};


export type SubscriptionCreatedLicenseRequestStatusArgs = {
  input?: InputMaybe<CreateLicenseRequestStatusSubscriptionFilterInput>;
};


export type SubscriptionCreatedLicenseToCmeArgs = {
  input?: InputMaybe<CreateLicenseToCmeSubscriptionFilterInput>;
};


export type SubscriptionCreatedMalpracticeInsuranceArgs = {
  input?: InputMaybe<CreateMalpracticeInsuranceSubscriptionFilterInput>;
};


export type SubscriptionCreatedNpiArgs = {
  input?: InputMaybe<CreateNpiSubscriptionFilterInput>;
};


export type SubscriptionCreatedOrganizationArgs = {
  input?: InputMaybe<CreateOrganizationSubscriptionFilterInput>;
};


export type SubscriptionCreatedPeerReferencesArgs = {
  input?: InputMaybe<CreatePeerReferencesSubscriptionFilterInput>;
};


export type SubscriptionCreatedPracticeEmployerArgs = {
  input?: InputMaybe<CreatePracticeEmployerSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderArgs = {
  input?: InputMaybe<CreateProviderSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderBirthInfoArgs = {
  input?: InputMaybe<CreateProviderBirthInfoSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderDemographicInfoArgs = {
  input?: InputMaybe<CreateProviderDemographicInfoSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderGroupArgs = {
  input?: InputMaybe<CreateProviderGroupSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderMoreNamesArgs = {
  input?: InputMaybe<CreateProviderMoreNamesSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderShareArgs = {
  input?: InputMaybe<CreateProviderShareSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderShareDocArgs = {
  input?: InputMaybe<CreateProviderShareDocSubscriptionFilterInput>;
};


export type SubscriptionCreatedProviderToGroupArgs = {
  input?: InputMaybe<CreateProviderToGroupSubscriptionFilterInput>;
};


export type SubscriptionCreatedRoleArgs = {
  input?: InputMaybe<CreateRoleSubscriptionFilterInput>;
};


export type SubscriptionCreatedUserArgs = {
  input?: InputMaybe<CreateUserSubscriptionFilterInput>;
};


export type SubscriptionCreatedWorkHistoryArgs = {
  input?: InputMaybe<CreateWorkHistorySubscriptionFilterInput>;
};


export type SubscriptionDeletedOneAddressArgs = {
  input?: InputMaybe<DeleteOneAddressSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneBoardCertificationArgs = {
  input?: InputMaybe<DeleteOneBoardCertificationSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneChangelogArgs = {
  input?: InputMaybe<DeleteOneChangelogSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneCmeArgs = {
  input?: InputMaybe<DeleteOneCmeSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneCmeSettingsArgs = {
  input?: InputMaybe<DeleteOneCmeSettingsSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneCredentialsArgs = {
  input?: InputMaybe<DeleteOneCredentialsSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneDocumentArgs = {
  input?: InputMaybe<DeleteOneDocumentSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneEducationArgs = {
  input?: InputMaybe<DeleteOneEducationSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneExamArgs = {
  input?: InputMaybe<DeleteOneExamSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneFacilityAffiliationArgs = {
  input?: InputMaybe<DeleteOneFacilityAffiliationSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneHealthcarePayorsArgs = {
  input?: InputMaybe<DeleteOneHealthcarePayorsSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneImportLogArgs = {
  input?: InputMaybe<DeleteOneImportLogSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneLegalAnswerArgs = {
  input?: InputMaybe<DeleteOneLegalAnswerSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneLegalQuestionArgs = {
  input?: InputMaybe<DeleteOneLegalQuestionSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneLicenseArgs = {
  input?: InputMaybe<DeleteOneLicenseSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneLicensePrimarySourceFileArgs = {
  input?: InputMaybe<DeleteOneLicensePrimarySourceFileSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneLicenseRequestArgs = {
  input?: InputMaybe<DeleteOneLicenseRequestSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneLicenseRequestStatusArgs = {
  input?: InputMaybe<DeleteOneLicenseRequestStatusSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneLicenseToCmeArgs = {
  input?: InputMaybe<DeleteOneLicenseToCmeSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneMalpracticeInsuranceArgs = {
  input?: InputMaybe<DeleteOneMalpracticeInsuranceSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneNpiArgs = {
  input?: InputMaybe<DeleteOneNpiSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneOrganizationArgs = {
  input?: InputMaybe<DeleteOneOrganizationSubscriptionFilterInput>;
};


export type SubscriptionDeletedOnePeerReferencesArgs = {
  input?: InputMaybe<DeleteOnePeerReferencesSubscriptionFilterInput>;
};


export type SubscriptionDeletedOnePracticeEmployerArgs = {
  input?: InputMaybe<DeleteOnePracticeEmployerSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderArgs = {
  input?: InputMaybe<DeleteOneProviderSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderBirthInfoArgs = {
  input?: InputMaybe<DeleteOneProviderBirthInfoSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderDemographicInfoArgs = {
  input?: InputMaybe<DeleteOneProviderDemographicInfoSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderGroupArgs = {
  input?: InputMaybe<DeleteOneProviderGroupSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderMoreNamesArgs = {
  input?: InputMaybe<DeleteOneProviderMoreNamesSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderShareArgs = {
  input?: InputMaybe<DeleteOneProviderShareSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderShareDocArgs = {
  input?: InputMaybe<DeleteOneProviderShareDocSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneProviderToGroupArgs = {
  input?: InputMaybe<DeleteOneProviderToGroupSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneRoleArgs = {
  input?: InputMaybe<DeleteOneRoleSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneUserArgs = {
  input?: InputMaybe<DeleteOneUserSubscriptionFilterInput>;
};


export type SubscriptionDeletedOneWorkHistoryArgs = {
  input?: InputMaybe<DeleteOneWorkHistorySubscriptionFilterInput>;
};


export type SubscriptionLicenseToCmeChangedArgs = {
  provider_id: Scalars['Float'];
};


export type SubscriptionNcsbnIdCheckedArgs = {
  user_id: Scalars['Float'];
};


export type SubscriptionOnLogoutArgs = {
  user_id: Scalars['Float'];
};


export type SubscriptionOnMeArgs = {
  user_id: Scalars['Float'];
};


export type SubscriptionPdfSharePreviewArgs = {
  provider_id: Scalars['Float'];
};


export type SubscriptionProviderDocumentsBadgesUpdatedArgs = {
  provider_id: Scalars['Float'];
};


export type SubscriptionProviderToGroupChangedArgs = {
  group_id: Scalars['Float'];
};


export type SubscriptionSharePdfAccessArgs = {
  email: Scalars['String'];
};


export type SubscriptionSharedPdfChangeArgs = {
  email: Scalars['String'];
};


export type SubscriptionUpdatedCmeSettingsArgs = {
  user_id: Scalars['Float'];
};


export type SubscriptionUpdatedOneAddressArgs = {
  input?: InputMaybe<UpdateOneAddressSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneBoardCertificationArgs = {
  input?: InputMaybe<UpdateOneBoardCertificationSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneChangelogArgs = {
  input?: InputMaybe<UpdateOneChangelogSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneCmeArgs = {
  input?: InputMaybe<UpdateOneCmeSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneCmeSettingsArgs = {
  input?: InputMaybe<UpdateOneCmeSettingsSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneCredentialsArgs = {
  input?: InputMaybe<UpdateOneCredentialsSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneDocumentArgs = {
  input?: InputMaybe<UpdateOneDocumentSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneEducationArgs = {
  input?: InputMaybe<UpdateOneEducationSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneExamArgs = {
  input?: InputMaybe<UpdateOneExamSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneFacilityAffiliationArgs = {
  input?: InputMaybe<UpdateOneFacilityAffiliationSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneHealthcarePayorsArgs = {
  input?: InputMaybe<UpdateOneHealthcarePayorsSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneImportLogArgs = {
  input?: InputMaybe<UpdateOneImportLogSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneLegalAnswerArgs = {
  input?: InputMaybe<UpdateOneLegalAnswerSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneLegalQuestionArgs = {
  input?: InputMaybe<UpdateOneLegalQuestionSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneLicenseArgs = {
  input?: InputMaybe<UpdateOneLicenseSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneLicensePrimarySourceFileArgs = {
  input?: InputMaybe<UpdateOneLicensePrimarySourceFileSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneLicenseRequestArgs = {
  input?: InputMaybe<UpdateOneLicenseRequestSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneLicenseRequestStatusArgs = {
  input?: InputMaybe<UpdateOneLicenseRequestStatusSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneLicenseToCmeArgs = {
  input?: InputMaybe<UpdateOneLicenseToCmeSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneMalpracticeInsuranceArgs = {
  input?: InputMaybe<UpdateOneMalpracticeInsuranceSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneNpiArgs = {
  input?: InputMaybe<UpdateOneNpiSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneOrganizationArgs = {
  input?: InputMaybe<UpdateOneOrganizationSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOnePeerReferencesArgs = {
  input?: InputMaybe<UpdateOnePeerReferencesSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOnePracticeEmployerArgs = {
  input?: InputMaybe<UpdateOnePracticeEmployerSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderArgs = {
  input?: InputMaybe<UpdateOneProviderSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderBirthInfoArgs = {
  input?: InputMaybe<UpdateOneProviderBirthInfoSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderDemographicInfoArgs = {
  input?: InputMaybe<UpdateOneProviderDemographicInfoSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderGroupArgs = {
  input?: InputMaybe<UpdateOneProviderGroupSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderMoreNamesArgs = {
  input?: InputMaybe<UpdateOneProviderMoreNamesSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderShareArgs = {
  input?: InputMaybe<UpdateOneProviderShareSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderShareDocArgs = {
  input?: InputMaybe<UpdateOneProviderShareDocSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneProviderToGroupArgs = {
  input?: InputMaybe<UpdateOneProviderToGroupSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneRoleArgs = {
  input?: InputMaybe<UpdateOneRoleSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneUserArgs = {
  input?: InputMaybe<UpdateOneUserSubscriptionFilterInput>;
};


export type SubscriptionUpdatedOneWorkHistoryArgs = {
  input?: InputMaybe<UpdateOneWorkHistorySubscriptionFilterInput>;
};


export type SubscriptionUpdatedQueueConfigArgs = {
  user_id: Scalars['Float'];
};

export type SuccessCheckInviteLinkResponse = {
  __typename?: 'SuccessCheckInviteLinkResponse';
  isFull: Scalars['Boolean'];
  success: Scalars['Boolean'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type TotpSecretQrcode = {
  __typename?: 'TotpSecretQrcode';
  dataUri: Scalars['String'];
};

export type TrialInviteProvider = {
  confirm_password: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  password: Scalars['String'];
  type_code: Scalars['String'];
};

export type UpdateAddress = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  createdAt?: InputMaybe<Scalars['Date']>;
  dea_check?: InputMaybe<Scalars['Boolean']>;
  dea_license_id?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  first_line?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  npi_number?: InputMaybe<Scalars['String']>;
  other_state?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  purpose?: InputMaybe<AddressPurpose>;
  state_code?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpdateBoardCertification = {
  certification?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  expires_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  is_passed?: InputMaybe<Scalars['Boolean']>;
  issued_at?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  specialty?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BoardCertificationStatus>;
};

export type UpdateCme = {
  apply_licenses_by_topic?: InputMaybe<Scalars['JSON']>;
  apply_licenses_ids?: InputMaybe<Scalars['JSON']>;
  completed?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  credits?: InputMaybe<Scalars['String']>;
  document_id?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  topic?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateCmeCycle = {
  id?: InputMaybe<Scalars['Float']>;
  license_id?: InputMaybe<Scalars['Float']>;
  period?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateCmeGeneralSettings = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  initial?: InputMaybe<Scalars['Boolean']>;
  providerTypeCode?: InputMaybe<Scalars['String']>;
  renew?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCmeSettings = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  additionalTopics?: InputMaybe<Scalars['JSON']>;
  gracePeriod?: InputMaybe<CmeSettingsGracePeriod>;
  gracePeriodValue?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  moreInformation?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Float']>;
  providerTypeCode?: InputMaybe<Scalars['String']>;
  renewal?: InputMaybe<CmeSettingsRenewal>;
  renewalValue?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<CmeSettingsReuqired>;
  requirements?: InputMaybe<CmeSettingsRequirements>;
  requirementsValue?: InputMaybe<Scalars['Float']>;
  show?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['String']>;
  statuteRuleRegulation?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CmeSettingsType>;
  typeValues?: InputMaybe<Scalars['JSON']>;
};

export type UpdateCredentials = {
  id?: InputMaybe<Scalars['Float']>;
  login?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateDocument = {
  extra?: InputMaybe<Scalars['DocumentsExtra']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  signedUrl?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  uploadedAt?: InputMaybe<Scalars['Date']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<Scalars['Float']>;
};

export type UpdateEducation = {
  createdAt?: InputMaybe<Scalars['Date']>;
  degree?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  institution?: InputMaybe<Scalars['String']>;
  institutionAddress?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  specialty?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateExam = {
  additional_type?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  date?: InputMaybe<Scalars['String']>;
  first_try_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  is_passed?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  number_of_attempts?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  score?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateFacilityAffiliation = {
  address?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  staff_category?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['String']>;
};

export type UpdateHealthcarePayors = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  effective_date?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  par_status?: InputMaybe<ParStatus>;
  payor_contact?: InputMaybe<Scalars['JSON']>;
  payor_name?: InputMaybe<Scalars['String']>;
  payor_state_code?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<HealthcarePayorsStatus>;
};

export type UpdateImportLog = {
  createdAt?: InputMaybe<Scalars['Date']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  imported_at?: InputMaybe<Scalars['Date']>;
  new_values?: InputMaybe<Scalars['JSON']>;
  old_values?: InputMaybe<Scalars['JSON']>;
  pdf?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  psv_link?: InputMaybe<Scalars['String']>;
  raw?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<ImportLogStatus>;
  subject?: InputMaybe<Scalars['String']>;
  subject_id?: InputMaybe<Scalars['Float']>;
};

export type UpdateLegalAnswer = {
  answer?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  question_id?: InputMaybe<Scalars['Float']>;
};

export type UpdateLicense = {
  cme?: InputMaybe<Scalars['JSON']>;
  expires_at?: InputMaybe<Scalars['String']>;
  issued_at?: InputMaybe<Scalars['String']>;
  last_parse?: InputMaybe<Scalars['Date']>;
  monitor?: InputMaybe<Scalars['Boolean']>;
  monitor_option?: InputMaybe<ParseInterval>;
  multi_state?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  primary_source_update?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  provider_type_code?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['JSON']>;
  request_id?: InputMaybe<Scalars['Float']>;
  request_status?: InputMaybe<LicenseRequestStatuses>;
  request_type?: InputMaybe<LicenseRequestType>;
  state_code?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LicenseStatus>;
  type?: InputMaybe<LicenseType>;
  verified?: InputMaybe<LicenseVerificationStatus>;
  verify?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateLicensePrimarySourceFile = {
  createdAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Float']>;
  license_id?: InputMaybe<Scalars['Float']>;
  pdf?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  signedUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateLicenseRequest = {
  createdAt?: InputMaybe<Scalars['Date']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  id_type?: InputMaybe<LicenseRequestIdType>;
  license_id?: InputMaybe<Scalars['Float']>;
  org_id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  provider_type_code?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['String']>;
  request_state?: InputMaybe<Scalars['String']>;
  request_type?: InputMaybe<LicenseRequestType>;
  start_at?: InputMaybe<Scalars['String']>;
  state_code?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LicenseRequestStatuses>;
  user_id?: InputMaybe<Scalars['Float']>;
};

export type UpdateLicenseRequestStatus = {
  close_comment?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  request_id?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Float']>;
  start_at?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<LicenseRequestStatuses>;
};

export type UpdateLicenseToCme = {
  cme_cycle_id?: InputMaybe<Scalars['Float']>;
  cme_id?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  license_id?: InputMaybe<Scalars['Float']>;
};

export type UpdateMalpracticeInsurance = {
  annual_aggregate_amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  effective_date?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  insurance_address?: InputMaybe<Scalars['JSON']>;
  insurance_name?: InputMaybe<Scalars['String']>;
  insurer_state_code?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  per_incident_amount?: InputMaybe<Scalars['Float']>;
  policy_number?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  retroactive_date?: InputMaybe<Scalars['String']>;
};

export type UpdateManyCmeCyclesInput = {
  /** Filter used to find fields to update */
  filter: CmeCycleUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateCmeCycle;
};

export type UpdateManyCmeGeneralSettingsInput = {
  /** Filter used to find fields to update */
  filter: CmeGeneralSettingsUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateCmeGeneralSettings;
};

export type UpdateManyCmeSettingsInput = {
  /** Filter used to find fields to update */
  filter: CmeSettingsUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateCmeSettings;
};

export type UpdateManyCmesInput = {
  /** Filter used to find fields to update */
  filter: CmeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateCme;
};

export type UpdateManyExamsInput = {
  /** Filter used to find fields to update */
  filter: ExamUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateExam;
};

export type UpdateManyHealthcarePayorsInput = {
  /** Filter used to find fields to update */
  filter: HealthcarePayorsUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateHealthcarePayors;
};

export type UpdateManyImportLogsInput = {
  /** Filter used to find fields to update */
  filter: ImportLogUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateImportLog;
};

export type UpdateManyLicensePrimarySourceFilesInput = {
  /** Filter used to find fields to update */
  filter: LicensePrimarySourceFileUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateLicensePrimarySourceFile;
};

export type UpdateManyLicenseRequestStatusesInput = {
  /** Filter used to find fields to update */
  filter: LicenseRequestStatusUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateLicenseRequestStatus;
};

export type UpdateManyLicenseRequestsInput = {
  /** Filter used to find fields to update */
  filter: LicenseRequestUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateLicenseRequest;
};

export type UpdateManyLicenseToCmesInput = {
  /** Filter used to find fields to update */
  filter: LicenseToCmeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateLicenseToCme;
};

export type UpdateManyMalpracticeInsurancesInput = {
  /** Filter used to find fields to update */
  filter: MalpracticeInsuranceUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMalpracticeInsurance;
};

export type UpdateManyOrganizationsInput = {
  /** Filter used to find fields to update */
  filter: OrganizationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateOrganization;
};

export type UpdateManyPeerReferencesInput = {
  /** Filter used to find fields to update */
  filter: PeerReferencesUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePeerReferences;
};

export type UpdateManyProviderGroupsInput = {
  /** Filter used to find fields to update */
  filter: ProviderGroupUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProviderGroup;
};

export type UpdateManyProviderShareDocsInput = {
  /** Filter used to find fields to update */
  filter: ProviderShareDocUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProviderShareDoc;
};

export type UpdateManyProviderSharesInput = {
  /** Filter used to find fields to update */
  filter: ProviderShareUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProviderShare;
};

export type UpdateManyProviderToGroupsInput = {
  /** Filter used to find fields to update */
  filter: ProviderToGroupUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProviderToGroup;
};

export type UpdateManyProviderTypesInput = {
  /** Filter used to find fields to update */
  filter: ProviderTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProviderType;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManyStatesInput = {
  /** Filter used to find fields to update */
  filter: StateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateState;
};

export type UpdateOneAddressInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateAddress;
};

export type UpdateOneAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: AddressSubscriptionFilter;
};

export type UpdateOneBoardCertificationInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateBoardCertification;
};

export type UpdateOneBoardCertificationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: BoardCertificationSubscriptionFilter;
};

export type UpdateOneChangelogSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ChangelogSubscriptionFilter;
};

export type UpdateOneCmeCycleInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateCmeCycle;
};

export type UpdateOneCmeGeneralSettingsInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateCmeGeneralSettings;
};

export type UpdateOneCmeInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateCme;
};

export type UpdateOneCmeSettingsInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateCmeSettings;
};

export type UpdateOneCmeSettingsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CmeSettingsSubscriptionFilter;
};

export type UpdateOneCmeSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CmeSubscriptionFilter;
};

export type UpdateOneCredentialsInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateCredentials;
};

export type UpdateOneCredentialsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CredentialsSubscriptionFilter;
};

export type UpdateOneDocumentInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateDocument;
};

export type UpdateOneDocumentSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: DocumentSubscriptionFilter;
};

export type UpdateOneEducationInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateEducation;
};

export type UpdateOneEducationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: EducationSubscriptionFilter;
};

export type UpdateOneExamInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateExam;
};

export type UpdateOneExamSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ExamSubscriptionFilter;
};

export type UpdateOneFacilityAffiliationInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateFacilityAffiliation;
};

export type UpdateOneFacilityAffiliationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: FacilityAffiliationSubscriptionFilter;
};

export type UpdateOneHealthcarePayorsInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateHealthcarePayors;
};

export type UpdateOneHealthcarePayorsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: HealthcarePayorsSubscriptionFilter;
};

export type UpdateOneImportLogInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateImportLog;
};

export type UpdateOneImportLogSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ImportLogSubscriptionFilter;
};

export type UpdateOneLegalAnswerInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateLegalAnswer;
};

export type UpdateOneLegalAnswerSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LegalAnswerSubscriptionFilter;
};

export type UpdateOneLegalQuestionSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LegalQuestionSubscriptionFilter;
};

export type UpdateOneLicenseInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateLicense;
};

export type UpdateOneLicensePrimarySourceFileInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateLicensePrimarySourceFile;
};

export type UpdateOneLicensePrimarySourceFileSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicensePrimarySourceFileSubscriptionFilter;
};

export type UpdateOneLicenseRequestInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateLicenseRequest;
};

export type UpdateOneLicenseRequestStatusInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateLicenseRequestStatus;
};

export type UpdateOneLicenseRequestStatusSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseRequestStatusSubscriptionFilter;
};

export type UpdateOneLicenseRequestSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseRequestSubscriptionFilter;
};

export type UpdateOneLicenseSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseSubscriptionFilter;
};

export type UpdateOneLicenseToCmeInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateLicenseToCme;
};

export type UpdateOneLicenseToCmeSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: LicenseToCmeSubscriptionFilter;
};

export type UpdateOneMalpracticeInsuranceInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateMalpracticeInsurance;
};

export type UpdateOneMalpracticeInsuranceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MalpracticeInsuranceSubscriptionFilter;
};

export type UpdateOneNpiSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: NpiSubscriptionFilter;
};

export type UpdateOneOrganizationInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateOrganization;
};

export type UpdateOneOrganizationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: OrganizationSubscriptionFilter;
};

export type UpdateOnePeerReferencesInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdatePeerReferences;
};

export type UpdateOnePeerReferencesSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PeerReferencesSubscriptionFilter;
};

export type UpdateOnePracticeEmployerInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdatePracticeEmployer;
};

export type UpdateOnePracticeEmployerSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PracticeEmployerSubscriptionFilter;
};

export type UpdateOneProviderBirthInfoInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateProviderBirthInfo;
};

export type UpdateOneProviderBirthInfoSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderBirthInfoSubscriptionFilter;
};

export type UpdateOneProviderDemographicInfoInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateProviderDemographicInfo;
};

export type UpdateOneProviderDemographicInfoSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderDemographicInfoSubscriptionFilter;
};

export type UpdateOneProviderGroupInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateProviderGroup;
};

export type UpdateOneProviderGroupSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderGroupSubscriptionFilter;
};

export type UpdateOneProviderInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateProvider;
};

export type UpdateOneProviderMoreNamesInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateProviderMoreNames;
};

export type UpdateOneProviderMoreNamesSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderMoreNamesSubscriptionFilter;
};

export type UpdateOneProviderShareDocInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateProviderShareDoc;
};

export type UpdateOneProviderShareDocSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderShareDocSubscriptionFilter;
};

export type UpdateOneProviderShareInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateProviderShare;
};

export type UpdateOneProviderShareSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderShareSubscriptionFilter;
};

export type UpdateOneProviderSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderSubscriptionFilter;
};

export type UpdateOneProviderToGroupInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateProviderToGroup;
};

export type UpdateOneProviderToGroupSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ProviderToGroupSubscriptionFilter;
};

export type UpdateOneProviderTypeInput = {
  /** The id of the record to update */
  id: Scalars['String'];
  /** The update to apply. */
  update: UpdateProviderType;
};

export type UpdateOneRoleInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateRole;
};

export type UpdateOneRoleSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RoleSubscriptionFilter;
};

export type UpdateOneStateInput = {
  /** The id of the record to update */
  id: Scalars['String'];
  /** The update to apply. */
  update: UpdateState;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateUser;
};

export type UpdateOneUserSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: UserSubscriptionFilter;
};

export type UpdateOneWorkHistoryInput = {
  /** The id of the record to update */
  id: Scalars['Float'];
  /** The update to apply. */
  update: UpdateWorkHistory;
};

export type UpdateOneWorkHistorySubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: WorkHistorySubscriptionFilter;
};

export type UpdateOrganization = {
  address?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  is_root?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  tax_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};

export type UpdatePeerReferences = {
  createdAt?: InputMaybe<Scalars['Date']>;
  degree?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<PeerReferencesPrefix>;
  provider_id?: InputMaybe<Scalars['Float']>;
  speciality?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};

export type UpdatePracticeEmployer = {
  createdAt?: InputMaybe<Scalars['Date']>;
  employer_address?: InputMaybe<Scalars['JSON']>;
  employer_name?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  office_type?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  start_at?: InputMaybe<Scalars['String']>;
};

export type UpdateProvider = {
  archived_at?: InputMaybe<Scalars['DateTime']>;
  birthdate?: InputMaybe<Scalars['LocalDate']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  driver_license?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<ProviderGender>;
  id?: InputMaybe<Scalars['Float']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  ncsbn_id?: InputMaybe<Scalars['String']>;
  ncsbn_id_check?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_verified?: InputMaybe<Scalars['Boolean']>;
  primary_receipt_ncsbn_id?: InputMaybe<Scalars['Boolean']>;
  specialty?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProviderStatus>;
  suffix?: InputMaybe<ProviderSuffix>;
  type_code?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['Float']>;
  work_email?: InputMaybe<Scalars['String']>;
  work_phone?: InputMaybe<Scalars['String']>;
};

export type UpdateProviderBirthInfo = {
  birthCity?: InputMaybe<Scalars['String']>;
  birthCountry?: InputMaybe<CountryCode>;
  birthCounty?: InputMaybe<Scalars['String']>;
  birthProvince?: InputMaybe<Scalars['String']>;
  countryOfCitizenship?: InputMaybe<CountryCode>;
  createdAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
};

export type UpdateProviderDemographicInfo = {
  createdAt?: InputMaybe<Scalars['Date']>;
  ethnicity?: InputMaybe<Ethnicity>;
  eyeColor?: InputMaybe<EyeColor>;
  hairColor?: InputMaybe<HairColor>;
  heightFt?: InputMaybe<Scalars['Float']>;
  heightIn?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  otherEthnicity?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  weightLbs?: InputMaybe<Scalars['Float']>;
};

export type UpdateProviderGroup = {
  createdAt?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateProviderMoreNames = {
  createdAt?: InputMaybe<Scalars['Date']>;
  end_date?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  moreNamesType?: InputMaybe<MoreNamesType>;
  provider_id?: InputMaybe<Scalars['Float']>;
  start_date?: InputMaybe<Scalars['String']>;
};

export type UpdateProviderShare = {
  address?: InputMaybe<Scalars['Boolean']>;
  birth_info?: InputMaybe<Scalars['Boolean']>;
  board_certification?: InputMaybe<Scalars['Boolean']>;
  cancelAt?: InputMaybe<Scalars['Date']>;
  cme?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  csr_licenses?: InputMaybe<Scalars['Boolean']>;
  dea_licenses?: InputMaybe<Scalars['Boolean']>;
  demographic_info?: InputMaybe<Scalars['Boolean']>;
  education?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  exams?: InputMaybe<Scalars['Boolean']>;
  facility_affiliations?: InputMaybe<Scalars['Boolean']>;
  healthcare_payors?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  legal_questions?: InputMaybe<Scalars['Boolean']>;
  malpractice_insurance?: InputMaybe<Scalars['Boolean']>;
  more_names?: InputMaybe<Scalars['Boolean']>;
  npdb?: InputMaybe<Scalars['Boolean']>;
  npi?: InputMaybe<Scalars['Boolean']>;
  peer_references?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
  photo?: InputMaybe<Scalars['Boolean']>;
  practice_employer?: InputMaybe<Scalars['Boolean']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  share_to?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<Scalars['String']>;
  state_licenses?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  work_email?: InputMaybe<Scalars['Boolean']>;
  work_history?: InputMaybe<Scalars['Boolean']>;
  work_phone?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateProviderShareDoc = {
  createdAt?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  path?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  share_id?: InputMaybe<Scalars['Float']>;
};

export type UpdateProviderToGroup = {
  group_id?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateProviderType = {
  code?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRole = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Float']>;
  permissions?: InputMaybe<Scalars['JSON']>;
  provider_assign?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateState = {
  code?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateUser = {
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  last_login?: InputMaybe<Scalars['Date']>;
  last_name?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkHistory = {
  createdAt?: InputMaybe<Scalars['Date']>;
  employer_address?: InputMaybe<Scalars['JSON']>;
  employer_name?: InputMaybe<Scalars['String']>;
  employer_type?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Float']>;
  start_at?: InputMaybe<Scalars['String']>;
};

export type UploadLinkRequest = {
  mime: Scalars['String'];
  name: Scalars['String'];
  provider_id?: InputMaybe<Scalars['Float']>;
  reference: Scalars['String'];
  size: Scalars['Float'];
};

export type UploadLinkRequestInput = {
  requests: Array<UploadLinkRequest>;
};

export type UploadLinksResponse = {
  __typename?: 'UploadLinksResponse';
  id: Scalars['Float'];
  link: Scalars['String'];
  reference: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  avatars?: Maybe<Array<Document>>;
  email: Scalars['String'];
  email_verified?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  last_login?: Maybe<Scalars['Date']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  roles: Array<Role>;
  sessions: UserSessionsConnection;
  status?: Maybe<UserStatus>;
  timezone?: Maybe<Scalars['String']>;
};


export type UserAvatarsArgs = {
  filter?: DocumentFilter;
  sorting?: Array<DocumentSort>;
};


export type UserRolesArgs = {
  filter?: RoleFilter;
  sorting?: Array<RoleSort>;
};


export type UserSessionsArgs = {
  filter?: SessionFilter;
  paging?: OffsetPaging;
  sorting?: Array<SessionSort>;
};

export type UserAggregateFilter = {
  and?: InputMaybe<Array<UserAggregateFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserAggregateFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  last_login?: Maybe<Scalars['Date']>;
  last_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  timezone?: Maybe<Scalars['String']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the User */
  node: User;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  avatars?: InputMaybe<UserFilterDocumentFilter>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  provider?: InputMaybe<UserFilterProviderFilter>;
  roles?: InputMaybe<UserFilterRoleFilter>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type UserFilterDocumentFilter = {
  and?: InputMaybe<Array<UserFilterDocumentFilter>>;
  extra?: InputMaybe<DocumentsExtraScalarFilterComparison>;
  hash?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  mime?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterDocumentFilter>>;
  path?: InputMaybe<StringFieldComparison>;
  provider_id?: InputMaybe<NumberFieldComparison>;
  size?: InputMaybe<NumberFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  uploadedAt?: InputMaybe<DateFieldComparison>;
  verified?: InputMaybe<BooleanFieldComparison>;
  visibility?: InputMaybe<NumberFieldComparison>;
};

export type UserFilterProviderFilter = {
  and?: InputMaybe<Array<UserFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type UserFilterRoleFilter = {
  and?: InputMaybe<Array<UserFilterRoleFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  meta?: InputMaybe<JsonFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterRoleFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  provider_assign?: InputMaybe<BooleanFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
};

export type UserRolesDto = {
  __typename?: 'UserRolesDTO';
  role_id: Scalars['Float'];
  type: Scalars['String'];
  user_id: Scalars['Float'];
};

export type UserSessionsConnection = {
  __typename?: 'UserSessionsConnection';
  /** Array of nodes. */
  nodes: Array<Session>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export type UserSortByAssignesFilterComparison = {
  eq?: InputMaybe<Array<Scalars['Float']>>;
};

export enum UserSortFields {
  Email = 'email',
  FirstName = 'first_name',
  Id = 'id',
  LastName = 'last_name',
  OrgId = 'org_id',
  Phone = 'phone',
  SortByAssignes = 'sortByAssignes',
  Status = 'status'
}

export enum UserStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Expired = 'Expired',
  Hold = 'Hold',
  Invited = 'Invited',
  NotInvited = 'NotInvited',
  Trial = 'Trial'
}

export type UserStatusFilterComparison = {
  eq?: InputMaybe<UserStatus>;
  gt?: InputMaybe<UserStatus>;
  gte?: InputMaybe<UserStatus>;
  iLike?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<UserStatus>;
  lt?: InputMaybe<UserStatus>;
  lte?: InputMaybe<UserStatus>;
  neq?: InputMaybe<UserStatus>;
  notILike?: InputMaybe<UserStatus>;
  notIn?: InputMaybe<Array<UserStatus>>;
  notLike?: InputMaybe<UserStatus>;
};

export type UserSubscriptionFilter = {
  and?: InputMaybe<Array<UserSubscriptionFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserSubscriptionFilter>>;
  org_id?: InputMaybe<NumberFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  sortByAssignes?: InputMaybe<UserSortByAssignesFilterComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
};

export type WorkHistory = {
  __typename?: 'WorkHistory';
  createdAt: Scalars['Date'];
  employer_address: Scalars['JSON'];
  employer_name: Scalars['String'];
  employer_type?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  provider: Provider;
  provider_id: Scalars['Float'];
  start_at: Scalars['String'];
};

export type WorkHistoryAggregateFilter = {
  and?: InputMaybe<Array<WorkHistoryAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  employer_address?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<WorkHistoryAggregateFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type WorkHistoryDeleteResponse = {
  __typename?: 'WorkHistoryDeleteResponse';
  createdAt?: Maybe<Scalars['Date']>;
  employer_address?: Maybe<Scalars['JSON']>;
  employer_name?: Maybe<Scalars['String']>;
  employer_type?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  start_at?: Maybe<Scalars['String']>;
};

export type WorkHistoryEdge = {
  __typename?: 'WorkHistoryEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the WorkHistory */
  node: WorkHistory;
};

export type WorkHistoryFilter = {
  and?: InputMaybe<Array<WorkHistoryFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  employer_address?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<WorkHistoryFilter>>;
  provider?: InputMaybe<WorkHistoryFilterProviderFilter>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};

export type WorkHistoryFilterProviderFilter = {
  and?: InputMaybe<Array<WorkHistoryFilterProviderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  driver_license?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  first_name?: InputMaybe<StringFieldComparison>;
  full_name?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<ProviderGenderFilterComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  last_name?: InputMaybe<StringFieldComparison>;
  middle_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkHistoryFilterProviderFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  specialty?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ProviderStatusFilterComparison>;
  suffix?: InputMaybe<ProviderSuffixFilterComparison>;
  type_code?: InputMaybe<StringFieldComparison>;
  user_id?: InputMaybe<NumberFieldComparison>;
  work_email?: InputMaybe<StringFieldComparison>;
  work_phone?: InputMaybe<StringFieldComparison>;
};

export type WorkHistorySort = {
  direction: SortDirection;
  field: WorkHistorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkHistorySortFields {
  CreatedAt = 'createdAt',
  EmployerAddress = 'employer_address',
  Id = 'id',
  ProviderId = 'provider_id'
}

export type WorkHistorySubscriptionFilter = {
  and?: InputMaybe<Array<WorkHistorySubscriptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  employer_address?: InputMaybe<JsonFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<WorkHistorySubscriptionFilter>>;
  provider_id?: InputMaybe<NumberFieldComparison>;
};
