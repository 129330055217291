import { number, object, string } from 'zod';

export const PersonalInfoDTO = object({
  id: number().optional(),
  first_name: string().min(1),
  last_name: string().min(1),
  phone: string().nullish(),
  email: string().email(),
  timezone: string(),
  status: string(),
});

export const ChangePasswordDTO = object({
  user_id: number().min(1),
  new_password: string().min(8),
  confirm_password: string().min(8),
});
