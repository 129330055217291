import { object, string } from 'zod';

export const TrialInviteDTO = object({
  type_code: string(),
  first_name: string(),
  last_name: string(),
  email: string().email(),
  password: string().min(8),
  confirm_password: string().min(8),
});
