import { array, boolean, number, object, string } from 'zod';

export const ShareProfileDTO = object({
  id: number().optional(),
  provider_id: number(),
  type: string().min(1),
  photo: boolean().default(true),
  email: boolean().default(true),
  phone: boolean().default(true),
  work_email: boolean().default(true),
  work_phone: boolean().default(true),
  birth_info: boolean().default(false),
  demographic_info: boolean().default(false),
  more_names: boolean().default(false),
  address: boolean().default(false),
  npi: boolean().default(true),
  education: boolean().default(true),
  exams: boolean().default(true),
  practice_employer: boolean().default(true),
  facility_affiliations: boolean().default(true),
  work_history: boolean().default(true),
  peer_references: boolean().default(false),
  state_licenses: boolean().default(true),
  dea_licenses: boolean().default(true),
  csr_licenses: boolean().default(true),
  cme: boolean().default(false),
  board_certification: boolean().default(true),
  healthcare_payors: boolean().default(false),
  malpractice_insurance: boolean().default(false),
  npdb: boolean().default(true),
  legal_questions: boolean().default(false),
  share_to: array(string().email()),
  isChangedOptions: boolean().default(false),
});
