import { number, object, string, z } from 'zod';

export const NpiCheckDTO = object({
  last_name: string().min(1),
  first_name: string().min(1),
  number: string().length(10),
  provider_id: number().nullish(),
});

export const NpiImportDTO = NpiCheckDTO.extend({
  provider_id: number().min(1),
});

export type NpiCheckParams = z.infer<typeof NpiCheckDTO>;
