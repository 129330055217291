import { array, number, object, string } from 'zod';

export const ProviderGroupDTO = object({
  id: number().optional(),
  name: string().min(1),
  assignees: array(number()).nullish(),
  description: string().nullish(),
  type: string().min(1),
  owner_id: number(),
});
