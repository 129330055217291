import { nativeEnum, number, object, string } from 'zod';

import { AddressPurpose, CountryCode } from '../codegen';

export const AddressDTO = object({
  id: number().optional(),
  provider_id: number(),
  purpose: nativeEnum(AddressPurpose),
  first_line: string().min(1),
  city: string().min(1),
  country: nativeEnum(CountryCode),
  state_code: string().nullish(),
  other_state: string().nullish(),
  zip: string().nullish(),
  fax: string().nullish(),
  phone: string().nullish(),
});
