import { boolean, object, string } from 'zod';

export const AuthDTO = object({
  email: string().email(),
  password: string(),
  remember: boolean().default(false),
});

export const SetPasswordDTO = object({
  first_name: string().min(1),
  last_name: string().min(1),
  token: string().min(1),
  user_id: string().min(1),
  first_time: boolean(),
  password: string().min(8),
  confirm_password: string().min(8),
});

export const ForgetDTO = object({
  email: string().email(),
});
