import { boolean, number, object, string } from 'zod';

import { dateString } from './helpers';

export const BoardCertificationDTO = object({
  id: number().optional(),
  provider_id: number(),
  name: string().min(1),
  specialty: string().min(1),
  certification: string().nullish(),
  issued_at: dateString,
  expires_at: dateString.nullish(),
  status: string().min(1),
  is_passed: boolean().default(false),
});
