import { array, boolean, number, object, string, z } from 'zod';

import { LicenseRequestIdType, LicenseRequestStatuses, LicenseRequestType, LicenseStatus, LicenseType, LicenseVerificationStatus } from '../codegen';
import { dateString } from './helpers';

const LicenseTypeNative = z.nativeEnum(LicenseType);
const LicenseStatusNative = z.nativeEnum(LicenseStatus);
const LicenseRequestTypeNative = z.nativeEnum(LicenseRequestType);
const VerificationStatusNative = z.nativeEnum(LicenseVerificationStatus);
const LicenseRequestIdTypeNative = z.nativeEnum(LicenseRequestIdType);
const LicenseRequestStatusesNative = z.nativeEnum(LicenseRequestStatuses);

export const LicenseDTO = object({
  id: number().optional(),
  type: LicenseTypeNative,
  state_code: string(),
  number: string().nullish(),
  provider_type_code: string(),
  issued_at: dateString.nullish(),
  expires_at: dateString.nullish(),
  status: LicenseStatusNative,
  primary: boolean().nullish(),
  request_type: LicenseRequestTypeNative.nullish(),
  verify: boolean().nullish(),
  auto_renewal: boolean().nullish(),
  monitor: boolean().nullish(),
  monitor_option: string().optional(),
  multi_state: boolean().optional(),
  verified: VerificationStatusNative.nullish(),
  primary_source_update: boolean().optional(),
  provider_id: number(),
  notes: string().nullish(),
  request: object({
    request_type: LicenseRequestTypeNative.nullish(),
    id_type: LicenseRequestIdTypeNative,
    start_at: string(),
    provider_id: number(),
    state_code: string(),
    provider_type_code: string(),
    status: LicenseRequestStatusesNative.nullish(),
    statuses: array(object({})),
    user: object({
      first_name: string(),
      last_name: string(),
    }),
    organization: object({
      name: string(),
    }),
  }).optional().nullish(),
  request_id: number().optional().nullish(),
  request_status: LicenseRequestStatusesNative.nullish(),
});

export type LicenseFormValues = z.infer<typeof LicenseDTO>;

export const RequestLicenseDTO = object({
  provider_type_code: string().min(1),
  request_license_type: LicenseRequestTypeNative,
  state_code: string().min(1),
  csr_registration: boolean().default(false),
  dea_registration: boolean().default(false),
  fcvs_registration: boolean().default(false),
});

export const LicenseRequestServicesDTO = object({
  type: string(),
  email: string().email(),
  firstname: string(),
  lastname: string(),
  phone: string().nullish(),
  description: string(),
});
