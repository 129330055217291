import { number, object, string } from 'zod';

import { dateString, jsonAddress } from './helpers';

export const PracticeEmployerDTO = object({
  id: number().optional(),
  provider_id: number(),
  employer_name: string().min(1),
  employer_address: jsonAddress.default({}),
  office_type: string().min(1),
  position: string().nullish(),
  start_at: dateString,
  end_at: dateString.nullish(),
  notes: string().nullish(),
});
