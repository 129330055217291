import { number, object, string, type z } from 'zod';

export const BirthInfoDTO = object({
  id: number().optional(),
  provider_id: number(),
  birthCountry: string().nullish(),
  birthCounty: string().nullish(),
  birthProvince: string().nullish(),
  birthCity: string().nullish(),
  countryOfCitizenship: string().nullish(),
});

export type BirthInfo = z.infer<typeof BirthInfoDTO>;
